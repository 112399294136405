import './loading.scss';

function Loading() {
	return (
		<div className='loading-page'>
			<div className='loader'>Loading...</div>
		</div>
	);
}

export default Loading;
