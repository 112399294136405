// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';

function TableSemesterPlanning(props) {
    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên Lớp
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Khoa
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian nhập học
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian kết thúc
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Hành động
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                    onClick={(e) => {
                        props.clicked(e, item.id);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.name || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.facultyName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='MM/yyyy'>
                                    {item.startDate}
                                </Moment>
                            ) : (
                                <div>{item.startDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.endDate &&
                            moment(item.endDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='MM/yyyy'>
                                    {item.endDate}
                                </Moment>
                            ) : (
                                <div>Chưa có thông tin</div>
                            )}
                        </div>
                    </td>

                    <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-start'>
                            <div
                                className='text-green-700 hover:text-green-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.updateClicked(e, item.id);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Thêm kế hoạch lớp
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableSemesterPlanning;
