import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import './Drag-drop-input.scss';
import { setAlert, setLoading } from '../../../../redux/authSlice';
import { $api } from '../../../../services/service';
import { useDispatch } from 'react-redux';
import ErrorDialog from '../../ErrorDialog/ErrorDialog';
import { Modal } from 'react-bootstrap';
import Button from '../../Button/Button';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

function DragDropInput({ documentLists, subjectId, handleChangeDocument }) {
    const dispatch = useDispatch();
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [deleteDocumentDialog, setDeleteDocumentDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [openUpdateDocument, setOpenUpdateDocument] = useState(false);
    const docType = ['doc', 'docx', 'rtl', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const xlsType = ['xls', 'xlsx', 'csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const pptType = ['ppt', 'pptx', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    const txtType = ['txt'];
    const pdfType = ['pdf', 'application/pdf'];
    // drag state
    const [dragActive, setDragActive] = useState(false);
    const [tab, setTab] = useState(0);
    const [documentsDetail, setDocumentsDetail] = useState({});
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            Promise.all(Array.from(e.dataTransfer.files).map((file, index) => {
                if (fileAccept(e.dataTransfer.files[index].type)) {
                    uploadDocument((tab + 1), e.dataTransfer.files[index].name, e.dataTransfer.files[index]);
                } else {
                    show_ErrMsg('File: ' + e.dataTransfer.files[index].name + ' không đúng định dạng cho phép!');
                }
            })).then(() => {
                handleChangeDocument(true);
                setOpenUpdateDocument(() => false);
                dispatch(setAlert({
                    title: 'Lưu thành công'
                }));
            }).catch(e => {
                show_ErrMsg(e);
            });
            // handleFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            Promise.all(Array.from(e.target.files).map((file, index) => {
                uploadDocument((tab + 1), e.target.files[index].name, e.target.files[index]);
            })).then(() => {
                handleChangeDocument(true);
                setOpenUpdateDocument(() => false);
                dispatch(setAlert({
                    title: 'Lưu thành công'
                }));
            });
            // handleFiles(e.target.files);
        }
    };

    function fileAccept(fileType) {
        if (fileType) {
            if (pdfType.includes(fileType)) {
                return true;
            } else if (txtType.includes(fileType) || txtType.includes(fileType)) {
                return true;
            } else if (docType.includes(fileType) || docType.includes(fileType)) {
                return true;
            } else if (xlsType.includes(fileType) || xlsType.includes(fileType)) {
                return true;
            } else return pptType.includes(fileType) || pptType.includes(fileType);
        }
        return false;
    }


// triggers the input when the button is clicked
    const onButtonClick = () => {
        // @ts-ignore
        inputRef.current.click();
    };

    async function uploadDocument(
        type,
        name,
        formFile
    ) {
        try {
            dispatch(setLoading(true));
            const formData = new FormData();
            formData.append('subjectId', subjectId);
            formData.append('type', type);
            formData.append('name', name);
            formData.append('formFile', formFile);
            const res: any = await $api.subjectDocument.saveDocument(formData);
            handleChangeDocument(true);
            dispatch(setAlert({
                title: res.message
            }));
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function updateDocument() {
        try {
            dispatch(setLoading(true));
            const formData = new FormData();
            formData.append('id', documentsDetail['id']);
            formData.append('subjectId', documentsDetail['subjectId']);
            formData.append('type', documentsDetail['type']);
            formData.append('name', documentsDetail['name']);
            formData.append('detail', documentsDetail['detail'] ? documentsDetail['detail'] : '');
            if (documentsDetail['formFile']) {
                formData.append('formFile', documentsDetail['formFile']);
            }
            const res: any = await $api.subjectDocument.saveDocument(formData);
            handleChangeDocument(true);
            dispatch(setAlert({
                title: res.message
            }));
            setOpenUpdateDocument(false);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }


    async function documentAction(record, action) {
        try {
            dispatch(setLoading(true));
            if (action == 'download') {
                const res: any = await $api.subjectDocument.downloadDocument({ path: record['url'] });
                if (res && res.result) {
                    window.open(res.result, '_blank');
                    dispatch(setAlert({
                        title: res.message
                    }));
                }
            } else {
                const res: any = await $api.subjectDocument.previewDocument({ path: record['url'] });
                if (res && res.result) {
                    window.open(res.result, '_blank');
                    dispatch(setAlert({
                        title: res.message
                    }));
                }
            }

            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteDocument() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subjectDocument.deleteDocument(documentsDetail['id']);
            if (res) {
                dispatch(setAlert({
                    title: res.message
                }));
                setDeleteDocumentDialog({ isOpen: false, title: '', subTitle: '' });
                handleChangeDocument(true);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function handleUpdateDocument(record) {
        setDocumentsDetail(() => record);
        setOpenUpdateDocument(() => true);
    }

    function handleDeleteDocument(record) {
        setDocumentsDetail(() => record);
        setDeleteDocumentDialog({
            isOpen: true,
            title: 'Thông báo',
            subTitle: 'Bạn muốn xóa tài liệu: ' + record['name'] + ' - ' + 'Loại tài liệu: ' + getTypeDocument(documentsDetail['type'])
        });
    }

    function getTypeDocument(type: any) {
        return type == 1 ? 'Tài liệu giảng dạy' : (type == 2 ? 'Tài liệu nội bộ' : 'Đề thi/Đáp án');
    }

    function updateDocumentChangeFile(event) {
        if (event.target.files && event.target.files[0]) {
            setDocumentsDetail({
                ...documentsDetail,
                formFile: event.target.files[0]
            });
        }
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <form id='form-file-upload' onDragEnter={event => handleDrag(event)}
                      onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type='file' id='input-file-upload'
                           accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.pdf,.ppt,.pptx,.doc,.docx'}
                           multiple={true} onChange={handleChange} />
                    <label id='label-file-upload' htmlFor='input-file-upload'
                           className={dragActive ? 'drag-active' : ''}>
                        <div>
                            <p className='fw-bold'>Kéo thả tài liệu vào đây</p>
                            <button className='upload-button text-danger font-bold' onClick={onButtonClick}>Tải lên
                            </button>
                        </div>
                    </label>
                    {dragActive &&
                        <div id='drag-file-element' onDragEnter={event => handleDrag(event)} onDragLeave={event => {
                            handleDrag(event);
                        }}
                             onDragOver={event => {
                                 handleDrag(event);
                             }}
                             onDrop={event => {
                                 handleDrop(event);
                             }}></div>}
                </form>
            </div>
            <div className='tab-content pt-5'>
                <Tabs defaultIndex={tab} onSelect={(index) => setTab(prevState => index)}>
                    <TabList>
                        <Tab>1. Tài liệu giảng dạy</Tab>
                        <Tab>2. Tài liệu nội bộ</Tab>
                        <Tab>3. Đề thi / đáp án</Tab>
                    </TabList>
                    <TabPanel>
                        {
                            documentLists.filter(value => value.type == '1').map((record, index) => {
                                return <div className='d-flex flex-row align-items-end justify-content-between pb-2'>
                                    <div className={'d-flex flex-row justify-between w-2/3'}
                                         onClick={() => handleUpdateDocument(record)}>
                                        <div className={'w-1/2 pr-1'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Tên
                                                tài liệu</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.name} readOnly={true} />
                                        </div>
                                        <div className={'w-1/2'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Mô
                                                tả</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.detail} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-end w-1/3'}>
                                        <button className='btn btn-success'
                                                onClick={() => documentAction(record, 'view')}><i
                                            className={'far fa-eye'}></i> Xem
                                        </button>
                                        <button className='btn btn-secondary ml-1 mr-1'
                                                onClick={() => documentAction(record, 'download')}><i
                                            className={'fas fa-arrow-down'}></i> Tải về
                                        </button>
                                        <button className='btn btn-danger' onClick={() => handleDeleteDocument(record)}>
                                            <i className={'fas fa-trash'}></i> Xóa
                                        </button>
                                    </div>
                                </div>;
                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            documentLists.filter(value => value.type == '2').map((record, index) => {
                                return <div className='d-flex flex-row align-items-end justify-content-between pb-2'>
                                    <div className={'d-flex flex-row justify-between w-2/3'}
                                         onClick={() => handleUpdateDocument(record)}>
                                        <div className={'w-1/2 pr-1'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Tên
                                                tài liệu</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.name} readOnly={true} />
                                        </div>
                                        <div className={'w-1/2'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Mô
                                                tả</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.detail} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-end w-1/3'}>
                                        <button className='btn btn-success'
                                                onClick={() => documentAction(record, 'view')}><i
                                            className={'far fa-eye'}></i> Xem
                                        </button>
                                        <button className='btn btn-secondary ml-1 mr-1'
                                                onClick={() => documentAction(record, 'download')}><i
                                            className={'fas fa-arrow-down'}></i> Tải về
                                        </button>
                                        <button className='btn btn-danger' onClick={() => handleDeleteDocument(record)}>
                                            <i className={'fas fa-trash'}></i> Xóa
                                        </button>
                                    </div>
                                </div>;
                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            documentLists.filter(value => value.type == '3').map((record, index) => {
                                return <div className='d-flex flex-row align-items-end justify-content-between pb-2'>
                                    <div className={'d-flex flex-row justify-between w-2/3'}
                                         onClick={() => handleUpdateDocument(record)}>
                                        <div className={'w-1/2 pr-1'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Tên
                                                tài liệu</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.name} readOnly={true} />
                                        </div>
                                        <div className={'w-1/2'}>
                                            <label htmlFor='documentName'
                                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Mô
                                                tả</label>
                                            <input type='text' id='documentName'
                                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                                   value={record.detail} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-end w-1/3'}>
                                        <button className='btn btn-success'
                                                onClick={() => documentAction(record, 'view')}><i
                                            className={'far fa-eye'}></i> Xem
                                        </button>
                                        <button className='btn btn-secondary ml-1 mr-1'
                                                onClick={() => documentAction(record, 'download')}><i
                                            className={'fas fa-arrow-down'}></i> Tải về
                                        </button>
                                        <button className='btn btn-danger' onClick={() => handleDeleteDocument(record)}>
                                            <i className={'fas fa-trash'}></i> Xóa
                                        </button>
                                    </div>
                                </div>;
                            })
                        }
                    </TabPanel>
                </Tabs>
                <Modal
                    show={openUpdateDocument}
                    aria-labelledby='contained-modal-title-vcenter'
                    onHide={() => {
                        setOpenUpdateDocument(false);
                    }}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id='contained-modal-title-vcenter'>
                            <i className='far fa-pencil' /> Cập nhật tài liệu
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'pb-2'}>
                            <label htmlFor='documentName'
                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Tên
                                tài liệu</label>
                            <input type='text' id='documentName'
                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                   value={documentsDetail['name']}
                                   onChange={event => setDocumentsDetail({
                                       ...documentsDetail,
                                       name: event.target.value
                                   })} />
                        </div>
                        <div className={'pb-2'}>
                            <label htmlFor='documentDetails'
                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Mô tả tài
                                liệu</label>
                            <input type='text' id='documentName'
                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                   value={documentsDetail['detail']} onChange={event => setDocumentsDetail({
                                ...documentsDetail,
                                detail: event.target.value
                            })} />
                        </div>
                        <div className={'pb-2'}>
                            <label htmlFor='documentDetails'
                                   className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Loại tài
                                liệu</label>
                            <input type='text' id='documentName'
                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500'
                                   value={getTypeDocument(documentsDetail['type'])} readOnly={true} />
                        </div>
                        <div className={'pb-2'}>
                            <label htmlFor='formFile'
                                   className='block mb-2 text-sm font-medium text-red-600 dark:text-white'>Nếu có thay
                                đổi tài liệu chọn tại đây</label>
                            <input type='file' id='formFile'
                                   className='bg-gray-50 border border-gray-300 text-gray-900
                                                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                                                 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                                                dark:focus:border-blue-500' onChange={event => {
                                updateDocumentChangeFile(event);
                            }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div onClick={() => {
                            setOpenUpdateDocument(false);
                        }}>
                            <Button
                                label={'Hủy'}
                                color={'bg-red-800'}
                                hoverColor={'bg-red-900'}
                                icon={<i className='far fa-times-circle' />}
                            />
                        </div>
                        <div
                            onClick={() => {
                                updateDocument();
                            }}>
                            <Button
                                color={'bg-green-800'}
                                hoverColor={'bg-green-900'}
                                icon={<i className={'far fa-save'} />}
                                label={'Lưu lại'}
                            />
                        </div>
                    </Modal.Footer>
                </Modal>
                <ErrorDialog
                    errorDialog={errDialog}
                    setErrorDialog={setErrDialog}
                />
                <ConfirmDialog
                    confirmDialog={deleteDocumentDialog}
                    setConfirmDialog={setDeleteDocumentDialog}
                    deleteItem={deleteDocument}
                />
            </div>
        </>
    );

}

export default DragDropInput;