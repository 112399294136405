import './student__manager.scss';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import TableStudent from 'components/Elements/Table/Table-Student';
import Pagination from 'components/Elements/Pagination/Pagination';
import { $api } from 'services/service';
import { useHistory } from 'react-router-dom';
import Alert from 'components/Elements/Alert/Alert';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { setAlert, setLoading } from '../../redux/authSlice';
import TableStudentManager from '../../components/Elements/Table/Table-Student-Manager';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Student__Manager() {
    let history = useHistory();
    const dispatch = useDispatch();

    const [students, setStudents] = useState([]);
    const [classId, setClassId] = useState('');
    const [changes, setChanges] = useState(false);
    const [graduated, setGraduated] = useState('0');
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);

    useEffect(() => {
        async function getStudents(option = {}) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.student.getPaging(option);
                if (res && res.result) {
                    setTotal(res.result.totalRecord);
                    setStudents(res.result.items);
                    setPages(Math.ceil(res.result.totalRecord / 18));
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getStudents({
            isGraduated: Number(graduated) === 1,
            pageIndex: page,
            pageSize: 18,
            freeTextSearch: search || search.length > 0 ? search : null
        });
    }, [changes, classId, graduated]);

    function changePage(page) {
        setPage(page);
        setChanges(!changes);
    }

    async function searchAPI() {
        setChanges(!changes);
    }

    async function deleteStudent(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.student.deleteStudent(idDelete);
            if (res) {
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }


    function getDetailStudent(e, id, classId) {
        e.stopPropagation();
        history.push(`/sinh-vien/${id}?c=${classId}`);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function toNotification() {
        history.push('/thong-bao-lop-hoc/' + classId);
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <a href={`/quan-ly-sinh-vien`}>
                                Quản lý sinh viên
                            </a>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='mt-4'>
                        <div className='flex justify-between items-end flex-wrap'>
                            <div className='action--item flex justify-start items-center'>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tình trạng tốt nghiệp
                                    </div>
                                    <select
                                        className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        value={graduated}
                                        onChange={(e) => {
                                            setGraduated(e.target.value);
                                        }}>
                                        <option value='1' key='1'>
                                            Đã tốt nghiệp
                                        </option>
                                        <option value='0' key='0'>
                                            Chưa tốt nghiệp
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className='action--item search flex justify-start mt-4'>
                                <input
                                    name='search'
                                    type='text'
                                    required
                                    className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Tìm theo: Tên, MSSV, SĐT'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) =>
                                        e.key === 'Enter' && searchAPI()
                                    }
                                />
                                <div onClick={searchAPI}>
                                    <Button
                                        label='Tìm kiếm'
                                        color='bg-yellow-500'
                                        hoverColor='bg-yellow-400'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-auto-scroll'>
                            {
                                students.length === 0 ?
                                    <div className='flex flex-wrap -ml-4 -mb-4'
                                         style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='/notFound_01.png'
                                             alt='Không tìm thấy dữ liệu'
                                             title='Không tìm thấy dữ liệu'
                                        />
                                    </div> :
                                    <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableStudentManager
                                            data={students}
                                            deleteClicked={deleteStudent}
                                            updateClicked={getDetailStudent}
                                            clicked={getDetailStudent}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='pagination'>
                        <Pagination
                            page={page}
                            total={total}
                            pages={pages}
                            clickedPage={changePage}
                        />
                    </div>
                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Student__Manager;
