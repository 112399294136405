// @ts-ignore
import Moment from 'react-moment';
import { _ENUMS } from 'constants/_enums';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { $api } from '../../../services/service';
import { setAlert } from '../../../redux/authSlice';
import { _AUTH } from '../../../constants/_auth';

function TableUser(props) {
    const dispatch = useDispatch();

    const forgotPassword = async (e, username) => {
        e.stopPropagation();
        if (username && username.length > 0) {
            try {
                const token = localStorage.getItem(_AUTH.TOKEN_NAME) || '';
                localStorage.removeItem(_AUTH.TOKEN_NAME);
                const res: any = await $api.auth.forgotPassword(username);
                localStorage.setItem(_AUTH.TOKEN_NAME, token);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
            } catch (e) {
                dispatch(
                    setAlert({
                        type: 'danger',
                        title: 'Đã có lỗi xảy ra, vui lòng thử lại'
                    })
                );
            }
        } else {
            dispatch(
                setAlert({
                    type: 'danger',
                    title: 'Không được bỏ trống tên tài khoản'
                })
            );
        }
    };

    function getRole(role) {
        switch (role) {
            case 1:
                return 'Sinh viên';
            case 2:
                return 'Giảng viên';
            case 3:
                return 'Giảng viên đồng thời là cán bộ';
            case 10:
                return 'Cán bộ nhà trường';
            case 99:
                return 'Admin';
        }
    }

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thông tin
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Email
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số điện thoại
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày sinh
                </th>
                {props.roleShow ? (
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Chức vụ
                    </th>
                ) : (
                    ''
                )}
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    hành động
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                    onClick={(e) => {
                        props.clicked(e, item.id);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                            <div className='flex-shrink-0 h-10 w-10'>
                                <img
                                    className='h-10 w-10 rounded-full'
                                    src={item.profilePicUrl || _ENUMS.IMAGE}
                                    alt={item.name}
                                    title={item.name}
                                />
                            </div>
                            <div className='ml-4'>
                                <div className='text-sm font-medium text-gray-900'>
                                    {item.fullName || ''}
                                </div>
                                <div className='text-sm text-gray-900'>
                                    ID: {item.userInternalId || ''}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.userName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.mobile || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900'>
                            {item.birthDate &&
                            moment(item.birthDate).isValid() ? (
                                <Moment format='DD/MM/yyyy'>
                                    {item.birthDate}
                                </Moment>
                            ) : (
                                <div>{item.birthDate}</div>
                            )}
                        </div>
                    </td>
                    {props.roleShow ? (
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-gray-900'>
                                {item?.isStaff ? 'Giảng viên này đồng thời là cán bộ' : item?.isStaff === false ? 'Giảng viên' : getRole(item.role)}
                            </div>
                        </td>
                    ) : (
                        ''
                    )}
                    <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            <div
                                className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.updateClicked(e, item.id);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Chỉnh sửa
                            </div>
                            <span className='mx-2'>/</span>
                            <div
                                className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.deleteClicked(e, item.id);
                                }}>
                                <i className='far fa-trash-alt mr-2' />
                                Xóa
                            </div>
                        </div>
                    </td>
                    <td>
                        <div
                            className='flex flex-nowrap items-center text-xs text-gray-500 hover:text-green-700 cursor-pointer pr-10'
                            onClick={(e) => {
                                return forgotPassword(e, item.userName);
                            }}>
                            <div className='mr-2'>
                                <i className='fas fa-undo' />
                            </div>
                            <div className='whitespace-nowrap'>
                                Mật khẩu
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableUser;
