// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';

function Table(props) {
	return (
		<table className='min-w-full divide-y divide-gray-200'>
			<thead className='bg-gray-50'>
				<tr>
					<th
						scope='col'
						className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
						Mã lớp
					</th>
					<th
						scope='col'
						className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
						Tên Lớp
					</th>
					<th
						scope='col'
						className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
						Ngày bắt đầu
					</th>
					<th
						scope='col'
						className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
						Trình trạng tốt nghiệp
					</th>
					<th
						scope='col'
						className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
						Hành động
					</th>
				</tr>
			</thead>
			<tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
				{props.data.map((item) => (
					<tr
						className='hover:bg-gray-100'
						key={item.id}
						onClick={(e) => {
							props.clicked(e, item.id);
						}}>
						<td className='px-6 py-4 whitespace-nowrap'>
							<div className='text-sm text-gray-900 font-medium text-left'>
								{item.classCodeName || ''}
							</div>
						</td>
						<td className='px-6 py-4 whitespace-nowrap'>
							<div className='flex items-start text-sm text-gray-900'>
								{item.name || ''}
							</div>
						</td>
						<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
							<div className='text-sm text-gray-900'>
								{item.startDate &&
								moment(item.startDate).isValid() ? (
									<Moment format='DD/MM/yyyy'>
										{item.startDate}
									</Moment>
								) : (
									<div>{item.startDate}</div>
								)}
							</div>
						</td>
						<td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
							<div className='text-sm text-gray-900'>
								{item.isGraduated
									? 'Đã tốt nghiệp'
									: 'Chưa tốt nghiệp'}
							</div>
						</td>
						<td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
							<div className='flex items-center'>
								<div
									className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
									onClick={(e) => {
										props.updateClicked(e, item.id);
									}}>
									<i className='far fa-user-edit mr-2' />
									Chỉnh sửa
								</div>
								<span className='mx-2'>/</span>
								<div
									className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'
									onClick={(e) => {
										props.deleteClicked(e, item.id);
									}}>
									<i className='far fa-trash-alt mr-2' />
									Xóa
								</div>
							</div>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default Table;
