import axiosClient from 'config/axiosClient';

const authService = {
	login: (data) => {
		return axiosClient.post('/Signin', data);
	},
	register: (data) => {
		return axiosClient.post('/Register', data);
	},
	forgotPassword: (username) => {
		return axiosClient.post('/ForgotPassword', JSON.stringify(username));
	},
	changePassword: (data) => {
		return axiosClient.post('/ChangePassword', data);
	},
	resetPassword: (id) => {
		return axiosClient.post('/ResetPassword?id=' + id);
	},
};

export default authService;
