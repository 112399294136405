import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import Button from 'components/Elements/Button/Button';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Calendar from 'components/Elements/Calender/Calendar';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import List from 'components/Elements/List/List';
import DialogStudent from 'components/Elements/DialogStudent/DialogStudent';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function Schedule__Teaching() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const [modalUpdateShow, setModelUpdateShow] = useState(false);
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [modalStudent, setModalStudent] = useState(false);
	const [subjectTeaching, setSubjectTeaching] = useState({
		id: null,
		subjectId: '',
		startDate: new Date(),
		endDate: new Date(),
		teacherId: '',
		roomId: '',
		isRepeatedWeekly: true,
		startTime: '09:00',
		duration: 120,
	});

	const [subjectSchedule, setSubjectSchedule] = useState({
		id: '',
		subjectTeachingId: '',
		startDateTime: new Date(),
		endDateTime: new Date(),
		roomId: '',
	});

	const [timeInput, setTimeInput] = useState({
		startTime: '09:00',
		endTime: '11:00',
	});

	const [data, setData] = useState({
		id: '',
		title: '',
		start: '',
		end: '',
	});

	const [changes, setChanges] = useState(false);
	const [rooms, setRooms] = useState([]);

	useEffect(() => {
		async function getRooms() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.room.getAll();
				setRooms(res.result);
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại ');
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}

		if (modalUpdateShow) {
			Promise.all([getRooms()]);
		}
	}, [modalUpdateShow]);

	useEffect(() => {
		async function getScheduleSubject(id) {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.schedule.subjectTeaching_get(id);
				setSubjectTeaching(res.result);
				const r = res.result?.subjectSchedules.map((item: any) => {
					return {
						...item,
						title: `<b>${moment(item.startDateTime + 'Z').format(
							'HH:mm'
						)} - ${moment(item.endDateTime + 'Z').format(
							'HH:mm'
						)}</b>
						<br/>${item?.room?.name}`,
						start: new Date(item.startDateTime + 'Z'),
						end: new Date(item.endDateTime + 'Z'),
					};
				});
				setData(r);
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại ');
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}

		if (id && id?.length) {
			getScheduleSubject(id);
		}
	}, [changes]);

	async function isEventClicked(eventId) {
		try {
			dispatch(setLoading(true));
			const res: any = await $api.schedule.subjectSchedule_get(eventId);
			setSubjectSchedule({
				id: eventId,
				subjectTeachingId: res.result.subjectTeachingId,
				startDateTime: new Date(res.result.startDateTime + 'Z'),
				endDateTime: new Date(res.result.endDateTime + 'Z'),
				roomId: res.result.roomId,
			});
			const startTime = moment(res.result.startDateTime + 'Z').format('HH:mm');
			const endTime = moment(res.result.endDateTime + 'Z').format('HH:mm');
			setTimeInput({
				startTime: startTime,
				endTime: endTime,
			});
			setModelUpdateShow(true);
			setChanges(!changes);
			dispatch(setLoading(false));
		} catch (e) {
			dispatch(setLoading(false));
			// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function openModalStudent(e) {
		e.stopPropagation();
		setModalStudent(true);
	}

	async function onSave() {
		try {
			const payloadData = {
				id: subjectSchedule.id,
				startDateTime: setDatWithTime(
					subjectSchedule.startDateTime,
					timeInput.startTime
				),
				endDateTime: setDatWithTime(
					subjectSchedule.startDateTime,
					timeInput.endTime
				),
				roomId: subjectSchedule.roomId,
			};
			dispatch(setLoading(true));
			const res: any = await $api.schedule.subjectSchedule_update(payloadData);
			setSubjectSchedule({
				id: '',
				subjectTeachingId: '',
				startDateTime: new Date(),
				endDateTime: new Date(),
				roomId: '',
			});
			setTimeInput({
				startTime: '09:00',
				endTime: '11:00',
			});
			setModelUpdateShow(false);
			setChanges(!changes);
			dispatch(
				setAlert({
					title: res.message,
				})
			);
			dispatch(setLoading(false));
		} catch (e) {
			dispatch(setLoading(false));
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function setDatWithTime(date, time) {
		const d = new Date(date);
		const hour = time.split(':')[0] || 9;
		const min = time.split(':')[1] || 0;
		return new Date(
			d.getFullYear(),
			d.getMonth(),
			d.getDate(),
			Number(hour),
			Number(min),
			0
		);
	}

	function selectedRoom(room) {
		setSubjectSchedule({
			...subjectSchedule,
			// @ts-ignore
			roomId: room.id,
		});
	}

	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}

	return (
		<DefaultLayout>
			<div className='faculties page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<a href={`/thoi-khoa-bieu`}>Danh sách môn học</a>
						<div className='___title__box__last'>{`>`}</div>
						<div>
							{
								// @ts-ignore
								subjectTeaching?.subject?.name +
								// @ts-ignore
								` (${subjectTeaching?.subject?.subjectCode})`
							}
						</div>
						<div className='___title__box__last'>{`>`}</div>
						<div>Thời khóa biểu</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div>
						<div className='flex justify-between items-end flex-wrap'>
							<div className='action--item flex justify-start items-end'>
								<div
									className='actions--item'
									onClick={(e) => {
										openModalStudent(e);
									}}>
									<Button
										label='Danh sách học viên'
										icon={<i className='fas fa-users' />}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='p-layout mt-8'>
						<div className='tab-content'>
							<Tabs defaultIndex={0}>
								<TabList>
									<Tab>Lịch học</Tab>
									<Tab>Thông tin lịch học</Tab>
								</TabList>
								<TabPanel>
									<Calendar
										data={data}
										isEventClicked={isEventClicked}
									/>
								</TabPanel>
								<TabPanel>
									<div className='py-4 px-2 max-w-md'>
										<div>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Khoa
											</div>
											<input
												name='subject'
												type='text'
												autoComplete='subject'
												required
												className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
												placeholder='Môn học'
												value={
													// @ts-ignore
													subjectTeaching?.subject
														?.facultyName
												}
												disabled
											/>
										</div>
										<div className='mt-2'>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Môn học
											</div>
											<input
												name='subject'
												type='text'
												autoComplete='subject'
												required
												className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
												placeholder='Môn học'
												value={
													// @ts-ignore
													subjectTeaching?.subject
														?.name +
													// @ts-ignore
													` (${subjectTeaching?.subject?.subjectCode})`
												}
												disabled
											/>
										</div>
										<div className='mt-2'>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Giảng viên
											</div>
											<input
												name='teacher'
												type='text'
												autoComplete='teacher'
												required
												className='input-custom appearance-none block
														w-full px-3 py-2 border border-gray-300
														placeholder-gray-500 rounded-md
														focus:outline-none focus:ring-1
														focus:ring-blue-700'
												placeholder='Giảng viên'
												value={
													// @ts-ignore
													subjectTeaching?.teacher
														?.fullName +
													// @ts-ignore
													` (${subjectTeaching?.teacher?.userName})`
												}
												disabled
											/>
										</div>
										<div className='mt-2'>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Ngày bắt đầu
											</div>
											<DatePicker
												selected={
													new Date(
														subjectTeaching.startDate
													)
												}
												onChange={(date) => {
													setSubjectTeaching({
														...subjectTeaching,
														startDate: date,
													});
												}}
												dateFormat='dd/MM/yyyy'
												disabled
											/>
										</div>
										<div className='mt-2'>
											<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
												Ngày kết thúc
											</div>
											<DatePicker
												selected={
													new Date(
														subjectTeaching.endDate
													)
												}
												onChange={(date) => {
													setSubjectTeaching({
														...subjectTeaching,
														endDate: date,
													});
												}}
												dateFormat='dd/MM/yyyy'
												disabled
											/>
										</div>
									</div>
								</TabPanel>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={modalUpdateShow}
				aria-labelledby='contained-modal-title-vcenter'
				onHide={() => setModelUpdateShow(false)}
				centered>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Thông tin lịch học
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Ngày học
						</div>
						<DatePicker
							selected={new Date(subjectSchedule.startDateTime)}
							onChange={(date) => {
								setSubjectSchedule({
									...subjectSchedule,
									// @ts-ignore
									startDateTime: date,
								});
							}}
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					<div className='mt-2'>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Giờ bắt đầu
						</div>
						<input
							name='text'
							type='time'
							required
							className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
							placeholder='Giờ bắt đầu buổi học'
							onChange={(e) => {
								setTimeInput({
									...timeInput,
									startTime: e.target.value,
								});
							}}
							value={timeInput.startTime}
						/>
					</div>
					<div className='mt-2'>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Giờ kết thúc
						</div>
						<input
							name='text'
							type='time'
							required
							className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
							placeholder='Giờ kết thúc'
							onChange={(e) => {
								setTimeInput({
									...timeInput,
									endTime: e.target.value,
								});
							}}
							min={timeInput.startTime}
							value={timeInput.endTime}
						/>
					</div>
					{rooms && rooms.length > 0 ? (
						<div className='mt-2'>
							<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
								Phòng học
							</div>
							<List
								data={rooms}
								selected={selectedRoom}
								type='room'
							/>
						</div>
					) : (
						''
					)}
				</Modal.Body>
				<Modal.Footer>
					<div
						onClick={() => {
							onSave();
						}}>
						<Button label='Lưu lại' />
					</div>
				</Modal.Footer>
			</Modal>
			<Modal
				show={modalStudent}
				aria-labelledby='contained-modal-title-vcenter'
				onHide={() => setModalStudent(false)}
				centered>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Danh sách sinh viên
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<DialogStudent />
				</Modal.Body>
			</Modal>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</DefaultLayout>
	);
}

export default Schedule__Teaching;
