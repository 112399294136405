// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
function TableFormRequest(props) {
    const [sortBy, setSortBy] = useState(props.sortBy)
    function getStatus(status) {
        switch (status) {
            case 1:
                return 'Chưa xác nhận';
            case 2:
                return 'Đang xử lý';
            case 3:
                return 'Đã xác nhận';
            case 4:
                return 'Đã nhận kết quả';
        }
    }

    useEffect(() => {
        setSortBy(props.sortBy)
    }, [props.sortBy])

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr className={'cursor-pointer'}>
                <th
                    onClick={(e) => {
                        props.sortClicked({sortBy: 'creationDate'});
                    }}
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian
                    {
                        sortBy.name == 'creationDate' ? <i className={(sortBy.order == 'desc' ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt') + ' ml-1'}></i> : ''
                    }
                </th>
                <th
                    onClick={(e) => {
                        props.sortClicked({sortBy: 'studentName'});
                    }}
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Họ tên
                    {
                        sortBy.name == 'studentName' ? <i className={(sortBy.order == 'desc' ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt') + ' ml-1'}></i> : ''
                    }
                </th>
                <th
                    onClick={(e) => {
                        props.sortClicked({sortBy: 'className'});
                    }}
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Lớp hành chính
                    {
                        sortBy.name == 'className' ? <i className={(sortBy.order == 'desc' ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt') + ' ml-1'}></i> : ''
                    }
                </th>
                <th
                    onClick={(e) => {
                        props.sortClicked({sortBy: 'formTemplateName'});
                    }}
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Loại đề nghị
                    {
                        sortBy.name == 'formTemplateName' ? <i className={(sortBy.order == 'desc' ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt') + ' ml-1'}></i> : ''
                    }
                </th>
                <th
                    onClick={(e) => {
                        props.sortClicked({sortBy: 'status'});
                    }}
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Trạng thái
                    {
                        sortBy.name == 'status' ? <i className={(sortBy.order == 'desc' ? 'fas fa-sort-amount-up-alt' : 'fas fa-sort-amount-down-alt') + ' ml-1'}></i> : ''
                    }
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ghi chú
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    hành động
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                    onClick={(e) => {
                        props.clicked(e, item.id);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {moment(item.creationDate).format('DD/MM/YYYY HH:mm') || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.userInternalId + ' - ' || ''}  {item.studentName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.className || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.formTemplateName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-bold '>
                            {getStatus(item.status) || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.note || ''}
                        </div>
                    </td>

                    <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            <div
                                className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.updateClicked(e, item.id);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Chỉnh sửa
                            </div>
                            {/*<span className='mx-2'>/</span>*/}
                            {/*<div*/}
                            {/*    className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'*/}
                            {/*    onClick={(e) => {*/}
                            {/*        props.deleteClicked(e, item.id);*/}
                            {/*    }}>*/}
                            {/*    <i className='far fa-trash-alt mr-2' />*/}
                            {/*    Xóa*/}
                            {/*</div>*/}
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableFormRequest;
