import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useHistory, useParams } from 'react-router-dom';
import YearSchedule from 'components/Elements/YearSchedule/YearSchedule';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import Button from 'components/Elements/Button/Button';
import { Modal } from 'react-bootstrap';
import List from 'components/Elements/List/List';

function Schedule__Faculties() {
	let history = useHistory();
	const dispatch = useDispatch();
	let { fId, cId } = useParams();
	const [modalShow, setModelShow] = useState(false);
	const [subjectTeaching, setSubjectTeaching] = useState({
		subjectId: '',
		startDate: new Date(),
		endDate: new Date(),
		teacherId: '',
		roomId: '',
		isRepeatedWeekly: true,
		startTime: '09:00',
		duration: 120,
	});
	const [changes, setChanges] = useState(false);
	const [schedule, setSchedule] = useState([]);
	const [subject, setSubject] = useState('');
	const [subjects, setSubjects] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [idDelete, setIdDelete] = useState(null);

	useEffect(() => {
		async function getFaculties() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.subject.getPaging({ facultyId: fId });
				if (res && res.result) {
					setSubjects(res.result.items);
					if (cId && cId.length > 0) {
						setSubject(cId);
					} else {
						setSubject(res.result.items[0].id);
					}
				}
				dispatch(setLoading(false));
			} catch (e) {

				dispatch(setLoading(false));
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}

		if (fId && fId.length > 0 && cId && cId.length > 0) {
			getFaculties();
		}
	}, [cId]);

	useEffect(() => {
		async function getTeachers() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.teacher.getAll();
				setTeachers(res.result);
				if (res.result.length !== 0) {
					setTeachers(res.result);
					subjectTeaching.teacherId = res.result[0].id;
					setSubjectTeaching({
						...subjectTeaching
					});
				}
				else {
					setTeachers([]);
					setSubjectTeaching({
						...subjectTeaching,
						teacherId: "",
					});
				}
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}

		async function getRooms() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.room.getAll();
				if (res['result'].length !== 0) {
					setRooms(res.result);
					setSubjectTeaching({
						...subjectTeaching,
						roomId: res.result[0].id,
					});
				}
				else{
					setSubjectTeaching({
						...subjectTeaching,
						roomId: '',
					});
				}
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}
		getRooms();
		getTeachers();
	}, []);

	useEffect(() => {
		async function getScheduleSubject(id) {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.schedule.subjectTeaching_getPaging({ subjectId: id });
				setSchedule(res.result.items);
				dispatch(setLoading(false));
			}
			catch (e) {
				dispatch(setLoading(false));
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}

		if (subject && subject.length > 0) {
			history.push('/thoi-khoa-bieu/' + fId + '/' + cId);
			getScheduleSubject(subject);
		}
	}, [subject, changes]);

	function setDatWithTime(date, time) {
		const d = new Date(date);
		const hour = time.split(':')[0] || 9;
		const min = time.split(':')[1] || 0;
		return new Date(
			d.getFullYear(),
			d.getMonth(),
			d.getDate(),
			Number(hour),
			Number(min),
			0
		);
	}

	function findByID() {
		const objectFind = subjects.find((item: { id: string }) => {
			return item.id === subject;
		});
		const object = { name: '' };
		return objectFind ? objectFind : object;
	}

	async function createSubjectTeaching() {
		try {
			const startTime = new Date(
				subjectTeaching.startDate.getFullYear(),
				subjectTeaching.startDate.getMonth(),
				subjectTeaching.startDate.getDate(),
				// @ts-ignore
				subjectTeaching.startTime?.split(':')[0] || 9,
				// @ts-ignore
				subjectTeaching.startTime?.split(':')[1] || 0,
				0
			);
			if (subjectTeaching.teacherId === "") {
				show_ErrMsg('Vui lòng chọn giảng viên');
				return;
			}
			if (subjectTeaching.endDate < subjectTeaching.startDate) {
				show_ErrMsg('Thời gian kết thúc không hợp lệ');
				return;
			}
			if (subjectTeaching.duration < 0) {
				show_ErrMsg('Thời gian buổi học không hợp lệ');
				return;
			}
			dispatch(setLoading(true));
			const res: any = await $api.schedule.subjectTeaching_create({
				subjectId: subject,
				startDate: setDatWithTime(
					subjectTeaching.startDate,
					subjectTeaching.startTime
				),
				endDate: setDatWithTime(
					subjectTeaching.endDate,
					subjectTeaching.startTime
				),
				// @ts-ignore
				teacherId: subjectTeaching.teacherId || teachers[0].id,
				// @ts-ignore
				roomId: subjectTeaching.roomId || rooms[0].id,
				isRepeatedWeekly: subjectTeaching.isRepeatedWeekly,
				startTime: startTime,
				duration: subjectTeaching.duration,
			});
			if (res) {
				dispatch(
					setAlert({
						title: `Tạo lịch học thành công`,
					})
				);
			}
			setModelShow(false);
			setSubjectTeaching({
				subjectId: subject,
				startDate: new Date(),
				endDate: new Date(),
				// @ts-ignore
				teacherId: teachers[0].id,
				// @ts-ignore
				roomId: rooms[0].id,
				isRepeatedWeekly: true,
				startTime: '09:00',
				duration: 120,
			});
			setChanges(!changes);
			dispatch(setLoading(false));
		} catch (e) {
			dispatch(setLoading(false));
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	async function deleteSubjectTeaching(e, id) {
		e.stopPropagation();
		setConfirmDialog({
			isOpen: true
			, title: 'Thông báo'
			, subTitle: 'Bạn có chắc muốn xóa ?'
		})
		setIdDelete(id);
	}

	async function deleteItem() {
		setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
		try {
			dispatch(setLoading(true));
			const res: any = await $api.schedule.subjectTeaching_delete(idDelete);
			if (res) {
				dispatch(
					setAlert({
						title: res?.message,
					})
				);
				dispatch(setLoading(false));
				setChanges(!changes);
			}
		} catch (e) {
			dispatch(setLoading(false));
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function geoScheduleTeaching(e, id) {
		e.stopPropagation();
		history.push('/thoi-khoa-bieu/lich-hoc/' + id);
	}

	function openModalCreate(e) {
		e.stopPropagation();
		setModelShow(true);
	}

	function selectedTeacher(teacher) {
		setSubjectTeaching({
			...subjectTeaching,
			// @ts-ignore
			teacherId: teacher.id,
		});
	}

	function selectedRoom(room) {
		setSubjectTeaching({
			...subjectTeaching,
			// @ts-ignore
			roomId: room.id,
		});
	}

	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}

	return (
		<DefaultLayout>
			<div className='faculties page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<a href={`/thoi-khoa-bieu?f=${fId}`}>
							Danh sách môn học
						</a>
						<div className='___title__box__last'>{`>`}</div>
						<div>Lịch học môn {findByID().name}</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div>
						<div className='flex justify-between items-end flex-wrap'>
							<div className='action--item flex justify-start items-end'>
								<div className='mr-4'>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Môn học
									</div>
									<select
										className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										value={subject}
										onChange={(e) => {
											setSubject(e.target.value);
										}}>
										{subjects.map((item: any) => {
											return (
												<option
													value={item.id}
													key={item.id}>
													{item.name}
												</option>
											);
										})}
									</select>
								</div>
								<div
									className='actions--item'
									onClick={(e) => {
										openModalCreate(e);
									}}>
									<Button
										label='Thêm lịch học'
										icon={
											<i className='far fa-calendar-alt' />
										}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='mt-8'>
						<YearSchedule
							data={schedule}
							deleteItem={deleteSubjectTeaching}
							clicked={geoScheduleTeaching}
						/>
					</div>
				</div>
			</div>
			<Modal
				show={modalShow}
				aria-labelledby='contained-modal-title-vcenter'
				onHide={() => setModelShow(false)}
				centered>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						Tạo lịch học
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Ngày bắt đầu
						</div>
						<DatePicker
							selected={new Date(subjectTeaching.startDate)}
							onChange={(date) => {
								setSubjectTeaching({
									...subjectTeaching,
									startDate: date,
								});
							}}
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					<div className='mt-2'>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Ngày kết thúc
						</div>
						<DatePicker
							selected={new Date(subjectTeaching.endDate)}
							onChange={(date) => {
								setSubjectTeaching({
									...subjectTeaching,
									endDate: date,
								});
							}}
							minDate={new Date(subjectTeaching.startDate)}
							dateFormat='dd/MM/yyyy'
						/>
					</div>
					<div className='mt-2'>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Thời gian bắt đầu buổi học
						</div>
						<input
							name='text'
							type='time'
							required
							className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
							placeholder='Thời gian bắt đầu buổi học'
							onChange={(e) => {
								setSubjectTeaching({
									...subjectTeaching,
									startTime: e.target.value,
								});
							}}
							// @ts-ignore
							value={subjectTeaching.startTime}
						/>
					</div>
					<div className='mt-2'>
						<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
							Thời gian buổi học (phút)
						</div>
						<input
							name='text'
							type='number'
							required
							className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
							placeholder='Nhập thời gian buổi học (120 phút)'
							onChange={(e) => {
								setSubjectTeaching({
									...subjectTeaching,
									duration: Number(e.target.value),
								});
							}}
							// @ts-ignore
							value={subjectTeaching.duration}
						/>
					</div>
					{teachers && teachers.length > 0 ? (
						<div className='mt-2'>
							<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
								Giảng viên
							</div>
							<List data={teachers} selected={selectedTeacher} />
						</div>
					) : (
						''
					)}
					{rooms && rooms.length > 0 ? (
						<div className='mt-2'>
							<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
								Phòng học
							</div>
							<List
								data={rooms}
								selected={selectedRoom}
								type='room'
							/>
						</div>
					) : (
						''
					)}
				</Modal.Body>
				<Modal.Footer>
					<div
						onClick={() => {
							createSubjectTeaching();
						}}>
						<Button label='Tạo mới' />
					</div>
				</Modal.Footer>
			</Modal>
			<ConfirmDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
				deleteItem={deleteItem}
			/>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</DefaultLayout>
	);
}

export default Schedule__Faculties;
