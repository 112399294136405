import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStateIfMounted } from 'use-state-if-mounted';
import { setAlert, setLoading } from '../../../redux/authSlice';
import { $api } from '../../../services/service';
import moment from 'moment/moment';
import DefaultLayout from '../../../layouts/DefaultLayout/DefaultLayout';
import ErrorDialog from '../../../components/Elements/ErrorDialog/ErrorDialog';
import { useHistory, useLocation, useParams } from 'react-router';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Form_Request__View() {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    let r: any = query.get('r');

    // @ts-ignore
    let { id } = useParams();
    const [updateMode, setUpdateMode] = useStateIfMounted(false);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [role, setRole] = useState(99);
    const [formTemplate, setFormTemplate] = useState<any>();
    useEffect(() => {
        if (r && r == 2) {
            setRole(Number(r));
        } else if (r) {
            history.push('/bieu-mau-xac-nhan');
        }

        //
        async function getRequestDetail(id) {
            try {
                dispatch(setLoading(true));
                let res;
                res = await $api.formRequest.get(id);
                if (res && res.result) {
                    console.log(res.result);
                    setUpdateMode(true);
                    setFormTemplate(res.result);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                setUpdateMode(false);
            }
        }

        getRequestDetail(id);

    }, [updateMode]);

    function cancel() {
        history.push(`/bieu-mau-xac-nhan`);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function saveTemplate() {
        try {
            dispatch(setLoading(true));
            let res;
            res = await $api.formRequest.changeStatus({ id: formTemplate.id, status: formTemplate.status });
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                cancel()
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <a href='/quan-ly-chung'>Biểu mẫu xác nhận</a>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>
                            Chi tiết xác nhận
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='py-2'>
                        <div className='label-form font-bold'>
                            Thông tin sinh viên:
                        </div>
                        <div className={'grid grid-cols-1 md:grid-cols-3 gap-4 mt-3'}>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Họ tên
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập họ tên'}
                                    value={formTemplate?.student.user.fullName || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày sinh
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập ngày sinh'}
                                    value={moment(formTemplate?.student.user.birthDate).format('DD/MM/YYYY') || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Giới tính
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập giới tính'}
                                    value={'Chưa có thông tin giới tính' || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    CMND/CCCD
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập cmnd/cccd'}
                                    value={formTemplate?.student.user.identificationNumber || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày cấp
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập ngày cấp cmnd/cccd'}
                                    value={'Chưa có thông tin ngày cấp' || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Nơi cấp
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập nơi cấp cmnd/cccd'}
                                    value={'Chưa có thông tin nơi cấp' || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Địa chỉ
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập địa chỉ'}
                                    value={'Chưa có thông tin địa chỉ' || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Lớp
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập lớp'}
                                    value={formTemplate?.student.class.name || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngành học
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập ngành học'}
                                    value={formTemplate?.student.class.faculty.name || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Khóa
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập khóa học'}
                                    value={moment(formTemplate?.student.class.startDate).format('YYYY') || ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='py-2'>
                        <div className='label-form font-bold'>
                            Thông tin xác nhận:
                        </div>
                        <div className={'grid grid-cols-1  md:grid-cols-2 gap-4 mt-3'}>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Tên xác nhận
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập họ tên'}
                                    value={formTemplate?.formTemplateName || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày yêu cầu
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập họ tên'}
                                    value={moment(formTemplate?.creationDate).format('DD/MM/YYYY') || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ghi chú
                                </div>
                                <textarea
                                    rows={3}
                                    name='text'
                                    className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={'Nhập họ tên'}
                                    value={moment(formTemplate?.creationDate).format('DD/MM/YYYY') || ''}
                                />
                            </div>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Trạng thái
                                </div>
                                <select
                                    className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                    value={formTemplate?.status}
                                    onChange={(e) => {
                                        setFormTemplate({ ...formTemplate, status: e.target.value });
                                    }}>
                                    <option
                                        value={1}
                                        key={1}>
                                        Chưa xác nhận
                                    </option>
                                    <option
                                        value={2}
                                        key={2}>
                                        Đang xử lý
                                    </option>
                                    <option
                                        value={3}
                                        key={3}>
                                        Đã xác nhận
                                    </option>
                                    <option
                                        value={4}
                                        key={4}>
                                        Đã nhận kết quả
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-center w-100 mt-4'>
                                <button
                                    type='button'
                                    className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
                                    onClick={cancel}>
                                    Hủy Bỏ
                                </button>
                                <button
                                    onClick={() => saveTemplate()}
                                    type='submit'
                                    className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                    Lưu lại
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Form_Request__View;