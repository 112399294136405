import React from 'react';

import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import '../auth.scss';
import ChangePasswordForm from 'components/Auth/ChangePassworForm/ChangePasswordForm';

function ChangePassword() {
	return (
		<AuthLayout>
			<div className='auth h-screen w-screen'>
				<div className='login-dialog fixed left-2/4 top-2/4'>
					<div>
						<ChangePasswordForm />
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}

export default ChangePassword;
