import React, { useState } from 'react';
import './login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert, setToken, setLoading } from 'redux/authSlice';
import jwt_decode from 'jwt-decode';
import { $api } from 'services/service';
import { _AUTH } from 'constants/_auth';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function Login() {
	const [username, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const dispatch = useDispatch();

	const submit = async (e) => {
		e.preventDefault();
		// @ts-ignore
		try {
			const res: any = await $api.auth.login({
				username,
				password,
			});
			// var token = response.headers.authorization;
			// token = token.replace('Bearer', '');
			// var jwt = require('jsonwebtoken');
			let obj_Login: [] = jwt_decode(res.result);
			if (obj_Login['Role'] === 'Admin' || obj_Login['Role'] === 'TeacherStaff'|| obj_Login['Role'] === 'Teacher') {
				localStorage.setItem(_AUTH.TOKEN_NAME, res.result);
				localStorage.setItem(_AUTH.USERNAME, obj_Login['UserName']);
				localStorage.setItem(_AUTH.ROLE, obj_Login['Role']);
				dispatch(
					// @ts-ignore
					setToken({})
				);
				if ( obj_Login['Role'] === 'Teacher'){
					window.location.href = '/thoi-khoa-bieu';

				}else{
					window.location.href = '/';
				}
			}
			else {
				// dispatch(
				// 	setAlert({
				// 		type: 'danger',
				// 		title: 'Người dùng không thể đăng nhập vào hệ thống',
				// 	})
				// );
				show_ErrMsg('Người dùng không thể đăng nhập vào hệ thống');
			}

		} catch (e) {
			// dispatch(
			// 	setAlert({
			// 		type: 'danger',
			// 		title: 'Tài khoản hoặc mật khẩu không chính xác',
			// 	})
			// );
			show_ErrMsg('Tài khoản hoặc mật khẩu không chính xác');
		}
	};

	const forgotPassword = async (e) => {
		if (username && username.length > 0) {
			try {
				const res: any = await $api.auth.forgotPassword(username);
				dispatch(
					setAlert({
						title: res.message,
					})
				);
			} catch (e) {
				// dispatch(
				// 	setAlert({
				// 		type: 'danger',
				// 		title:
				// 			e?.response?.data?.errors?.exceptionMessage ||
				// 			'Lỗi',
				// 	})
				// );
				show_ErrMsg(e?.response?.data?.errors?.exceptionMessage || 'Lỗi');
			}
		} else {
			// dispatch(
			// 	setAlert({
			// 		type: 'danger',
			// 		title: 'Không được bỏ trống tên tài khoản',
			// 	})
			// );
			show_ErrMsg('Không được bỏ trống tên tài khoản');
		}
	};

function show_ErrMsg(msg){
	setErrDialog({
		isOpen: true
		, subTitle: msg
	});
}

	return (
		<div className='login bg-white rounded-xl h-full w-full flex justify-center items-center flex-col'>
			<div className='login__logo rounded-full border-8 border-white bg-white absolute'>
				<img
					className='img-logo rounded-full'
					src='/logo.png'
					alt='Cao Đẳng Tây Đô'
					title='Cao Đẳng Tây Đô'
				/>
			</div>
			<div className='login__content'>
				<div className='font-bold text-xl text-center mb-2.5 color-sapphire'>
					Đăng Nhập
				</div>
				<div>
					Chào mừng bạn đến với{' '}
					<span className='color-my-sin font-bold'>
						Cao Đẳng Tây Đô
					</span>
				</div>
				<div>
					<form
						className='mt-4 space-y-4 flex item-center
						flex-col justify-center'
						onSubmit={(e) => submit(e)}
						onKeyDown={(e) => e.key === 'Enter' && submit(e)}>
						<div>
							<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
								Tên đăng nhập
							</div>
							<input
								name='email'
								type='text'
								required
								className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
								placeholder='Nhập tên đăng nhập'
								value={username}
								onChange={(e) => setUserName(e.target.value)}
							/>
						</div>
						<div>
							<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
								Mật khẩu
							</div>
							<input
								name='password'
								type='password'
								autoComplete='password'
								required
								className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
								placeholder='Nhập mật khẩu'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className='item-center flex justify-between'>
							<div
								className='text-xs pl-1 -mt-2 cursor-pointer
								hover:text-blue-700'
								onClick={forgotPassword}>
								Quên mật khẩu?
							</div>
						</div>
						<div>
							<button
								type='submit'
								className='w-full text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
								Đăng Nhập
							</button>
						</div>
					</form>
				</div>
			</div>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</div>
	);
}

export default Login;
