import './year-schedule.scss';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Moment from 'react-moment';

function YearSchedule(props) {
	const [time, setTime] = useState([
		{
			month: 1,
			year: 2021,
		},
	]);

	const [position, setPosition] = useState(0);

	useEffect(() => {
		const nowYear = new Date().getFullYear();
		const array: any = [];
		for (let i = 6; i > 0; i--) {
			for (let j = 0; j <= 11; j++) {
				array.push({
					year: nowYear + 3 - i,
					month: j + 1,
				});
			}
		}
		const startDate: any = new Date(new Date().getFullYear() - 3, 0, 0);
		const dateIndex = getIndexDate(new Date(), startDate);
		// @ts-ignore
		document.getElementById('year-schedule').scrollTo(dateIndex * 2.9, 0);
		setPosition(dateIndex);
		setTime(array);
	}, [props.data]);

	function getIndexDate(pointValue, startValue = null) {
		const date = new Date(pointValue);
		// @ts-ignore
		const start = startValue
			? // @ts-ignore
			  new Date(startValue)
			: new Date(time[0].year, 0, 0);
		// @ts-ignore
		const diff = date - start;
		const oneDay = 1000 * 60 * 60 * 24;
		return Math.floor(diff / oneDay);
	}

	function getPositionBar(value, startValue = null) {
		return getIndexDate(value, startValue) * 3.287671232876712;
	}

	return (
		<div className='year-schedule' id='year-schedule'>
			<div className='year-schedule__header hidden'>Header</div>
			<div className='year-schedule__content'>
				<div
					className='c-header bg-gray-200
                                text-xs font-medium text-gray-500
                                uppercase rounded-sm schedule-grid'>
					{time.map((item) => {
						return (
							<div
								key={item.month + '_' + item.year}
								className='month c-header--item'>
								Tháng {item.month}/{item.year}
							</div>
						);
					})}
				</div>
				{props.data.length > 0 ? (
					<div className='c-content'>
						{props.data.map((d) => {
							return (
								<div
									className='c-content--item rounded-lg'
									key={d.id}>
									<div className='c-content--item_child'>
										<div
											className='schedule-progress-bar w-10/12'
											style={{
												marginLeft:
													getPositionBar(
														d.startDate
													) + 'px',
												width:
													getPositionBar(
														d.endDate,
														d.startDate
													) + 'px',
											}}
											onClick={(e) =>
												props.clicked(e, d.id)
											}>
											<div
												className='delete text-red-700'
												onClick={(e) => {
													props.deleteItem(e, d.id);
												}}>
												<i className='fas fa-times-circle' />
											</div>
											<span>
												Từ{' '}
												{d.startDate &&
												moment(
													d.startDate
												).isValid() ? (
													// @ts-ignore
													<Moment format='DD/MM/yyyy'>
														{d.startDate}
													</Moment>
												) : (
													<div>{d.startDate}</div>
												)}{' '}
												đến{' '}
												{d.endDate &&
												moment(d.endDate).isValid() ? (
													// @ts-ignore
													<Moment format='DD/MM/yyyy'>
														{d.endDate}
													</Moment>
												) : (
													<div>{d.endDate}</div>
												)}{' '}
												({d?.teacher?.fullName})
											</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				) : (
					<div className='font-medium text-gray-500 pl-2'>
						<i className='fad fa-info-circle text-yellow-500 mr-2' />
						Không có lịch học của môn này
					</div>
				)}
			</div>
		</div>
	);
}

export default YearSchedule;
