import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ErrorDialog from '../../../components/Elements/ErrorDialog/ErrorDialog';
import DefaultLayout from '../../../layouts/DefaultLayout/DefaultLayout';
import Button from '../../../components/Elements/Button/Button';
import './Student__Notification.css';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/authSlice';
import classService from '../../../services/class.service';
import TableNotificationClass from '../../../components/Elements/Table/Table-Notification-Class';
import { Modal } from 'react-bootstrap';
import ConfirmDialog from '../../../components/Elements/ConfirmDialog/ConfirmDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import userAnnouncements from '../../../services/userAnnouncements.service';

const StudentNotification = () => {
    const [totalRecord, setTotalRecord] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [noti, setNoti] = useState({message: '', enforceRead: false});
    const [notification, setNotification] = useState([]);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [classInfo, setClassInfo] = useState({});
    const dispatch = useDispatch();
    // @ts-ignore
    const { id } = useParams();
    const history = useHistory();
    useEffect(() => {
        getClass();
        getNotification()
    }, []);

    function getClass() {
        dispatch(setLoading(true));
        classService.getDetail(id).then(result => {
            setClassInfo(result['result']);
            dispatch(setLoading(false));
        }).catch(err => {
            dispatch(setLoading(false));
            setErrDialog({ isOpen: true, subTitle: 'Có lỗi khi lấy thông tin lớp' });
        });
    }

    function getNotification() {
        dispatch(setLoading(true));
        userAnnouncements.getPaging({
            entityObjectId: id
        }).then(result => {
            setNotification(result['result']['items']);
            setTotalRecord(result['result']['totalRecord']);
            dispatch(setLoading(false));
        }).catch(err => {
            dispatch(setLoading(false));
            setErrDialog({ isOpen: true, subTitle: 'Có lỗi khi lấy thông báo lớp' });
        });
    }

    function addNewNotification() {
        dispatch(setLoading(true));
        classService.createNotificationClass({
            classId: id,
            message: noti.message,
            enforceRead: noti.enforceRead
        }).then(result => {
            setConfirmDialog({isOpen: false, subTitle: '', title: ''});
            setModalShow(false);
            setNoti({message: '', enforceRead: false});
            setErrDialog({ isOpen: true, subTitle: result['message'] });
            getNotification();
            dispatch(setLoading(false));
        }).catch(err => {
            dispatch(setLoading(false));
            if(err['errors']['exceptionMessage'].length){
                setErrDialog({ isOpen: true, subTitle: err['errors']['exceptionMessage']});
            }else{
                setErrDialog({ isOpen: true, subTitle: 'Có lỗi khi tạo thông báo cho lớp!'});
            }
        })
    }

    function confirm() {
        if (noti.message.length) {
            setConfirmDialog({
                isOpen: true,
                title: 'Xác nhận',
                subTitle: 'Bạn muốn gửi thông báo: "'+noti.message+'" Cho ' + `${classInfo['name']}` + '?'
            });
        } else {
            setErrDialog({
                isOpen: true,
                subTitle: 'Bạn chưa nhập nội dung thông báo!'
            });
        }
    }

    return (
        <DefaultLayout>
            <div className='faculties page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        Thông báo {classInfo?.['name']} - {classInfo?.['facultyName']}
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='d-flex justify-content-between '>
                        <div onClick={history.goBack}>
                            <Button
                                label='Quay lại'
                                // icon={<i className='far fa-arrow-left' />}
                            />
                        </div>
                        <div onClick={() => setModalShow(true)}>
                            <Button
                                label='Tạo thông báo cho lớp'
                                color={'bg-red-800'}
                                hoverColor={'bg-red-900'}
                                icon={<i className='far fa-bell' />}
                            />
                        </div>
                    </div>
                    {
                        totalRecord > 0 ? <div>
                            <div className={'mt-5'}>
                                <TableNotificationClass data={notification} />
                            </div>
                        </div> : ''
                    }
                </div>
                <Modal
                    show={modalShow}
                    aria-labelledby='contained-modal-title-vcenter'
                    onHide={() => {
                        setModalShow(false);
                        setNoti({message: '', enforceRead: false});
                    }}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id='contained-modal-title-vcenter'>
                            <i className='far fa-bell' /> Thêm thông báo cho lớp học
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                <i className={'far fa-pencil'} /> Nội dung thông báo
                            </div>
                            <textarea
                                name='text'
                                maxLength={100}
                                rows={5}
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Nhập nội dung thông báo'
                                value={noti.message}
                                onChange={(e) => {
                                    setNoti({...noti, message: e.target.value});
                                }}
                            />
                        </div>
                        <div className='mt-2 flex center'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600'>
                                Thông báo quan trọng
                            </div>
                            <input
                                name={'enforceRead'}
                                type='checkbox'
                                className='h-4 w-4 mt-0.5 ml-4'
                                onChange={(e) => {
                                    setNoti({
                                        ...noti,
                                        // @ts-ignore
                                        enforceRead: e.target.checked
                                    });
                                }}
                                // @ts-ignore
                                checked={noti.enforceRead}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div onClick={() => {
                            setModalShow(false);
                            setNoti({message: '', enforceRead: false});
                        }}>
                            <Button
                                label={'Hủy'}
                                color={'bg-red-800'}
                                hoverColor={'bg-red-900'}
                                icon={<i className='far fa-times-circle' />}
                            />
                        </div>
                        <div
                            onClick={() => {
                                confirm();
                            }}>
                            <Button
                                color={'bg-green-800'}
                                hoverColor={'bg-green-900'}
                                icon={<i className={'far fa-bell'} />}
                                label={'Tạo mới'}
                            />
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={() => addNewNotification()}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog} />
        </DefaultLayout>
    );
};

export default StudentNotification;