// @ts-ignore
import moment from 'moment-timezone';

function TableLog(props) {
    function getAction(action) {
        switch (action) {
            case 1:
                return 'Thêm';
            case 2:
                return 'Cập nhật';
            case 3:
                return 'Xóa';
        }
    }

    return (
        <table className='min-w-full divide-y divide-gray-200 '>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Hoạt động
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Mô tả
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                >
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <span
                            className={'badge bg-' + (item.action === 1 ? 'success' : item.action === 2 ? 'warning' : 'danger')}>{getAction(item.action) || ''}</span>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900' style={{ fontWeight: 'bold' }}>
                            {moment(item.creationDate ).format('DD/MM/YYYY hh:mm:ss') || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div
                            className={'text-sm text-white p-2 rounded bg-' + (item.action === 1 ? 'success' : item.action === 2 ? 'warning text-dark' : 'danger')}>
                            {item.recordDesc || ''}
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableLog;
