import React, { useEffect } from 'react';

import Login from 'components/Auth/Login/Login';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import './auth.scss';
import { _AUTH } from '../../constants/_auth';

function Auth() {
	const token: any = localStorage.getItem(_AUTH.TOKEN_NAME);
	useEffect(() => {
		if (token) {
			window.location.href = '/';
		}
	});
	return (
		<AuthLayout>
			<div className='auth'>
				<div className='login-dialog'>
					<Login />
				</div>
			</div>
		</AuthLayout>
	);
}

export default Auth;
