import axiosClient from 'config/axiosClient';

const _url = '/Api/Students';
const studentService = {
	getAll: () => {
		return axiosClient.post(_url + '/GetAll');
	},
	getPaging: (option) => {
		return axiosClient.post(_url + '/GetPaging', option);
	},
	getDetail: (id) => {
		return axiosClient.post(_url + '/Get', { id: id });
	},
	createStudent: (data) => {
		return axiosClient.post(_url + '/Create', data);
	},
	updateStudent: (data) => {
		return axiosClient.post(_url + '/Update', data);
	},

	blockStudent: (data) => {
		return axiosClient.post(_url + '/UpdateIssue', data);
	},
	deleteStudent: (id) => {
		return axiosClient.post(_url + '/Delete', { id: id });
	},
	downloadTemplate: () => {
		return axiosClient.get(_url + '/Template', {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type':
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
		});
	},
	importStudent: (data, id) => {
		return axiosClient.post(_url + '/Import?ClassId=' + id, data);
	},
	exportStudent: (id) => {
		return axiosClient.get(_url + '/Export?ClassId=' + id, {
			responseType: 'arraybuffer',
			headers: {
				'Content-Type':
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
		});
	},
};

export default studentService;
