import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Button from '../../components/Elements/Button/Button';
import DatePicker from 'react-datepicker';
import { format } from 'util';
import moment from 'moment-timezone';
import TableLog from '../../components/Elements/Table/Table-Log';
import Pagination from '../../components/Elements/Pagination/Pagination';
import auditLogService from '../../services/audit-log.service';
import { setLoading } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import ErrorDialog from '../../components/Elements/ErrorDialog/ErrorDialog';

const AuditLog = () => {
    const dispatch = useDispatch();
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [postData, setPostData] = useState({
        freeTextSearch: '',
        pageSize: 20,
        pageIndex: 1,
        creationDate: new Date()
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const [log, setLog] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (e) => {
        setIsOpen(!isOpen);
        setPostData({ ...postData, creationDate: e, pageIndex: 1 });
    };
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        searchAPI();
    }, [postData.pageIndex, postData.creationDate]);

    function show_ErrMsg(msg: any) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function searchAPI() {
        dispatch(setLoading(true));
        auditLogService.getPaging(postData).then(results => {
            setLog(results['result'].items);
            setTotalRecord(results['result']['totalRecord']);
            dispatch(setLoading(false));
        }).catch(e => {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        });
    }

    function changePage(page) {
        setPostData({ ...postData, pageIndex: page });
    }

    function search() {
        setPostData({ ...postData, pageIndex: 1 });
        searchAPI();
    }

    return (
        <DefaultLayout>
            <div className='faculties page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        Audit Log
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='d-flex justify-content-between '>
                        <div>
                            <button className='btn btn-success' onClick={handleClick}>
                                <i className='far fa-calendar' /> {moment(new Date(postData.creationDate)).format('DD/MM/YYYY')}
                            </button>
                            {isOpen && (
                                <DatePicker selected={postData.creationDate} onChange={handleChange} inline />
                            )}
                        </div>
                        <div className='d-flex'>
                            <input
                                name='search'
                                type='text'
                                required
                                className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder={
                                    'Tìm kiếm audit log'
                                }
                                value={postData.freeTextSearch}
                                onChange={(e) => setPostData({ ...postData, freeTextSearch: e.target.value })}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && search()
                                }
                            />
                            <div onClick={search}>
                                <Button
                                    label='Tìm kiếm'
                                    color='bg-yellow-500'
                                    hoverColor='bg-yellow-400'
                                />
                            </div>
                        </div>
                    </div>
                    {
                        totalRecord > 0 ? <div>
                            <div className={'mt-5'}>
                                <TableLog data={log} />
                            </div>
                            <div className='pagination'>
                                <Pagination
                                    page={postData.pageIndex}
                                    total={totalRecord}
                                    pages={Math.ceil(totalRecord / postData.pageSize)}
                                    clickedPage={changePage}
                                />
                            </div>
                        </div> : ""
                    }
                </div>
            </div>
            < ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
};

export default AuditLog;