import { _ENUMS } from 'constants/_enums';
import { useDispatch } from 'react-redux';

function TableStudentManager(props) {
    const dispatch = useDispatch();
    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thông tin sinh viên
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Lớp
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số điện thoại
                </th>
                {/*<th*/}
                {/*    scope='col'*/}
                {/*    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>*/}
                {/*    hành động*/}
                {/*</th>*/}
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                    onClick={(e) => {
                        props.clicked(e, item.id, item.classId);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-center'>
                            <div className='flex-shrink-0 h-10 w-10'>
                                <img
                                    className='h-10 w-10 rounded-full'
                                    src={item.profilePicUrl || _ENUMS.IMAGE}
                                    alt={item.name}
                                    title={item.name}
                                />
                            </div>
                            <div className='ml-4'>
                                <div className='text-sm font-medium text-gray-900'>
                                    {item.fullName || ''}
                                </div>
                                <div className='text-sm text-gray-900'>
                                    MSSV: {item.userInternalId || ''}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.className || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.mobile || ''}
                        </div>
                    </td>
                    {/*<td className='px-6 py-4 whitespace-nowrap text-right text-sm'>*/}
                    {/*    <div className='flex items-center'>*/}
                    {/*        <div*/}
                    {/*            className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'*/}
                    {/*            onClick={(e) => {*/}
                    {/*                props.updateClicked(e, item.id, item.classId);*/}
                    {/*            }}>*/}
                    {/*            <i className='fas fa-user-edit mr-2' />*/}
                    {/*            Chỉnh sửa*/}
                    {/*        </div>*/}
                    {/*        <span className='mx-2'>/</span>*/}
                    {/*        <div*/}
                    {/*            className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'*/}
                    {/*            onClick={(e) => {*/}
                    {/*                props.deleteClicked(e, item.id, item.classId);*/}
                    {/*            }}>*/}
                    {/*            <i className='far fa-trash-alt mr-2' />*/}
                    {/*            Xóa*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableStudentManager;
