// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';

function TableSubjectOnDay(props) {
    return (
        <div className="overflow-x-auto">
            <h2 className={'text-uppercase pb-2 text-lg font-medium text-center'}>Danh sách lịch học ngày <span className={'text-danger'}>{moment(props.data[0].startDateTime + "Z").format('DD-MM-YYYY') }</span></h2>
            <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                <tr>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        STT
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Khoa
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Môn
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Lớp
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Giờ học
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Phòng học
                    </th>
                    <th
                        scope='col'
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Giảng viên
                    </th>
                </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
                {props.data.map((item, index) => (

                    <tr
                        className={`hover:bg-gray-100 ${ moment(item.endDateTime + "Z").format("A") == "PM" ? 'bg-yellow-50' : "bg-blue-50"}`}
                        key={item.id}
                        onClick={(e) => {
                            props.clicked(e, item.subjectTeachingId);
                        }}>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-gray-900 font-medium'>
                                {index + 1}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-gray-900 font-medium'>
                                {item.facultyName}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-red-500 font-medium'>
                                {item.subjectName}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-gray-900 font-medium'>
                                {item.subjectTeachingName}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-red-500 font-medium'>
                                {
                                    moment(item.startDateTime + "Z").format('HH:mm') + ' - ' + moment(item.endDateTime + "Z").format('HH:mm')
                                }
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-gray-900 font-medium'>
                                {item.roomName}
                            </div>
                        </td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                            <div className='text-sm text-left text-gray-900 font-medium'>
                                {item.teacherName}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableSubjectOnDay;
