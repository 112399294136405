import Button from 'components/Elements/Button/Button';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import './SemesterPlanning.scss';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/authSlice';
import { useHistory } from 'react-router-dom';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { useLocation } from 'react-router';
import TableSemesterPlanning from '../../components/Elements/Table/Table-Semester-Planning';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SemesterPlanning() {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    const [classActive, setClassActive] = useState([]);
    const [classInActive, setClassInactive] = useState([]);
    const [changes, setChanges] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [graduated, setGraduated] = useState('0');
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);
    let fId: any = localStorage.getItem('semester_faculty') ? localStorage.getItem('semester_faculty') : query.get('f');
    useEffect(() => {
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.getPaging({});
                if (res && res.result) {
                    setFaculties(res.result.items);
                    if (fId && fId.length > 0) {
                        setFaculty(fId);
                    } else {
                        setFaculty(res.result.items[0].id);
                    }
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFaculties();
    }, []);
    useEffect(() => {
        getClasses({
            isGraduated: Number(graduated) === 1,
            facultyId: faculty || faculty != null ? faculty : null,
            pageIndex: page,
            pageSize: 9,
            freeTextSearch: search || search.length > 0 ? search : null
        });
    }, [changes, faculty, graduated]);

    async function getClasses(option = {}) {
        try {
            if (faculty) {
                dispatch(setLoading(true));
                const res: any = await $api.class.getList(option);
                if (res && res.result) {
                    setTotal(res.result.totalRecord);
                    setClassActive(res.result.active);
                    setClassInactive(res.result.inActive);
                    setPages(Math.ceil(res.result.totalRecord / 9));
                    dispatch(setLoading(false));

                }
            }
        } catch (e) {
            dispatch(setLoading(false));
        }
    }

    async function searchAPI() {
        setChanges(!changes);
    }

    async function deleteClass(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    function getSemesterPlanning(e, id) {
        e.stopPropagation();
        history.push(`/ke-hoach-toan-khoa/${faculty}/${id}`);
    }


    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    useEffect(() => {
        history.push(`/ke-hoach-toan-khoa?f=${fId}`);
        localStorage.setItem('semester_faculty', faculty);
    }, [faculty]);
    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <div>Kế hoạch toàn khóa</div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='mt-4'>
                        <div className='flex justify-between items-end flex-wrap'>
                            <div className='action--item flex justify-start items-center'>
                                <div className='mr-4'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Khoa
                                    </div>
                                    <select
                                        className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        value={faculty}
                                        onChange={(e) => {
                                            setFaculty(e.target.value);
                                        }}>
                                        {faculties.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='action--item search flex justify-start mt-4'>
                                <input
                                    name='search'
                                    type='text'
                                    required
                                    className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Tìm kiếm lớp học'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) =>
                                        e.key === 'Enter' && searchAPI()
                                    }
                                />
                                <div onClick={searchAPI}>
                                    <Button
                                        label='Tìm kiếm'
                                        color='bg-yellow-500'
                                        hoverColor='bg-yellow-400'
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className='pt-3 text-uppercase text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
                            Lớp đang học
                        </div>
                        {
                            classActive.length === 0 ?
                                <div className='faculties--list flex flex-wrap -ml-4 -mb-4'
                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    Không có dữ liệu lớp active
                                </div>
                                :
                                <div className='w-auto-scroll'>
                                    <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableSemesterPlanning
                                            data={classActive}
                                            updateClick={getSemesterPlanning}
                                            clicked={getSemesterPlanning}
                                        />
                                    </div>
                                </div>
                        }
                        <div
                            className='pt-3 text-uppercase text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
                            Lớp đã tốt nghiệp
                        </div>
                        {
                            classInActive.length === 0 ?
                                <div className='faculties--list flex flex-wrap -ml-4 -mb-4'
                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    Không có dữ liệu lớp
                                </div>
                                :
                                <div className='w-auto-scroll'>
                                    <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableSemesterPlanning
                                            data={classInActive}
                                            updateClick={getSemesterPlanning}
                                            clicked={getSemesterPlanning}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default SemesterPlanning;
