import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import './faculties__list.scss';
import React, { useEffect, useRef, useState } from 'react';
import { $api } from 'services/service';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/authSlice';
import { useHistory } from 'react-router-dom';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import Calendar from '../../../components/Elements/Calender/Calendar';
import { useParams } from 'react-router';
import TableSubjectTeachingRoom from '../../../components/Elements/Table/TableSubjectTeachingRoom';
import Button from '../../../components/Elements/Button/Button';

function SubjectRoom() {
    let history = useHistory();
    const ref = useRef();
    const dispatch = useDispatch();
    // @ts-ignore
    const { fId, cId } = useParams();
    const [subjectOnDay, setSubjectOnDay] = useState([]);
    const [viewType, setViewType] = useState('default');
    const [dateStr, setDateStr] = useState('');
    const [freeTextSearch, setFreeTextSearch] = useState('');
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [isMonth, setIsMonth] = useState({
        start: new Date(),
        end: new Date()
    });
    const [calendarSubject, setCalendarSubject] = useState([{
        id: '',
        title: '',
        start: '',
        end: '',
        backgroundColor: '#1e3a8a',
        eventName: 'subject'
    }]);
    const [subjectTeaching, setSubjectTeaching] = useState({
        id: '',
        subjectId: '',
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '00:00',
        teacherId: '',
        roomIdDefault: ''
    });

    const [subjectScheduleExport, setSubjectScheduleExport] = useState(
        {
            startDate: new Date(),
            endDate: new Date(new Date().setMonth(new Date().getMonth() + 6)),
            classId: cId
        }
    );

    useEffect(() => {
        getSubjectOnDate(new Date());
    }, [dateStr, viewType]);

    async function getSubjectOnDate(dateStr) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedules_GetOptionSchedule({
                'date': dateStr,
                'freeTextSearch': freeTextSearch,
                'isWeekDisplay': viewType == 'week'
            });
            if (res && res.result) {
                const arraySubject = [];
                if (res.result) {
                    setSubjectOnDay(res.result);
                    // @ts-ignore
                    ref.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start'
                    });
                } else {
                    setSubjectOnDay([]);
                }
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    useEffect(() => {
        setCalendarSubject([{
            id: '',
            title: '',
            start: '',
            end: '',
            backgroundColor: '#1e3a8a',
            eventName: 'subject'
        }]);
        setSubjectScheduleExport({ ...subjectScheduleExport, classId: cId });
    }, [isMonth]);

    useEffect(() => {
        async function getTeachers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.teacher.getAll();
                if (res.result.length !== 0) {
                    subjectTeaching.teacherId = res.result[0].id;
                    setSubjectTeaching({
                        ...subjectTeaching
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        teacherId: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getAll();
                if (res['result'].length !== 0) {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: res.result[0].id
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getRooms();
        getTeachers();
    }, []);


    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }


    // @ts-ignore
    return (
        <DefaultLayout>
            <div className='subject page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div>Thời khóa biểu phòng</div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='flex justify-between w-full'>
                            <div className='mt-2'>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Chế độ xem
                                </div>
                                <select
                                    className='input-custom block
												w-100 px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                    value={viewType}
                                    onChange={(e) => {
                                        setViewType(() => e.target.value);
                                    }}>
                                    <option
                                        value={'default'}>
                                        Xem theo ngày
                                    </option>
                                    <option
                                        value={'week'}>
                                        Xem theo tuần
                                    </option>
                                </select>
                            </div>
                            <div className={'actions flex justify-end align-bottom h-100 mt-4'}>
                                <input
                                    name='search'
                                    type='text'
                                    required
                                    className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder={
                                        'Tìm kiếm theo phòng '
                                    }
                                    value={freeTextSearch}
                                    onChange={(e) => setFreeTextSearch(e.target.value)}
                                    onKeyDown={(e) =>
                                        e.key === 'Enter' && getSubjectOnDate(dateStr)
                                    }
                                />
                                <div onClick={() => getSubjectOnDate(dateStr)}>
                                    <Button
                                        label='Tìm kiếm'
                                        color='bg-yellow-500'
                                        hoverColor='bg-yellow-400'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <Calendar
                        aspectRatio={3}
                        data={calendarSubject}
                        isEventClicked={id => {
                        }}
                        isEventDateClicked={dateStr => getSubjectOnDate(dateStr)}
                        isEventChangeMonthClicked={(startStr, endStr) => setIsMonth({
                            start: startStr,
                            end: endStr
                        })}
                    />
                </div>
                {
                    // @ts-ignore
                    <div className='page__content bg-white mt-3' ref={ref}>
                        <TableSubjectTeachingRoom data={subjectOnDay}
                                                  clicked={(e, subjectTeachingId) => console.log(e)} />
                    </div>
                }
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default SubjectRoom;
