// @ts-ignore
import Moment from 'react-moment';
import { _ENUMS } from 'constants/_enums';

function TableRoom(props) {

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên phòng
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số ghế
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    hành động
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr className='hover:bg-gray-100' key={item.id}
                    onClick={(e) => {
                        props.updateClicked(e, item.id, item.name, item.numberOfSeats);
                    }}>
                    <td className='px-6 py-4 whitespace-nowrap w-6/12'>
                        <div className='flex items-center'>
                            <div>
                                <div className='text-sm font-medium text-gray-900'>
                                    {item.name || ''}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap w-3/12'>
                        <div className='text-sm text-gray-900'>
                            {item.numberOfSeats || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            <div
                                className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.updateClicked(e, item.id, item.name, item.numberOfSeats);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Chỉnh sửa
                            </div>
                            <span className='mx-2'>/</span>
                            <div
                                className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.deleteClicked(e, item.id);
                                }}>
                                <i className='far fa-trash-alt mr-2' />
                                Xóa
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableRoom;
