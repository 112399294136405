import moment from 'moment';
import React from 'react';

function TableNotificationClass(props) {
    return (
        <table className='min-w-full divide-y divide-gray-200 '>
            <thead className='bg-gray-50' >
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thông báo quan trọng
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày tạo thông báo
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Nội dung thông báo
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                >
                    <td width={'15%'} className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-left text-gray-900' style={{fontWeight: 'bold'}}>
                            <input
                                name={'enforceRead'}
                                type='checkbox'
                                className='h-4 w-4 mt-0.5 ml-4'
                                // @ts-ignore
                                checked={item.enforceRead}
                            />
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-left text-gray-900' style={{fontWeight: 'bold'}}>
                            {moment(new Date(item.creationDate)).format('DD/MM/YYYY hh:mm:ss') || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className={'text-sm text-left text-danger p-2 rounded'} style={{fontWeight: 'bold'}}>
                            {item.message || ''}
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableNotificationClass;
