import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import { setAlert, setLoading } from '../../redux/authSlice';
import { $api } from '../../services/service';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ErrorDialog from '../../components/Elements/ErrorDialog/ErrorDialog';
import check from '../../assets/icons/checkbox-selected.svg';
import un_check from '../../assets/icons/checkbox-unselected.svg';
import './attendance.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Attendance() {
    let history = useHistory();
    const ref = useRef();
    const query = useQuery();
    const dispatch = useDispatch();
    let idSubjectSchedule: any = query.get('id');
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [attendances, setAttendances] = useState<any>([]);
    const [allCheck, setAllCheck] = useState(99);

    async function getAttendance() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.getStudent(idSubjectSchedule);
            if (res['result'].length > 0) {
                setAttendances(res.result);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    useEffect(() => {
        getAttendance();
    }, []);


    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function setStatus(index: number, status: number) {
        setAttendances(attendances.map((att, i) => {
            return i == index ? { ...att, status: status } : { ...att };
        }));

    }

    async function setNote(index: number, note: string) {
        setAttendances(attendances.map((att, i) => {
            return i == index ? { ...att, notes: note } : { ...att };
        }));

    }

    function getCountStatus(status: number) {
        return attendances.filter(value => value.status == status).length;
    }

    function pageBack() {
        history.goBack();

    }

    async function save() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.attendance.saveAttendances(attendances);
            if (res) {
                dispatch(
                    setAlert({
                        title: `Điểm danh buổi thành công!`
                    })
                );
                pageBack();
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function setALL(status: number) {
        if (allCheck !== status) {
            setAttendances(attendances.map((att, i) => {
                return { ...att, status: status };
            }));
            setAllCheck(status);
        }
    }

    return (
        <DefaultLayout>
            <div className='attendance page'>
                <div className='page__header'>
                    <div className={'flex flex-row align-items-center'}>
                        <button className={'btn btn-sm btn-light'} onClick={() => pageBack()}>
                            <i className={'fas fa-arrow-left mr-0.5'}></i>
                            Quay lại
                        </button>
                        <div className='___title flex items-center'>
                            <div className='___title__decorate mx-2' />
                            <div>Điểm danh môn học</div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div
                            className='flex flex-row mx-sm-3 bg-white px-sm-3 py-sm-2 radius-10 w-100'
                            style={{ overflow: 'auto' }}>
                            <table className='table table-light'>
                                <thead>
                                <tr className={'cursor-pointer'}>
                                    <th style={{ width: '5%' }}>STT</th>
                                    <th style={{ width: '10%' }}>MSSV</th>
                                    <th style={{ width: '20%' }}>Sinh viên</th>
                                    <th style={{ width: '20%' }}>Ghi chú</th>
                                    <th style={{ width: '10%' }}>
                                        <div onClick={() => setALL(1)}> Có mặt <i
                                            className='fas fa-check-double'></i></div>
                                    </th>
                                    <th style={{ width: '10%' }}>
                                        <div onClick={() => setALL(2)}>
                                            Đi trễ <i className='fas fa-check-double'></i>
                                        </div>
                                    </th>
                                    <th style={{ width: '12%' }}>
                                        <div onClick={() => setALL(3)}>Vắng có phép <i className='fas fa-check-double'></i></div>
                                    </th>
                                    <th style={{ width: '13%' }}>
                                        <div onClick={() => setALL(4)}>
                                            Vắng không phép <i className='fas fa-check-double'></i>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    attendances.length > 0 ? attendances.map((attendance, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '5%' }} data-label='STT'>
                                                    <b>{i + 1}</b>
                                                </td>
                                                <td style={{ width: '10%' }} data-label='MSSV'>
                                                    <b className='span-center'>
                                                        <span
                                                            className={'text-danger'}>{attendance.userInternalId}</span>
                                                    </b>
                                                </td>
                                                <td style={{ width: '20%' }} data-label='Sinh viên'>
                                                    <b>
                                                        <span className={'text-danger'}>{attendance.name}</span>
                                                    </b>
                                                </td>
                                                <td style={{ width: '20%' }} data-label='Ghi chú'>
                                                    <input defaultValue={attendance.notes || ''}
                                                           onChange={event => setNote(i, event.target.value)} />
                                                </td>
                                                <td align='center' style={{ width: '10%' }} data-label='Có mặt'
                                                    onClick={() => setStatus(i, 1)}>
                                                    <img
                                                        src={attendance.status == 1 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '10%' }} data-label='Đi trễ'
                                                    onClick={() => setStatus(i, 2)}>
                                                    <img
                                                        src={attendance.status == 2 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '12%' }} data-label='Vắng có phép'
                                                    onClick={() => setStatus(i, 3)}>
                                                    <img
                                                        src={attendance.status == 3 ? check : un_check}
                                                        alt='' />
                                                </td>
                                                <td align='center' style={{ width: '13%' }} data-label='Vắng không phép'
                                                    onClick={() => setStatus(i, 4)}>
                                                    <img
                                                        src={attendance.status == 4 ? check : un_check}
                                                        alt='' />
                                                </td>
                                            </tr>
                                        );
                                    }) : <tr>
                                        <td colSpan={8} align={'center'}><span className={'text-danger'}>Không có dữ liệu sinh viên để tiến hành điểm danh!</span>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='flex-row flex items-center justify-between w-100'>
                        <div className={''}>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV có mặt:</span>
                            <span className={'text-danger'}><b>{getCountStatus(1)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV đi trễ:</span>
                            <span className={'text-danger'}><b>{getCountStatus(2)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV vắng có phép:</span>
                            <span className={'text-danger'}><b>{getCountStatus(3)}</b></span>
                        </div>
                        <div>
                            <i className={'fas fa-user'}></i>
                            <span className={'mx-0.5'}>Tổng số SV vắng không phép:</span>
                            <span className={'text-danger'}><b>{getCountStatus(4)}</b></span>
                        </div>
                    </div>
                    <div className='my-2 w-100 flex flex-row justify-end'>
                        <button className={'btn btn-success'} onClick={() => save()}>
                            <i className={'fas fa-save mr-0.5'}></i>
                            Lưu lại
                        </button>
                    </div>
                </div>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />;
        </DefaultLayout>
    );
}

export default Attendance;