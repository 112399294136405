import React, { useEffect, useState } from 'react';
import './default-layout.scss';
import NavBar from 'components/Layouts/NavBar/NavBar';
import SideMenu from 'components/Layouts/SideMenu/SideMenu';
import { logout, setAlert, setLoading, setToken } from 'redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { _AUTH } from 'constants/_auth';
import Alert from 'components/Elements/Alert/Alert';
import { useHistory, useLocation  } from 'react-router-dom';
import Loading from '../../components/Elements/Loading/Loading';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function DefaultLayout(props) {
	const dispatch = useDispatch();
	let history = useHistory();
	let location = useLocation();
	// @ts-ignore
	const alert = useSelector((state) => state.auth.alert);
	// @ts-ignore
	const loading = useSelector((state) => state.auth.loading);
	if (alert?.title.length > 0) {
		setTimeout(() => {
			dispatch(
				setAlert({
					type: 'success',
					title: '',
				})
			);
		}, 2000);
	}

	const token: any = localStorage.getItem(_AUTH.TOKEN_NAME);

	useEffect(() => {
		if (!token) {
			window.location.href = '/auth';
		}
		dispatch(setToken());
	}, []);

	// useEffect(() => {
	// 	dispatch(setLoading(true));
	// 	setTimeout(() => {
	// 		dispatch(setLoading(false));
	// 	}, 1000);
	// }, []);

	const [menuHide, setMenuHide] = useState(false);

	function hideMenu() {
		setMenuHide(!menuHide);
	}

	function logOut() {
		localStorage.clear();
		// @ts-ignore
		dispatch(logout({}));
	}

	function changePassword() {
		history.push('/doi-mat-khau');
	}

	return (
		<div>
			<Alert
				show={alert?.title.length > 0}
				label={alert?.title}
				type={alert?.type}
			/>
			{loading ? <Loading /> : ''}
			<div className='default-layout layout'>
				<div className='layout__nav-bar fixed w-screen'>
					<NavBar
						hideClicked={hideMenu}
						logOut={logOut}
						changePassword={changePassword}
					/>
				</div>
				<div className='layout__side-menu fixed h-screen'>
					<SideMenu hide={menuHide} />
				</div>
				<div
					className={classNames(
						'layout__content',
						menuHide ? '' : 'full-menu'
					)}>
					{props.children}
				</div>
			</div>
		</div>
	);
}

export default DefaultLayout;
