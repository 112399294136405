import axiosClient from 'config/axiosClient';

const _url = '/Api/Subjects';
const subjectService = {
    getPaging: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    subjectGetLists: (option) => {
        return axiosClient.post(_url + '/GetList', option);
    },
    getDetail: (id) => {
		return axiosClient.post(_url + '/Get', { id: id });
	},
    createSubject: (data) => {
        return axiosClient.post(_url + '/Create', data);
    },
    updateSubject: (data) => {
        return axiosClient.post(_url + '/Update', data);
    },
    deleteSubject: (id) => {
        return axiosClient.post(_url + '/Delete', { id: id });
    },

    subjectClass_Get(option){
        return axiosClient.post(_url + '/GetByClass', option);
    }
};

export default subjectService;
