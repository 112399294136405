import axiosClient from 'config/axiosClient';

const _url = '/Api/SubjectDocuments';
const subjectDocumentService = {
    saveDocument: (option) => {
        return axiosClient.post(_url + '/Save', option);
    },
    deleteDocument: (id) => {
        return axiosClient.post(_url + '/Delete', { id: id });
    },
    getPagingDocument: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    getDocument: (id) => {
        return axiosClient.post(_url + '/Get', { id: id });
    },
    downloadDocument: (path) => {
        return axiosClient.post(_url + '/Download', path);
    },
    previewDocument: (path) => {
        return axiosClient.post(_url + '/Preview', path);
    }
};

export default subjectDocumentService;
