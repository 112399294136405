import './SemesterPlanningDetail.scss';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import { $api } from 'services/service';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { setAlert, setLoading } from '../../../redux/authSlice';
import { useParams } from 'react-router';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import vi from 'date-fns/locale/vi';
import moment from 'moment/moment';
import Moment from 'react-moment';
import TableSubjectPlanning from '../../../components/Elements/Table/Table-Subject-Planning';

const fileDownload = require('js-file-download');

registerLocale('vi', vi); // register it with the name you want
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SemesterPlanningDetail() {
    const dispatch = useDispatch();
    // @ts-ignore
    const { fId, cId } = useParams();
    const [classes, setClasses] = useState([]);
    const [semesterPlanningId, setSemesterPlanningId] = useState('');
    const [semesterPlannings, setSemesterPlannings] = useState([]);
    const [classId, setClassId] = useState('');
    const [changes, setChanges] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState({ id: null, type: null });
    const [modalCreateSemesterPlanning, setModalCreateSemesterPlanning] = useState(false);
    const [modalSubjectList, setModalSubjectList] = useState(false);
    const [semesterPlanning, setSemesterPlanning] = useState({
            id: '',
            classId: classId,
            semester: 1,
            startDate: new Date(),
            endDate: new Date()
        }
    );
    const [subjectSelectList, setSubjectSelectList] = useState<any>([]);
    const [subjectRequest, setSubjectRequest] = useState({ id: cId, searchText: '' });
    const [subjectList, setSubjectList] = useState([]);
    const currentDate = new Date();

    useEffect(() => {
        async function getClasses(option = {}) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.class.getPaging(option);
                if (res && res.result) {
                    setClasses(res.result.items);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        if (cId && cId.length > 0) {
            setClassId(cId);
        }
        getClasses({
            facultyId: fId ? fId : null
        });
    }, []);
    useEffect(() => {
        async function getByClass() {
            try {
                if (classId) {
                    dispatch(setLoading(true));
                    const res: any = await $api.semesterPlanning.getByClass(classId);
                    if (res && res.result) {
                        const results = res.result;
                        const updatedItems = results.map((item, index) => {
                            const startDate = new Date(item.startDate);
                            const endDate = new Date(item.endDate);
                            return {
                                ...item,
                                statusCollapse: currentDate >= startDate && currentDate <= endDate
                            };
                        });
                        setSemesterPlannings(updatedItems);
                    }
                    dispatch(setLoading(false));
                }
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getByClass();
    }, [changes, classId]);
    useEffect(() => {
        async function getSubjectPlanning() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.subjectPlanning.getListSubject(subjectRequest);
                if (res && res.result) {
                    setSubjectList(res.result);
                    setSubjectRequest({ ...subjectRequest, searchText: '' });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getSubjectPlanning();
    }, [changes]);

    async function deleteAction(e, id, typeDelete) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ' + (typeDelete == 'semester' ? 'học kì ?' : 'môn học ?')
        });
        setIdDelete({ id: id, type: typeDelete });
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            let res: any;
            if (idDelete.type == 'semester') {
                res = await $api.semesterPlanning.delete(idDelete.id);
            } else {
                res = await $api.subjectPlanning.delete(idDelete.id);
            }
            if (res) {
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }

    function findByID() {
        const objectFind = classes.find((item: { id: string }) => {
            return item.id === classId;
        });
        const object = {
            name: 'Quản lý lớp học',
            facultyName: 'Quản lý khoa',
            facultyId: ''
        };
        return objectFind ? objectFind : object;
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function onSaveSemesterPlanning() {
        if (semesterPlanning.endDate < semesterPlanning.startDate) {
            show_ErrMsg('Thời gian kết thúc không hợp lệ!');
            return;
        }
        try {
            dispatch(setLoading(true));
            if (semesterPlanning.id) {
                const res: any = await $api.semesterPlanning.update(semesterPlanning);
                if (res) {
                    setChanges(!changes);
                    dispatch(
                        setAlert({
                            type: 'success',
                            title: `Cập nhật học kì thành công`
                        })
                    );
                    setModalCreateSemesterPlanning(false);
                    dispatch(setLoading(true));
                }
            } else {
                const res: any = await $api.semesterPlanning.create({
                    classId: classId,
                    startDate: semesterPlanning.startDate,
                    endDate: semesterPlanning.endDate,
                    semester: semesterPlanning.semester
                });
                if (res) {
                    setChanges(!changes);
                    dispatch(
                        setAlert({
                            type: 'success',
                            title: `Tạo học học kì thành công`
                        })
                    );
                    setModalCreateSemesterPlanning(false);
                    dispatch(setLoading(true));
                }
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function subjectSelected(id) {
        setSubjectSelectList(prevIds => {
            const index = prevIds.indexOf(id);
            if (index === -1) {
                // If id not equal array add
                return [...prevIds, id];
            } else {
                // If id equal array delete
                return prevIds.filter(existingId => existingId !== id);
            }
        });
    }

    async function onSaveSubjectPlanning() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subjectPlanning.save({
                subjectIds: subjectSelectList,
                semesterPlanningId: semesterPlanningId
            });
            if (res) {
                setModalSubjectList(false);
                setSubjectSelectList([]);
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }

    function updateSemesterPlanningCollapse(index: number) {
        // @ts-ignore
        setSemesterPlannings((prevPlannings) => {
            return prevPlannings.map((planning, i) => {
                if (i === index) {
                    return {
                        // @ts-ignore
                        ...planning,
                        statusCollapse: !planning.statusCollapse
                    };
                }
                return planning;
            });
        });
    }

    async function exportSemester() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.semesterPlanning.export(classId);
            if (res) {
                fileDownload(res, `KE_HOACH_HOC_TAP_KHOA_${findByID().facultyName}_LOP_${findByID().name}.xlsx`);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function totalCredit() {
        return semesterPlannings
            // @ts-ignore
            .flatMap(classItem => classItem.subjectPlannings)
            .reduce((total, subject) => total + subject.creditPoint, 0);
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center justify-between'>
                        <div className={'flex items-center'}>
                            <div className='___title__decorate' />
                            <div className='___title__box'>
                                <a href={`/ke-hoach-toan-khoa?f=${fId}`}>
                                    Kế hoạch toàn khóa: {findByID().facultyName}
                                </a>
                                <div className='___title__box__last'>{`>`}</div>
                                <div>{findByID().name}</div>
                            </div>
                        </div>
                        <div className='___title__box'>

                            <div className={'font-bold'}><i className='fa fa-calculator mr-0.5'
                                                            aria-hidden='true'></i>
                                Tổng số tín chỉ toàn khóa:
                                <span className={'text-red-600 ml-0.5'}>
                                    {
                                        semesterPlannings.length === 0 ? 0 : totalCredit()
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='actions--item d-flex'>
                            <div onClick={() => setModalCreateSemesterPlanning(true)}>
                                <Button
                                    label='Thêm học kì'
                                    icon={<i className='fas fa-plus-circle' />}
                                />
                            </div>
                        </div>
                        <div className='actions--item d-flex'>
                            <div onClick={(e) => {
                                exportSemester();
                            }}>
                                <Button
                                    label='Xuất học kì'
                                    icon={<i className='fas fa-file' />}
                                    color={'bg-green-800'}
                                    hoverColor={'bg-green-900'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-1'>
                    <div className='w-auto-scroll'>
                        {
                            semesterPlannings.length === 0 ?
                                <div className='flex flex-wrap -ml-4 -mb-4'
                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <img src='/notFound_01.png'
                                         alt='Không tìm thấy dữ liệu học kì'
                                         title='Không tìm thấy dữ liệu học kì'
                                    />
                                </div> :
                                semesterPlannings.map((semesterPlanning, index) => (
                                    <div className='page__content bg-white mt-3 subject-detail flex flex-row flex-wrap'
                                         key={'semester-planning-detail' + semesterPlanning['id']}>
                                        <div
                                            className='action--item flex align-items-center justify-between flex-row w-full py-2'>
                                            <div className={'flex flex-row'}>
                                                <div className='text-sm text-left text-gray-900 font-bold'>
                                                    Học kì: {semesterPlanning['semester']}
                                                </div>
                                                <span className={'mx-2'}>/</span>
                                                <div className='text-sm text-left text-gray-900 font-bold'>
                                                    Ngày bắt đầu: {semesterPlanning['startDate'] &&
                                                moment(semesterPlanning['startDate']).isValid() ? (
                                                    // @ts-ignore
                                                    <Moment format='DD/MM/YYYY'>
                                                        {semesterPlanning['startDate']}
                                                    </Moment>
                                                ) : (
                                                    <div>{semesterPlanning['startDate']}</div>
                                                )}
                                                </div>
                                                <span className={'mx-2'}>/</span>
                                                <div className='text-sm text-left text-gray-900 font-bold'>
                                                    Ngày kết thúc: {semesterPlanning['endDate'] &&
                                                moment(semesterPlanning['endDate']).isValid() ? (
                                                    // @ts-ignore
                                                    <Moment format='DD/MM/YYYY'>
                                                        {semesterPlanning['endDate']}
                                                    </Moment>
                                                ) : (
                                                    <div>{semesterPlanning['endDate']}</div>
                                                )}
                                                </div>
                                                <span className={'mx-2'}>/</span>
                                                <div className='text-sm text-left text-gray-900 font-bold'>
                                                    Trạng
                                                    thái: {semesterPlanning['isActive'] ? 'Đã bắt đầu' : 'Đã kết thúc'}
                                                </div>
                                            </div>
                                            <div>
                                                <button className={'btn btn-md outline-none bg-white'}
                                                        onClick={() => updateSemesterPlanningCollapse(index)}><i
                                                    className={semesterPlanning['statusCollapse'] ? 'fas fa-angle-down' : 'fas fa-angle-up'}></i>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            semesterPlanning['statusCollapse'] ? <div className={'w-full'}>
                                                <div className={'flex flex-row w-full justify-between'}>
                                                    <div onClick={() => {
                                                        setModalSubjectList(true);
                                                        setSemesterPlanningId(semesterPlanning['id']);
                                                    }}>
                                                        <Button
                                                            label='Thêm môn học'
                                                            icon={<i className='fas fa-plus-circle' />}
                                                        />
                                                    </div>
                                                    <div className={'flex flex-row'}>
                                                        <div
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                setModalCreateSemesterPlanning(true);
                                                                setSemesterPlanning(semesterPlanning);
                                                            }}>
                                                            <Button
                                                                color={'bg-green-800'}
                                                                hoverColor={'bg-green-900'}
                                                                label='Chỉnh sửa học kì'
                                                                icon={<i className='far fa-edit' />}
                                                            />
                                                        </div>
                                                        <span className={'mx-1'}></span>
                                                        <div
                                                            className='text-red-700 hover:text-red-800 font-semibold cursor-pointer'
                                                            onClick={event => deleteAction(event, semesterPlanning['id'], 'semester')}>
                                                            <Button
                                                                color={'bg-red-800'}
                                                                hoverColor={'bg-red-900'}
                                                                label='Xóa học kì'
                                                                icon={<i className='fas fa-trash' />}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    // @ts-ignore
                                                    semesterPlanning['subjectPlannings'].length === 0 ?
                                                        <div
                                                            className='flex flex-wrap justify-center w-full -ml-4 -mb-4'
                                                            style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                            <h4 className={'my-2 font-bold'}> Học kì chưa có dữ liệu môn
                                                                học</h4>
                                                        </div> : <div className='table w-auto-scroll mt-8 w-full'>
                                                            <TableSubjectPlanning
                                                                data={semesterPlanning['subjectPlannings']}
                                                                deleteClicked={(e, id) => deleteAction(e, id, 'subjectPlanning')}
                                                            />
                                                        </div>
                                                }
                                            </div> : ''
                                        }
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={modalSubjectList}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => {
                    setModalSubjectList(false);
                    setSubjectSelectList([]);
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Danh sách môn học
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-column flex-nowrap'>
                        <div className='mt-2 w-100 overflow-x-auto'>
                            <div className='relative mb-3'>
                                <div
                                    className='absolute inset-y-0 start-0 flex items-center px-2 pointer-events-none'>
                                    <svg className='w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden='true'
                                         xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'>
                                        <path stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'
                                              stroke-width='2' d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z' />
                                    </svg>
                                </div>
                                <input type='search' id='default-search'
                                       className='block w-full p-2.5 pl-4 pr-28 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                       placeholder='Tìm kiếm môn học' onChange={event => setSubjectRequest({
                                    ...subjectRequest,
                                    searchText: event.target.value
                                })} onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        setChanges(!changes);
                                    }
                                }} />
                                <button onClick={event => setChanges(!changes)}
                                        className='text-white absolute right-1 bottom-0.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Tìm
                                    kiếm
                                </button>
                            </div>
                            {
                                subjectList.length > 0 ? <table className='divide-y divide-gray-200'>
                                        <thead className='bg-gray-50'>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='px-3 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider'>
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Khoa
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Tên môn
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Số tín chỉ
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
                                        {subjectList.map((item) => (
                                            <tr
                                                className='hover:bg-gray-100'
                                                key={item['id']}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    subjectSelected(item['id']);
                                                }}>
                                                <td className='px-2 py-2'>
                                                    <input id='checkbox-item-1' type='checkbox'
                                                           checked={subjectSelectList.includes(item['id'])}
                                                           className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500' />
                                                </td>
                                                <td className='px-6 py-4 whitespace-nowrap '>
                                                    <div className='flex text-left items-start text-sm text-gray-900'>
                                                        {item['facultyName'] || ''}
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 '>
                                                    <div className='flex items-start text-sm text-gray-900'>
                                                        {item['name'] || ''}
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 '>
                                                    <div className='flex text-center items-start text-sm text-gray-900'>
                                                        {item['creditPoint'] || ''}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    : <div
                                        className={'mx-2 font-medium font-bold w-full flex flex-row justify-center'}> Chưa
                                        có thông tin môn học!</div>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'flex flex-row justify-between w-full'}>
                        <div
                            onClick={() => {
                                setModalSubjectList(false);
                                setSubjectSelectList([]);
                            }}>
                            <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                    icon={<i className='fas fa-times-circle'></i>} label='Hủy' />
                        </div>
                        <div
                            onClick={() => {
                                onSaveSubjectPlanning();
                            }}>
                            <Button icon={<i className='fas fa-save'></i>}
                                    label={'Lưu lại'} />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modalCreateSemesterPlanning}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => {
                    setModalCreateSemesterPlanning(false);
                    setSemesterPlanning({
                        semester: 1,
                        endDate: new Date(),
                        startDate: new Date(),
                        classId: classId,
                        id: ''
                    });
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {semesterPlanning.id ? 'Cập nhật học kì' : 'Tạo mới học kì'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-column flex-nowrap'>
                        <div className='mt-2 w-100'>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Tên học kì
                            </div>
                            <select
                                className='w-full block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                value={semesterPlanning.semester}
                                onChange={(e) => {
                                    setSemesterPlanning({
                                        ...semesterPlanning,
                                        semester: Number.parseInt(e.target.value)
                                    });
                                }}>
                                {Array(7).fill(0).map((item: any, index) => {
                                    return (
                                        <option
                                            value={index + 1}
                                            key={index}>
                                            Học kì: {index + 1}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className={'mt-2 w-100'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ngày bắt đầu
                            </div>
                            <DatePicker
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                selected={new Date(semesterPlanning.startDate)}
                                onChange={(date) => {
                                    const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
                                    newDate.setUTCDate(1);
                                    setSemesterPlanning({
                                        ...semesterPlanning,
                                        startDate: new Date(date.setHours(date.getHours() + 7))
                                    });
                                }}
                                locale={'vi'}
                                showMonthDropdown
                                showYearDropdown
                                dateFormat='MM/yyyy'
                                showMonthYearPicker
                            />
                        </div>
                        <div className={'mt-2 w-100'}>
                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                Ngày kết thúc
                            </div>
                            <DatePicker
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                selected={new Date(semesterPlanning.endDate)}
                                onChange={(date) => {
                                    const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
                                    newDate.setUTCDate(1);
                                    setSemesterPlanning({
                                        ...semesterPlanning,
                                        endDate: new Date(date.setHours(date.getHours() + 7))
                                    });
                                }}
                                locale={'vi'}
                                showMonthDropdown
                                showYearDropdown
                                dateFormat='MM/yyyy'
                                showMonthYearPicker
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'flex flex-row justify-between w-full'}>
                        <div
                            onClick={() => {
                                setModalCreateSemesterPlanning(false);
                                setSemesterPlanning({
                                    semester: 1,
                                    endDate: new Date(),
                                    startDate: new Date(),
                                    classId: classId,
                                    id: ''
                                });
                            }}>
                            <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                    icon={<i className='fas fa-times-circle'></i>} label='Hủy' />
                        </div>
                        <div
                            onClick={() => {
                                onSaveSemesterPlanning();
                            }}>
                            <Button icon={<i className='fas fa-save'></i>}
                                    label={'Lưu lại'} />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default SemesterPlanningDetail;
