import axiosClient from 'config/axiosClient';

const _url = '/Api/SubjectPlanning';
const subjectPlanningService = {
    getBySemester: (id) => {
        return axiosClient.post(_url + '/GetBySemester', { id: id });
    },
    getListSubject: (options) => {
        return axiosClient.post(_url + '/GetListSubject', options);
    },
    delete: (id) => {
        return axiosClient.post(_url + '/Delete', { id: id });
    },
    save: (options) => {
        return axiosClient.post(_url + '/Save', options);
    }
};

export default subjectPlanningService;
