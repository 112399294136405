/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { _ENUMS } from 'constants/_enums';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function List(props) {
	const [selected, setSelected] = useState(props.data[0]);

	useEffect(() => {
		props.selected(selected);
	}, [selected]);

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					<div className='mt-1 relative'>
						<Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							{props.type !== 'room' ? (
								<span className='flex items-center'>
									<img
										src={
											selected.profilePicUrl ||
											_ENUMS.IMAGE
										}
										alt=''
										className='flex-shrink-0 h-6 w-6 rounded-full'
									/>
									<span className='ml-3 block truncate font-medium'>
										{selected.nameAndFacultyName}
									</span>
								</span>
							) : (
								<span className='flex items-center'>
									<span className='ml-3 block truncate font-medium'>
										{selected.name} (
										{selected.numberOfSeats} chổ ngồi)
									</span>
								</span>
							)}
							<span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
								<SelectorIcon
									className='h-5 w-5 text-gray-400'
									aria-hidden='true'
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'>
							<Listbox.Options className='absolute z-10 mt-1 w-full bg-white max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
								{props.data.map((person) => (
									<Listbox.Option
										key={person.id}
										className={({ active }) =>
											classNames(
												active
													? 'text-white bg-indigo-600'
													: 'text-gray-900',
												'cursor-default select-none relative py-2 pl-3 pr-9'
											)
										}
										value={person}>
										{({ selected, active }) => (
											<>
												{props.type !== 'room' ? (
													<span className='flex items-center'>
														<img
															src={
																person.profilePicUrl ||
																_ENUMS.IMAGE
															}
															alt=''
															className='flex-shrink-0 h-6 w-6 rounded-full'
														/>
														<span className='ml-3 block truncate font-medium'>
															{
																person.nameAndFacultyName
															}
														</span>
													</span>
												) : (
													<span className='flex items-center'>
														<span className='ml-3 block truncate font-medium'>
															{person.name} (
															{
																person.numberOfSeats
															}{' '}
															chổ ngồi)
														</span>
													</span>
												)}

												{selected ? (
													<span
														className={classNames(
															active
																? 'text-white'
																: 'text-indigo-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}>
														<CheckIcon
															className='h-5 w-5'
															aria-hidden='true'
														/>
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
}
