// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import React from 'react';

function TableSubjectPlanning(props) {

    function totalCredit() {
        return props.data.reduce((sum, subject) => sum + subject.creditPoint, 0);
    }

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên môn
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số tín chỉ
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày bắt đầu
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày kết thúc
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Trạng thái
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Hành động
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.subjectName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='flex items-start text-sm text-gray-900'>
                            {item.creditPoint || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/yyyy'>
                                    {item.startDate}
                                </Moment>
                            ) : (
                                <div>{item.startDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.endDate &&
                            moment(item.endDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/yyyy'>
                                    {item.endDate}
                                </Moment>
                            ) : (
                                <div>{item.endDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div
                            className={'flex items-start text-sm font-bold ' + (item.status == 0 ? 'text-gray-700' : item.status == 1 ? 'text-blue-700' : 'text-red-700')}>
                            {item.status == 0 ? 'Chưa bắt đầu' : item.status == 1 ? 'Đã bắt đầu' : 'Đã kết thúc'}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            <div
                                className='text-red-700 hover:text-red-800 font-semibold cursor-pointer'
                                onClick={event => props.deleteClicked(event, item.id)}>
                                <i className='fas fa-trash mr-2' />
                                Xóa môn học
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
                <td className={'font-bold text-left'} colSpan={props.data.length}>Tổng số tín chỉ học kì: <span className={'text-red-600'}>{totalCredit()}</span></td>
            </tr>
            </tfoot>
        </table>
    );
}

export default TableSubjectPlanning;
