import './alert.scss';
import Parser from 'html-react-parser';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function Alert(props) {
	const label =
		typeof props?.label == 'string'
			? props?.label
			: 'Đã có lỗi xảy ra vui lòng thử lại';
	return (
		<div
			className={classNames(
				'alert-custom',
				props.show ? 'show-custom showAlert-custom' : 'hide-custom',
				props.type === 'danger'
					? 'border-red-700 bg-red-100'
					: 'border-green-700 bg-green-100'
			)}>
			<div
				className={classNames(
					'fas fa-check-circle',
					props.type === 'danger' ? 'text-red-700' : 'text-green-700'
				)}
			/>
			<div
				className={classNames(
					'msg',
					props.type === 'danger' ? 'text-red-700' : 'text-green-700'
				)}>
				{Parser(label || 'Nội dung hiển thị')}
			</div>
		</div>
	);
}

export default Alert;