import { _ENUMS } from 'constants/_enums';
import './dialog-student.scss';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DialogSelectList from './DialogSelectList';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import Button from '../Button/Button';

export default function DialogStudent({reloadData}) {
    let { idSubjectTeaching } = useParams();
    const dispatch = useDispatch();
    const [change, setChange] = useState(false);
    const [studentsSelected, setStudentsSelected] = useState([]);
    const [mode, setMode] = useState(0);
    const [studentInSubject, setStudentInSubject] = useState([]);
    const [students, setStudents] = useState([]);
    const [classes, setClasses] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);

    useEffect(() => {
        async function fetchStudentInSubject(id) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.schedule.subjectStudent_getPaging({
                    subjectTechingsId: id
                });
                setStudentInSubject(res.result);
                const a = res.result.map((item) => {
                    return item.studentId;
                });
                setStudentsSelected(a);

                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                // dispatch(
                // 	setAlert({
                // 		type: 'danger',
                // 		title:
                // 			e?.response?.data?.errors?.exceptionMessage ||
                // 			'Lỗi',
                // 	})
                // );
            }
        }

        async function fetchAllStudent() {
            try {
                // const res: any = await $api.student.getPaging({
                // 	isGraduated: false,
                // });
                dispatch(setLoading(true));
                const res: any =
                    await $api.schedule.subjectStudent_getStudentForSelect(
                        idSubjectTeaching
                    );
                setStudents(res.result);

                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                // dispatch(
                // 	setAlert({
                // 		type: 'danger',
                // 		title:
                // 			e?.response?.data?.errors?.exceptionMessage ||
                // 			'Lỗi',
                // 	})
                // );
            }
        }

        async function fetchAllClass() {
            try {
                const res: any = await $api.class.getAll();
                setClasses(res.result);
            } catch (e) {
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                // dispatch(
                // 	setAlert({
                // 		type: 'danger',
                // 		title:
                // 			e?.response?.data?.errors?.exceptionMessage ||
                // 			'Lỗi',
                // 	})
                // );
            }
        }

        if (idSubjectTeaching && idSubjectTeaching.length > 0) {
            fetchStudentInSubject(idSubjectTeaching);
            fetchAllStudent();
            fetchAllClass();
            setTimeout(() => reloadData(change), 700)
        }
    }, [change, idSubjectTeaching]);

    // useEffect(() => {
    // 	async function fetchAllStudent() {
    // 		try {
    // 			const res: any = await $api.student.getPaging({
    // 				isGraduated: false,
    // 			});
    // 			console.log('fetchAllStudent --------------------------------------------------');
    // 			console.log(res);
    // 			console.log('fetchAllStudent --------------------------------------------------');
    // 			setStudents(res.result.items);
    // 		} catch (e) {
    // 			dispatch(
    // 				setAlert({
    // 					type: 'danger',
    // 					title:
    // 						e?.response?.data?.errors?.exceptionMessage ||
    // 						'Lỗi',
    // 				})
    // 			);
    // 		}
    // 	}

    // 	async function fetchAllClass() {
    // 		try {
    // 			const res: any = await $api.class.getAll();

    // 			console.log('fetchAllClass --------------------------------------------------');
    // 			console.log(res);
    // 			console.log('fetchAllClass --------------------------------------------------');
    // 			setClasses(res.result);
    // 		} catch (e) {
    // 			dispatch(
    // 				setAlert({
    // 					type: 'danger',
    // 					title:
    // 						e?.response?.data?.errors?.exceptionMessage ||
    // 						'Lỗi',
    // 				})
    // 			);
    // 		}
    // 	}

    // 	fetchAllStudent();
    // 	fetchAllClass();
    // }, []);

    function addStudentToList(e) {
        e.stopPropagation();
        setMode(1);
    }

    async function createStudentInSubject() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectStudent_update({
                subjectTeachingId: idSubjectTeaching,
                studentIds: studentsSelected
            });
            // const res: any = await $api.schedule.subjectStudent_create({
            // 	subjectTeachingId: idSubjectTeaching,
            // 	studentIds: studentsSelected,
            // });
            dispatch(
                setAlert({
                    title: res?.message
                })
            );
            dispatch(setLoading(false));
            setChange(!change);
            setTimeout(() => {
                setMode(0);
            }, 500);
        } catch (e) {
        }
    }

    async function deleteStudentInSubject(e, idDelete) {
        e.stopPropagation();
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true,
            title: 'Thông báo',
            subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(idDelete);
    }

    async function addSelectedStudent(e, id: string) {
        e.stopPropagation();
		// @ts-ignore
        const index = studentsSelected.indexOf(id);
        // @ts-ignore
        studentsSelected.push(id);
        //  if (index !== -1) {
        // 	studentsSelected.splice(index, 1);
        //  }
        //  else {
        //  	// @ts-ignore
        // // 	studentsSelected.push(id);
        // // }
    }

    async function addSelectedStudent2(e, id: string) {
        e.stopPropagation();
        // @ts-ignore
        const index = studentsSelected.indexOf(id);
        if (index === -1) {
            // @ts-ignore
            studentsSelected.push(id);
        }
    }

    function back() {
        setMode(0);
        const a: any = studentInSubject.map((item: { studentId: string }) => {
            return item.studentId;
        });
        setStudentsSelected(a);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            const array = studentInSubject
                .map((item: { studentId: string }) => {
                    return item.studentId;
                })
                .filter((item) => {
                    return item !== idDelete;
                });
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectStudent_update({
                subjectTeachingId: idSubjectTeaching,
                studentIds: array
            });
            dispatch(
                setAlert({
                    title: res?.message
                })
            );
            dispatch(setLoading(false));
            setChange(!change);
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true,
            subTitle: msg
        });
    }

    const [keyOfFilter, setKeyOfFilter] = useState({
        classFilter: '',
        studentFilter: '',
        actions: ''
    });
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [filteredClass, setFilteredClass] = useState([]);
    const [studentConfirmed, setStudentConfirmed] = useState([{}]);
    const [studentsForShow, setStudentsForShow] = useState([{}]);

    useEffect(() => {
        if (keyOfFilter.actions === 'student') {
            if (keyOfFilter.studentFilter.length) {
                setFilteredStudents(() => {
                    return students.filter(
                        (student) =>
                            // @ts-ignore
                            student.fullName
                                .toLowerCase()
                                .search(
                                    keyOfFilter.studentFilter.toLowerCase()
                                ) >= 0
                    );
                });
            } else {
                setFilteredStudents(students);
            }
        } else {
            if (keyOfFilter.classFilter.length) {
                setFilteredClass(() => {
                    return classes.filter(
                        (el) =>
                            // @ts-ignore
                            el.name
                                .toLowerCase()
                                .search(
                                    keyOfFilter.classFilter.toLowerCase()
                                ) >= 0
                    );
                });
            } else {
                setFilteredClass(classes);
            }
        }
    }, [keyOfFilter]);

    function confirmStudentsToAdd(e, student) {
        let arrStudent = [...studentConfirmed];
        arrStudent.push(student);
        addSelectedStudent(e, student?.['id']);

        setStudentConfirmed(arrStudent);

        setKeyOfFilter({ ...keyOfFilter, studentFilter: '' });
        // setStudentsForShow(arrStudent);
    }

    useEffect(() => {
        studentConfirmed.shift();
        studentsForShow.shift();
    }, []);

    function DeleteConfirmedStudents(e, id) {
        let arr = [...studentConfirmed];
        let indexOfId = -1;
        studentConfirmed.forEach((item, index) => {
            if (item?.['id'] === id) {
                indexOfId = index;
            }
        });

        arr.splice(indexOfId, 1);
        setStudentConfirmed(arr);
    }

    function addFacultyToConfirmationTable(e, classId) {
        let arr = [...studentsForShow];

        let arrConfirmed = [...studentConfirmed];

        students.forEach((item) => {
            if (item['classId'] === classId) {
				arr.push(item);
				addSelectedStudent(e, item?.['id']);
			}
        });

        setStudentConfirmed(arrConfirmed);
        // setStudentsForShow(arr);
    }

    useEffect(() => {
        async function fetchStudentInSubject() {
            let arr = [...studentsForShow];
            try {
                dispatch(setLoading(true));
                const res: any = await $api.student.getAll();

                // const a = res.result.map((item) => {
                // 	return item;
                // });

                res.result.forEach((item) => {
                    arr.push(item);
                });
                setStudentsForShow(arr);
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        fetchStudentInSubject();
    }, []);

    function updateListStudentConfirmed(e) {
        studentConfirmed.forEach((item) => {
            addSelectedStudent(e, item?.['id']);
        });

        createStudentInSubject()
    }

    function renderSelected() {
        let arr: any = [...studentsForShow];
        let arr1: any = [...studentsSelected];
        let newArr: any = [];
        arr.forEach((item, index) => {
            if (arr1.includes(item.id)) {
                newArr.push(item);
            }
        });

        return (
            <tbody>
            {newArr.map((item, index) => {
                return (
                    <tr key={index}>
                        <th scope='row' style={{ width: '10%' }}>
                            <img
                                src={
                                    item?.['profilePicUrl'] ||
                                    _ENUMS.IMAGE_PROFILE
                                }
                                alt='Photo'
                            />
                        </th>
                        <td>{item?.['fullName']}</td>
                        <td>{item?.['className']}</td>
                        <td>
                            <input
                                className='btn btn-defauld'
                                type='button'
                                value='Xóa'
                                onClick={(e) =>
                                    // DeleteConfirmedStudents(
                                    // 	e,
                                    // 	item?.['id']
                                    // )
                                    deleteStudentInSubject(e, item?.['id'])
                                }
                            />
                        </td>
                    </tr>
                );
            })}
            </tbody>
        );
    }

    // -----------------------------------------------

    // @ts-ignore
    // @ts-ignore
    return (
        <div>
            {/* {mode === 0 ? (
				<div>
					<div className='toolbar'>
						<div
							className='toolbar--item'
							onClick={(e) => addStudentToList(e)}>
							<i className='fas fa-users-medical' />
							<span className='ml-2 font-medium text-blue-700 text-sm cursor-pointer'>
								Thêm sinh viên
							</span>
						</div>
					</div>
					{studentInSubject.length === 0 ? (
						<div
							className='faculties--list flex flex-wrap -ml-4 -mb-4'
							style={{
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<img
								src='/notFound_01.png'
								alt='Không tìm thấy dữ liệu'
								title='Không tìm thấy dữ liệu'
							/>
						</div>
					) : (
						<div className='list'>
							{studentInSubject.map((s) => {
								return (
									<div
										className='flex align-items-center list--item'
										key={
											// @ts-ignore
											s.id
										}>
										<div className='mr-4'>
											<img
												src={
													// @ts-ignore
													s?.profilePicUrl ||
													_ENUMS.IMAGE
												}
												alt=''
												className='flex-shrink-0 h-10 w-10 rounded-full'
											/>
										</div>
										<div>
											<div className='font-medium'>
												{
													// @ts-ignore
													s?.studentName
												}
											</div>
										</div>
										<div
											className='delete-user'
											onClick={(e) =>
												deleteStudentInSubject(
													e,
													// @ts-ignore
													s.studentId
												)
											}>
											<i className='fas fa-user-slash' />
										</div>
									</div>
								);
							})}
						</div>
					)}

					<div className='font-medium text-gray-500 pt-2'>
						Số lượng: {studentInSubject.length} sinh viên
					</div>
				</div>
			) : (
				<div className='creat-list'>
					<DialogSelectList
						// @ts-ignore
						students={students}
						classes={classes}
						studentsSelected={studentsSelected}
						createStudentInSubject={createStudentInSubject}
						addSelectedStudent={addSelectedStudent}
						addSelectedStudent2={addSelectedStudent2}
						backTo={back}
					/>
				</div>
			)} */}

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />

            {/* NhutTrung */}
            <div className='row'>
                <div className='col wrapper col-student'>
                    <h5>Sinh viên</h5>
                    <input
                        className='form-control mt-2'
                        type='text'
                        value={keyOfFilter.studentFilter}
                        onChange={(e) =>
                            setKeyOfFilter({
                                ...keyOfFilter,
                                studentFilter: e.target.value,
                                actions: 'student'
                            })
                        }
                        placeholder='Nhập tên sinh viên'
                    />

                    <table className='table table-striped'>
                        <thead>
                        <tr>
                            <th scope='col'>Ảnh</th>
                            <th scope='col'>Tên</th>
                            <th scope='col'>Lớp</th>
                            <th scope='col'></th>
                        </tr>
                        </thead>
                        <tbody
                            style={{
                                display: 'table-caption',
                                maxHeight: 300,
                                overflowY: 'scroll'
                            }}>
                        {filteredStudents.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <th
                                        scope='row'
                                        style={{ width: '15%' }}>
                                        <img
                                            src={
                                                item?.['profilePicUrl'] ||
                                                _ENUMS.IMAGE_PROFILE
                                            }
                                            alt='Photo'
                                        />
                                    </th>
                                    <td>{item?.['fullName']}</td>
                                    <td>{item?.['className']}</td>
                                    <td>
                                        <input
                                            className='btn btn-defauld'
                                            type='button'
                                            value='Thêm'
                                            onClick={(e) =>
                                                confirmStudentsToAdd(
                                                    e,
                                                    item
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                <div className='col wrapper col-class'>
                    <h5>Lớp</h5>
                    <input
                        className='form-control mt-2'
                        type='text'
                        value={keyOfFilter.classFilter}
                        onChange={(e) =>
                            setKeyOfFilter({
                                ...keyOfFilter,
                                classFilter: e.target.value,
                                actions: 'class'
                            })
                        }
                        placeholder='Nhập tên lớp'
                    />
                    <table className='table table-striped'>
                        <thead>
                        <tr>
                            <th scope='col'>Tên</th>
                            <th scope='col'>Mã</th>
                            <th scope='col'>Khoa</th>
                            <th scope='col'></th>
                        </tr>
                        </thead>
                        <tbody
                            style={{
                                display: 'table-caption',
                                maxHeight: 300,
                                overflowY: 'scroll'
                            }}>
                        {filteredClass.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item?.['name']}</td>
                                    <td>{item?.['classCodeName']}</td>
                                    <td>{item?.['facultyName']}</td>
                                    <td>
                                        <input
                                            className='btn btn-defauld'
                                            type='button'
                                            value='Thêm'
                                            onClick={(e) =>
                                                addFacultyToConfirmationTable(
                                                    e,
                                                    item?.['id']
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='col'>
                <div className='col wrapper col-confirm'>
                    <h5>Đã chọn</h5>
                    <table className='table table-striped'>
                        <thead>
                        <tr>
                            <th scope='col'>Ảnh</th>
                            <th scope='col'>Tên</th>
                            <th scope='col'>Lớp</th>
                            <th scope='col'></th>
                        </tr>
                        </thead>
                        {renderSelected()}
                    </table>
                </div>

                <div className='font-medium text-gray-500 pt-2'>
                    Số lượng: {studentInSubject.length} sinh viên
                </div>

                <input
                    type='button'
                    className='btn btn-primary'
                    onClick={(e) => updateListStudentConfirmed(e)}
                    value='Cập nhật danh sách'
                />
            </div>

            {/* ----------------------------------------------------------------------- */}
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </div>
    );
}
