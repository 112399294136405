import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
import { setAlert, setLoading } from '../../redux/authSlice';
import { $api } from '../../services/service';
import DefaultLayout from '../../layouts/DefaultLayout/DefaultLayout';
import Button from '../../components/Elements/Button/Button';
import ConfirmDialog from '../../components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from '../../components/Elements/ErrorDialog/ErrorDialog';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FilterIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import fileDownload from 'js-file-download';

function QuestionSuite__List() {
    let history = useHistory();

    const styleInput = 'px-2 py-1 w-full bg-white text-base font-semibold placeholder-slate-400' +
        ' focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500' +
        '      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none' +
        '      invalid:border-pink-500 invalid:text-pink-600' +
        '      focus:invalid:border-pink-500 focus:invalid:ring-pink-500' + '';
    // @ts-ignore
    let { idQuestionSuite } = useParams();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [filterType, setFilterType] = useState(99);
    const [change, setChange] = useState(false);
    const [questionSuite, setQuestionSuite] = useState({ name: '' });
    const [questionEdit, setQuestionEdit] = useState([]);
    const [questions, setQuestions] = useState([{
        id: '',
        questionSuiteId: '',
        questionText: '',
        level: 0,
        isEdit: false,
        levelText: '',
        questionAnswers: [
            {
                answerText: '',
                isAnswer: true
            },
            {
                answerText: '',
                isAnswer: false
            },
            {
                answerText: '',
                isAnswer: false
            },
            {
                answerText: '',
                isAnswer: false
            }
        ]
    }]);
    const [newQuestion, setNewQuestion] = useState({
        questionSuiteId: idQuestionSuite,
        questionText: '',
        level: 0,
        isEdit: true,
        levelText: '',
        questionAnswers: [
            {
                answerText: '',
                isAnswer: true
            },
            {
                answerText: '',
                isAnswer: false
            },
            {
                answerText: '',
                isAnswer: false
            },
            {
                answerText: '',
                isAnswer: false
            }
        ]
    });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);
    const [isNew, setIsNew] = useState(false);

    async function getQuestionSuiteDetail() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.getQuestionSuiteDetail(idQuestionSuite);
            if (res && res.result) {
                setQuestionSuite(() => res.result);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }

            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    async function getPagingQuestion() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.getPagingQuestion(idQuestionSuite);
            if (res && res.result.items) {
                setQuestions(res.result.items);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    let IS_ANSWER = 'isAnswer';
    let ANSWER_TEXT = 'answerText';
    let QUESTION_TEXT = 'questionText';
    let LEVEL = 'level';
    let SAVE_SINGLE = 'save_single';
    useEffect(() => {
        getQuestionSuiteDetail();
        getPagingQuestion();
    }, [change]);
    useEffect(() => {

    }, [filterType]);

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function changeAnswer(type: string, questionIndex: number, answerIndex: number, event: any) {
        // 1. Make a shallow copy of the items
        let items = [...questions];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...items[questionIndex] };
        // Process with type
        if (type == IS_ANSWER) {
            item.questionAnswers?.map((answer, itemAnswerIndex) => {
                answer.isAnswer = answerIndex == itemAnswerIndex;
            });
            item.questionAnswers[answerIndex].isAnswer = true;
        }
        if (type == ANSWER_TEXT) {
            item.questionAnswers[answerIndex].answerText = event;
            setItem(item);
        }
        if (type == LEVEL) {
            item.level = event;
            setItem(item);
        }
        if (type == QUESTION_TEXT) {
            item.questionText = event;
            setItem(item);
        }

        function setItem(item: any) {
            items[questionIndex] = item;
        }

        if (!questionEdit.find(question => question == item.id)) {
            // @ts-ignore
            setQuestionEdit(old => [...old, item.id]);
        }
        // 5. Set the state to our new copy
        setQuestions(() => items);
    }

    async function changeAnswerNew(type: string, answerIndex: number, event: any) {
        let item = { ...newQuestion };
        // Process with type
        if (type == IS_ANSWER) {
            item.questionAnswers?.map((answer, itemAnswerIndex) => {
                answer.isAnswer = answerIndex == itemAnswerIndex;
            });
            item.questionAnswers[answerIndex].isAnswer = true;
        }
        if (type == ANSWER_TEXT) {
            item.questionAnswers[answerIndex].answerText = event;
        }
        if (type == LEVEL) {
            item.level = event;
        }
        if (type == QUESTION_TEXT) {
            item.questionText = event;
        }

        // 5. Set the state to our new copy
        setNewQuestion(() => item);
    }

    function isEditQuestion(id: string) {
        return questionEdit.find((question) => question == id);
    }

    async function saveQuestion(saveType: string, question: any) {
        if (question.questionText === '') {
            show_ErrMsg('Nhập nội dung câu hỏi');
            return;
        }
        if (question.questionAnswers[0].answerText === '') {
            show_ErrMsg('Nhập đáp án 1');
            return;
        }
        if (question.questionAnswers[1].answerText === '') {
            show_ErrMsg('Nhập đáp án 2');
            return;
        }
        if (question.questionAnswers[2].answerText === '') {
            show_ErrMsg('Nhập đáp án 3');
            return;
        }
        if (question.questionAnswers[3].answerText === '') {
            show_ErrMsg('Nhập đáp án 4');
            return;
        }
        try {
            dispatch(setLoading(true));
            if (saveType == SAVE_SINGLE) {
                const res: any = await $api.question.saveQuestion(question);
                if (res && res.result) {
                    if (isNew) {
                        if (question.level !== filterType && filterType !== 99) {
                            setFilterType(99);
                        } else {
                            setFilterType(question.level);
                        }
                        removeNewQuestion();
                        await getPagingQuestion();
                    } else {
                        setQuestionEdit(prevState => prevState.filter(value => value !== question.id));
                        await getPagingQuestion();
                    }
                }
            } else {

            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }

            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }


    async function questionRemove() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.deleteQuestion(idDelete);
            if (res) {
                setIdDelete(null);
                setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
                getPagingQuestion();
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }

            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    function newQuestionSuite() {
        setIsNew(true);
    }

    function removeNewQuestion() {
        setIsNew(false);
        setNewQuestion({
            questionSuiteId: idQuestionSuite,
            questionText: '',
            level: 0,
            isEdit: true,
            levelText: '',
            questionAnswers: [
                {
                    answerText: '',
                    isAnswer: true
                },
                {
                    answerText: '',
                    isAnswer: false
                },
                {
                    answerText: '',
                    isAnswer: false
                },
                {
                    answerText: '',
                    isAnswer: false
                }
            ]
        });
    }

    function questionConfirmRemove(id) {
        setIdDelete(id);
        setConfirmDialog({ isOpen: true, subTitle: 'Bạn muốn xóa câu hỏi?', title: 'Xác nhận' });
    }

    async function actionImport() {
        // @ts-ignore
        document.getElementById('questionImport').click();
    }

    async function actionExport() {
        const res: any = await $api.question.exportQuestion(idQuestionSuite);
        fileDownload(res, `bo-cau-hoi-${questionSuite.name}.xlsx`);
    }

    async function downloadTemplate() {
        const res: any = await $api.question.downloadTemplate();
        fileDownload(res, `bieu-mau-nhap-bo-cau-hoi.xlsx`);
    }

    async function uploadFile(event) {
        const formData = new FormData();
        formData.append('formFile', event.target.files[0]);
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.importQuestion(
                formData,
                idQuestionSuite
            );
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                window.location.reload();
                dispatch(setLoading(false));
                return;
            }
            if (res && res.errors) {
                dispatch(setLoading(false));
                show_ErrMsg(res?.errors?.exceptionMessage ? res?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                return;
            }
        } catch (e) {
            let err = '';
            if (e.response && e.response.data && e.response.data.errors) {
                if (e.response.data.errors.rowErrors) {
                    for (var er of e.response.data.errors.rowErrors['details']) {
                        err += er + '\r\n';
                    }
                } else {
                    err = e.response.data.errors.exceptionMessage;
                }
            }
            if (e.response.data.errors['success'].count !== 0) {
                err += e.response.data.errors['success'].detail + '\r\n';
                setChange(!change);
            }
            dispatch(setLoading(false));
            show_ErrMsg(err);
            return;
        } finally {
            event.target.value = '';
        }
    }

    return (
        <DefaultLayout>
            <input
                type='file'
                id='questionImport'
                className='hidden'
                accept='.xlsx, .xls, .csv'
                onChange={(e) => {
                    uploadFile(e);
                }}
            />
            <div className='subject page position-relative'>
                <div className='page__header'>
                    <div className={'d-flex cursor-pointer'} onClick={e => history.goBack()}>
                        <div className={'font-bold'}>
                            <i className={'fas fa-chevron-left pr-2'}></i>
                            Quay lại
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className={'d-flex justify-between align-items-center'}>
                        <h2 className={'w-1/3 text-uppercase font-bold underline mt2 mb-3'}>{questionSuite.name}</h2>
                        <div className={'d-flex flex-row flex-nowrap justify-end align-middle gap-2 w-2/3 mb-4'}>
                            <Button color={'bg-blue-800'} hoverColor={'bg-blue-600'} label={'Thêm câu hỏi'}
                                    icon={<i className={'fas fa-plus'}></i>}
                                    click={e => newQuestionSuite()}></Button>
                            <Button className={'ml-2'} color={'bg-green-800'} hoverColor={'bg-green-600'}
                                    click={e => actionImport()}
                                    label={'Import'}
                                    icon={<i className={'fas fa-file-import'}></i>}></Button>
                            <Button color={'bg-green-800'} hoverColor={'bg-green-600'} label={'Export'}
                                    click={e => actionExport()}
                                    icon={<i className={'fas fa-file-export'}></i>}></Button>
                            <Button color={'bg-green-800'} hoverColor={'bg-green-600'} label={'Tải mẫu'}
                                    click={e => downloadTemplate()}
                                    icon={<i className={'fas fa-file-download'}></i>}></Button>
                            <Menu as='div' className='relative inline-block text-left'>
                                <Menu.Button
                                    className='h-full inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                                    <div className={
                                        'd-flex h-full flex-row align-items-center'
                                    }>
                                        <FilterIcon className='mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                                        {filterType == 99 ? 'Tất cả' : filterType == 0 ? 'Dễ' : filterType == 1 ? 'Trung bình' : 'Khó'}
                                    </div>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                >
                                    <Menu.Items
                                        className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                        <div className='py-1'>
                                            <Menu.Item onClick={e => setFilterType(() => 99)}>
                                                <div
                                                    className={filterType == 99 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                >
                                                    Tất cả
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item onClick={e => setFilterType(() => 0)}>
                                                <div
                                                    className={filterType == 0 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                >
                                                    Dễ
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item onClick={e => setFilterType(() => 1)}>
                                                <div
                                                    className={filterType == 1 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                >
                                                    Trung bình
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item onClick={e => setFilterType(() => 2)}>
                                                <div
                                                    className={filterType == 2 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                >
                                                    Khó
                                                </div>
                                            </Menu.Item>

                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                    {/*New question*/}
                    {
                        isNew ? <div
                            className={'border-2 gap-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 p-3 mb-5'}>
                            <div className={'d-flex justify-between align-items-center mb-2'}>
                                <h5 className={'font-bold'}>Tạo câu hỏi mới</h5>
                                <Button color={'bg-blue-800'} hoverColor={'bg-blue-600'}
                                        label={'Lưu'}
                                        click={(e) => {
                                            e.stopPropagation();
                                            saveQuestion(SAVE_SINGLE, newQuestion);
                                        }}></Button>
                            </div>
                            <div className={'pb-2 border-bottom  mb-3'}>
                                {/*Question text*/}
                                <input type='text' className={styleInput}
                                       value={newQuestion.questionText}
                                       onChange={e => changeAnswerNew(QUESTION_TEXT, 0, e.target.value)} />
                            </div>
                            {
                                newQuestion.questionAnswers.map((questionAnswer, answerIndex) =>
                                    <div className='d-flex flex-row align-items-center  mb-2' key={answerIndex}>
                                        {/*Answer checkbox*/}
                                        <input id={'new_question' + answerIndex} type='radio' name={'new_question'}
                                               defaultChecked={questionAnswer.isAnswer}
                                               onChange={e => changeAnswerNew(IS_ANSWER, answerIndex, e)}
                                               className='w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500' />
                                        {/*Answer text*/}
                                        <label htmlFor={'new_question' + answerIndex}
                                               className='ml-2 mb-0 w-full text-base font-semibold text-gray-900 dark:text-gray-300'>
                                            <div className={'d-flex items-center'}>
                                                    <span
                                                        className={'mr-1'}>{answerIndex == 0 ? 'A' : answerIndex == 1 ? 'B' : answerIndex == 2 ? 'C' : 'D'}.</span>
                                                <input type='text' className={styleInput}
                                                       value={questionAnswer.answerText}
                                                       onChange={e => changeAnswerNew(ANSWER_TEXT, answerIndex, e.target.value)} />
                                            </div>
                                        </label>
                                    </div>)
                            }
                            <div className={'w-full d-flex justify-between'}>
                                <Menu as='div' className='relative inline-block text-left'>
                                    <Menu.Button
                                        className={'h-full inline-flex w-full justify-center gap-x-1.5 rounded-md border-b-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ' +
                                            (newQuestion.level == 0 ? 'bg-green-500 hover:bg-green-600  text-light' : (newQuestion.level == 1 ? 'bg-yellow-400 hover:bg-yellow-500  text-light' : (newQuestion.level == 2 ? 'bg-yellow-600 hover:bg-yellow-700  text-light' : ' bg-red-800 hover:bg-gray-50  text-gray-900')))}>
                                        <div className={
                                            'd-flex h-full flex-row align-items-center'
                                        }>
                                            <ChevronDownIcon
                                                className={'mr-1 h-5 w-5 text-light'}
                                                aria-hidden='true' />
                                            {newQuestion.level == 0 ? 'Dễ' : (newQuestion.level == 1 ? 'Trung bình' : (newQuestion.level == 2 ? 'Khó' : 'Lựa chọn độ khó câu hỏi'))}
                                        </div>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100'
                                        enterFrom='transform opacity-0 scale-95'
                                        enterTo='transform opacity-100 scale-100'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 scale-100'
                                        leaveTo='transform opacity-0 scale-95'
                                    >
                                        <Menu.Items
                                            className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                            <div className='py-1'>
                                                <Menu.Item onClick={e => changeAnswerNew(LEVEL, 0, 0)}>
                                                    {({ active }) => (
                                                        <div
                                                            className={newQuestion.level == 0 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                        >
                                                            Dễ
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item onClick={e => changeAnswerNew(LEVEL, 0, 1)}>
                                                    {({ active }) => (
                                                        <div
                                                            className={newQuestion.level == 1 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                        >
                                                            Trung bình
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item onClick={e => changeAnswerNew(LEVEL, 0, 2)}>
                                                    {({ active }) => (
                                                        <div
                                                            className={newQuestion.level == 2 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                        >
                                                            Khó
                                                        </div>
                                                    )}
                                                </Menu.Item>

                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <button type='button' onClick={e => removeNewQuestion()}
                                        className='px-2 py-1 text-xs font-medium text-red-700 hover:text-white  border-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900'>
                                    Xóa
                                </button>

                            </div>
                        </div> : ''
                    }
                    {
                        questions.length > 0 && questions.filter((question => filterType == 99 ? question : question.level == filterType)).length > 0 ?
                            questions.filter((question => filterType == 99 ? question : question.level == filterType)).map((question, index) =>
                                <div
                                    key={question.id}
                                    className={'border-2 gap-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 p-3 mb-5'}>
                                    <div className={'d-flex justify-between align-items-center mb-2'}>
                                        <h5 className={'font-bold'}>Câu {index + 1}/{questions.filter((question => filterType == 99 ? question : question.level == filterType)).length}</h5>
                                        {
                                            isEditQuestion(question.id) ?
                                                <Button color={'bg-blue-800'} hoverColor={'bg-blue-600'}
                                                        label={'Lưu'}
                                                        click={(e) => {
                                                            e.stopPropagation();
                                                            saveQuestion(SAVE_SINGLE, question);
                                                        }}></Button> : ''
                                        }

                                    </div>
                                    <div className={'pb-2 border-bottom  mb-3'}>
                                        {/*Question text*/}
                                        <textarea className={styleInput}
                                               value={question.questionText}
                                               onChange={e => changeAnswer(QUESTION_TEXT, index, 0, e.target.value)}></textarea>
                                    </div>
                                    {
                                        question.questionAnswers.map((questionAnswer, answerIndex) =>
                                            <div className='d-flex flex-row items-start mb-2'
                                                 key={question.id + answerIndex}>
                                                {/*Answer checkbox*/}
                                                <input id={question.id + answerIndex} type='radio'
                                                       name={question.id}
                                                       defaultChecked={questionAnswer.isAnswer}
                                                       onChange={e => changeAnswer(IS_ANSWER, index, answerIndex, e)}
                                                       className='mt-2 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500' />
                                                {/*Answer text*/}
                                                <label htmlFor={question.id + answerIndex}
                                                       className='ml-2 mb-0 w-full text-base font-semibold text-gray-900 dark:text-gray-300'>
                                                    <div className={'d-flex items-start'}>
                                                    <span
                                                        className={'mr-1 mt-1'}>{answerIndex == 0 ? 'A' : answerIndex == 1 ? 'B' : answerIndex == 2 ? 'C' : 'D'}.</span>
                                                        <textarea rows={2} className={styleInput}
                                                               value={questionAnswer.answerText}
                                                               onChange={e => changeAnswer(ANSWER_TEXT, index, answerIndex, e.target.value)} />
                                                    </div>
                                                </label>
                                            </div>)
                                    }
                                    <div className={'w-full d-flex justify-between'}>
                                        <Menu as='div' className='relative inline-block text-left'>
                                            <Menu.Button
                                                className={'h-full inline-flex w-full justify-center gap-x-1.5 rounded-md border-b-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ' +
                                                    (question.level == 0 ? 'bg-green-500 hover:bg-green-600  text-light' : (question.level == 1 ? 'bg-yellow-400 hover:bg-yellow-500  text-light' : (question.level == 2 ? 'bg-yellow-600 hover:bg-yellow-700  text-light' : ' bg-red-800 hover:bg-gray-50  text-gray-900')))}>
                                                <div className={
                                                    'd-flex h-full flex-row align-items-center'
                                                }>
                                                    <ChevronDownIcon
                                                        className={'mr-1 h-5 w-5 text-light'}
                                                        aria-hidden='true' />
                                                    {question.level == 0 ? 'Dễ' : (question.level == 1 ? 'Trung bình' : (question.level == 2 ? 'Khó' : 'Lựa chọn độ khó câu hỏi'))}
                                                </div>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter='transition ease-out duration-100'
                                                enterFrom='transform opacity-0 scale-95'
                                                enterTo='transform opacity-100 scale-100'
                                                leave='transition ease-in duration-75'
                                                leaveFrom='transform opacity-100 scale-100'
                                                leaveTo='transform opacity-0 scale-95'
                                            >
                                                <Menu.Items
                                                    hidden={filterType !== 99}
                                                    className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                    <div className='py-1'>
                                                        <Menu.Item onClick={e => changeAnswer(LEVEL, index, 0, 0)}>
                                                            {({ active }) => (
                                                                <div
                                                                    className={question.level == 0 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                                >
                                                                    Dễ
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item onClick={e => changeAnswer(LEVEL, index, 0, 1)}>
                                                            {({ active }) => (
                                                                <div
                                                                    className={question.level == 1 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                                >
                                                                    Trung bình
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item onClick={e => changeAnswer(LEVEL, index, 0, 2)}>
                                                            {({ active }) => (
                                                                <div
                                                                    className={question.level == 2 ? 'bg-gray-100 text-gray-900 block px-4 py-2 text-sm' : 'text-gray-700 block px-4 py-2 text-sm'}
                                                                >
                                                                    Khó
                                                                </div>
                                                            )}
                                                        </Menu.Item>

                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <button type='button' onClick={e => questionConfirmRemove(question.id)}
                                                className='px-2 py-1 text-xs font-medium text-red-700 hover:text-white  border-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900'>
                                            Xóa
                                        </button>

                                    </div>
                                </div>) : <h6>Hiện chưa có câu hỏi!</h6>
                    }
                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={() => questionRemove()}
            />

            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default QuestionSuite__List;
