import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'components/Elements/Alert/Alert';
import { setAlert } from 'redux/authSlice';

function AuthLayout(props) {
	const dispatch = useDispatch();
	// @ts-ignore
	const alert = useSelector((state) => state.auth.alert);
	if (alert?.title.length > 0) {
		setTimeout(() => {
			dispatch(
				setAlert({
					title: '',
				})
			);
		}, 3000);
	}

	return (
		<div>
			<Alert
				show={alert?.title.length > 0}
				label={alert?.title}
				type={alert?.type}
			/>
			<div className='auth-layout'>{props.children}</div>
		</div>
	);
}

export default AuthLayout;
