import axiosClient from 'config/axiosClient';

const _url = '/Api/FormTemplates';
const formTemplateService = {
    getPaging: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    save: (option) => {
        return axiosClient.post(_url + '/Save', option);
    },
    delete: (option) => {
        return axiosClient.post(_url + '/Delete', option);
    },
    downloadDocument: (option) => {
        return axiosClient.post(_url + '/DownloadDocument', option);
    }
};

export default formTemplateService;
