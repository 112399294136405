import axiosClient from 'config/axiosClient';

const _url = '/api/settings';

const settingService = {
    settingsSchedule: (numberOfDays) => {
        return axiosClient.post(_url + '/schedule', { numberOfDays: numberOfDays });
    },

    getSettingsSchedule: () => {
        return axiosClient.post(_url + '/get-schedule', {});
    }
};

export default settingService;
