function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Pagination(props) {
    function getPages() {
        let pagesResult: any = [];
        if (props.pages < 7) {
            for (let i = 0; i < props.pages; i++) {
                pagesResult.push(i + 1);
            }
        } else {
            if (props.page < 3 || props.page > props.pages - 2) {
                pagesResult = [1, 2, 3, 0, Number(props.pages - 2), Number(props.pages - 1), Number(props.pages)];
            } else {
                pagesResult = [1, 0, Number(props.page - 1), Number(props.page), Number(props.page + 1), 0, Number(props.pages)];
            }
        }
        return pagesResult;
    }

    function nextPrevPage(type = 0) {
        if (type === 0) {
            if (props.page < props.pages) {
                props.clickedPage(Number(props.page + 1));
            }
        } else if (type === 1) {
            if (props.page > 1) {
                props.clickedPage(Number(props.page - 1));
            }
        } else if (type === 2) {
            props.clickedPage(props.pages);
        } else if (type === 3) {
            props.clickedPage(1);
        }
    }

    return (
        <div className='bg-white p-4 flex items-center justify-between border-t border-gray-200 sm:px-6 w-full'>
            <div className='sm:flex-1 sm:flex sm:items-center sm:justify-center lg:justify-between'>
                <div className='hidden lg:block'>
                    <p className='text-sm text-gray-700'>
                        Trang <span className='font-medium'>{props.page || 0} </span>
                        trong <span className='font-medium'> {props.pages || 0}</span> (
                        <span className='font-medium'> {props.total || 0}</span> kết quả)
                    </p>
                </div>
                <div>
                    <nav
                        className='relative z-0 inline-flex rounded-md -space-x-px'
                        aria-label='Pagination'>
                        <div
                            className='cursor-pointer relative inline-flex
                            items-center px-3 py-2 border rounded-l-md
                            border-gray-300 bg-white text-sm font-medium
                            text-gray-500 hover:text-blue-700'
                            onClick={() => nextPrevPage(1)}>
                            <i className='far fa-chevron-left' />
                        </div>
                        {
                            getPages().map(p => {
                                return (
                                    p !== 0 ? (
                                        <div
                                            className={
                                                classNames(`cursor-pointer
                                                    border-gray-300 text-gray-500 hover:text-blue-700
                                                    relative inline-flex items-center py-2 px-3 border
                                                    text-sm font-medium`,
                                                    props.page === p ? 'text-blue-700 bg-blue-50' : ''
                                                )
                                            }
                                            onClick={() => {
                                                props.clickedPage(p);
                                            }}
                                            key={p}>
                                            {p}
                                        </div>
                                    ) : (
                                        <div
                                            className='cursor-pointer
                                                    border-gray-300 text-gray-500 hover:text-blue-700
                                                    relative inline-flex items-center py-2 px-3 border
                                                    text-sm font-medium'>
                                            ...
                                        </div>
                                    )
                                );
                            })
                        }
                        <div
                            className='cursor-pointer relative
                            inline-flex items-center px-3 py-2
                            border border-gray-300 rounded-r-md
                            bg-white text-sm font-medium
                            text-gray-500 hover:text-blue-700'
                            onClick={() => nextPrevPage(0)}
                        >
                            <i className='far fa-chevron-right' />
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Pagination;
