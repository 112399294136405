import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useParams } from 'react-router-dom';
import { $api } from 'services/service';
import DatePicker from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function User__Create() {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    let r: any = query.get('r');

    let { id } = useParams();
    const [updateMode, setUpdateMode] = useStateIfMounted(false);
    const [faculties, setFaculties] = useStateIfMounted([]);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [role, setRole] = useState(99);

    const [userDetail, setUserDetail] = useStateIfMounted({
        userName: '',
        facultyId: '',
        password: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        role: 99,
        isActived: true,
        isStaff: false
    });
    const [userDetailTmp, setUserDetailTmp] = useStateIfMounted({
        userName: '',
        facultyId: '',
        password: '',
        fullName: '',
        birthDate: new Date(),
        identificationNumber: '',
        userInternalId: '',
        mobile: '',
        profilePicUrl: '',
        role: 99,
        isActived: true,
		isStaff: false
	});

    useEffect(() => {
        if (r && r == 2) {
            setRole(Number(r));
        } else if (r) {
            history.push('/quan-ly-chung');
        }

        //
        async function getUserDetail(id) {
            try {
                dispatch(setLoading(true));
                let res;
                if (r && r == 2) {
                    res = await $api.teacher.getDetail(id);
                } else {
                    res = await $api.user.getDetail(id);
                }
                if (res && res.result) {
                    setUpdateMode(true);
                    setUserDetail({
                        ...res.result,
                        birthDate: moment(
                            res.result.birthDate,
                            'DD/MM/YYYY'
                        ).isValid()
                            ? moment(
                                res.result.birthDate,
                                'DD/MM/YYYY'
                            ).toDate()
                            : res.result.birthDate
                    });
                    let tmp = Object.assign({}, userDetail);
                    setUserDetailTmp(tmp);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                setUpdateMode(false);
            }
        }

        //
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.getPaging({});
                if (res && res.result) {
                    setFaculties(res.result.items);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFaculties();

        if (id && id !== 'them-moi') {
            getUserDetail(id);
        }
    }, [updateMode]);

    async function submit(e) {
        e.preventDefault();
        let res;
        try {
            if (role === 2) {
                if (faculties.length === 0) {
                    show_ErrMsg('Vui lòng tạo thông tin khoa');
                    return;
                } else {
                    // @ts-ignore
                    if (userDetail.facultyId === '' && faculties[0].id === '') {
                        show_ErrMsg('Vui lòng chọn thông tin khoa');
                        return;
                    }
                }
            }
            if (userDetail.userInternalId === '') {
                show_ErrMsg('Vui lòng nhập mã ' + getRole());
                return;
            }
            if (userDetail.fullName === '') {
                show_ErrMsg('Vui lòng nhập tên ' + getRole());
                return;
            }
            if (userDetail.userName === '') {
                show_ErrMsg('Vui lòng nhập địa chỉ mail');
                return;
            }
            // if (userDetail.userName !== '') {
            // 	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // 	if (re.test(userDetail.userName) === false) {
            // 		show_ErrMsg('Thông tin mail không đúng định dạng');
            // 		return;
            // 	}
            // }
            dispatch(setLoading(true));
            res = role === Number(2) ? await createUpdateTeacher() : await createUpdateUser();
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                history.push(`/quan-ly-chung`);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function createUpdateTeacher() {
        // @ts-ignore
        let a = {
            ...userDetail,
            facultyId:
                userDetail.facultyId && userDetail.facultyId.length > 0
                    ? userDetail.facultyId
                    : // @ts-ignore
                    faculties[0].id,
            role: 2
        };
        if (updateMode) {
            return await $api.teacher.updateTeacher(a);
        } else {
            return await $api.teacher.createTeacher(a);
        }
    }

    async function createUpdateUser() {
        let a = {
            ...userDetail,
            facultyId: null
        };
        if (updateMode) {
            return await $api.user.updateUser(a);
        }
        return await $api.user.createCUser(a);
    }

    function getRole() {
        switch (role) {
            case 2:
                return 'giảng viên';
            case 99:
                return 'cán bộ';
        }
    }

    function cancel() {
        setUserDetail(userDetailTmp);
        history.push(`/quan-ly-chung`);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <a href='/quan-ly-chung'>Quản lý chung</a>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>
                            {updateMode
                                ? userDetail.fullName
                                : `Thêm mới ${getRole()}`}
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='max-450 py-4'>
                        <div className='label-form font-bold'>
                            Thông tin {getRole()}:
                        </div>
                        <div>
                            <form
                                className='mt-4 space-y-4 flex item-center
						flex-col justify-center'
                                onSubmit={(e) => submit(e)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && submit(e)
                                }>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Mã {getRole()}
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder={'Nhập mã ' + getRole()}
                                        value={userDetail.userInternalId || ''}
                                        onChange={(e) => {
                                            setUserDetail({
                                                ...userDetail,
                                                userInternalId: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Tên {getRole()}
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                        placeholder={'Nhập tên ' + getRole()}
                                        value={userDetail.fullName || ''}
                                        onChange={(e) => {
                                            setUserDetail({
                                                ...userDetail,
                                                fullName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Địa chỉ email
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập địa chỉ email'
                                        value={userDetail.userName || ''}
                                        onChange={(e) => {
                                            setUserDetail({
                                                ...userDetail,
                                                userName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Số điện thoại
                                    </div>
                                    <input
                                        name='text'
                                        type='tel'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập số điện thoại'
                                        value={userDetail.mobile || ''}
                                        onChange={(e) => {
                                            setUserDetail({
                                                ...userDetail,
                                                mobile: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Chứng minh thư
                                    </div>
                                    <input
                                        name='text'
                                        type='text'
                                        className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                        placeholder='Nhập chứng minh thư'
                                        value={
                                            userDetail.identificationNumber ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setUserDetail({
                                                ...userDetail,
                                                identificationNumber:
                                                e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                {role === Number(2) ? (
                                    <>
                                        <div>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                                Khoa
                                            </div>
                                            <select
                                                className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                                // @ts-ignore
                                                value={userDetail.facultyId}
                                                onChange={(e) => {
                                                    setUserDetail({
                                                        ...userDetail,
                                                        // @ts-ignore
                                                        facultyId: e.target.value
                                                    });
                                                }}>
                                                {faculties.map((item: any) => {
                                                    return (
                                                        <option
                                                            value={item.id}
                                                            key={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div>

                                            <div
                                                className='pl-1 text-sm font-semibold mb-2 text-gray-600 d-flex align-items-center'>
                                                <input type='checkbox' title={'Đồng thời là cán bộ'}
                                                       className={'mr-2 form-check-input'} checked={userDetail.isStaff} onClick={event => setUserDetail({
													...userDetail,
													// @ts-ignore
													isStaff: event.target.checked
												})} /><span>Giảng viên này đồng thời là cán bộ </span>

                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                            Chức vụ
                                        </div>
                                        <select
                                            className='input-custom block
													w-full px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                            // @ts-ignore
                                            value={userDetail.role}
                                            onChange={(e) => {
                                                setUserDetail({
                                                    ...userDetail,
                                                    role: Number(
                                                        e.target.value
                                                    )
                                                });
                                            }}>
                                            <option value={10} key={10}>
                                                Cán bộ nhà trường
                                            </option>
                                            <option value={99} key={99}>
                                                Ban quản trị
                                            </option>
                                        </select>
                                    </div>
                                )}

                                <div>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Ngày sinh
                                    </div>
                                    <DatePicker
                                        selected={
                                            new Date(userDetail.birthDate)
                                        }
                                        onChange={(date) => {
                                            setUserDetail({
                                                ...userDetail,
                                                birthDate: date
                                            });
                                        }}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </div>
                                <div className='flex justify-center'>
                                    <button
                                        type='button'
                                        className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
                                        onClick={cancel}>
                                        Hủy Bỏ
                                    </button>
                                    <button
                                        type='submit'
                                        className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
                                        Lưu lại
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default User__Create;
