import './side-menu.scss';
import { NavLink } from 'react-router-dom';
import { _AUTH } from '../../../constants/_auth';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function SideMenu(props) {
    const [urlTkb, setUrlTkb] = useState({ mainActive: false, subActiveTitle: '' });
    let history = useHistory();
    let role = localStorage.getItem(_AUTH.ROLE) !== null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';
    useEffect(() => {
        if (history.location.pathname.search('/thoi-khoa-bieu') >= 0) {
            setUrlTkb({
                mainActive: true,
                subActiveTitle: ''
            });
        }
    }, [history]);
    return (
        <div
            className={classNames(
                'side-menu bg-white h-full shadow-sm side-menu--hidden lg:side-menu--full',
                props.hide ? '' : 'side-menu--full'
            )}>
            <div className='_wrapper'>
                {role ?
                    <><NavLink exact to='/' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-chalkboard-teacher text-xl' />
                            </div>
                            <div className='label font-semibold'>Khoa</div>
                        </div>
                    </NavLink>
                        <div className='space my-1' />
                        <NavLink exact to='/quan-ly-chung' activeClassName='active-link'>
                            <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                <div className='icon d-icon mr-8 opacity-100'>
                                    <i className='fas fa-users text-xl' />
                                </div>
                                <div className='label font-semibold'>Quản lý chung</div>
                            </div>
                        </NavLink>
                        <NavLink exact to='/quan-ly-sinh-vien' activeClassName='active-link'>
                            <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                <div className='icon d-icon mr-8 opacity-100'>
                                    <i className='fas fa-users-cog text-xl' />
                                </div>
                                <div className='label font-semibold'>Quản lý SV</div>
                            </div>
                        </NavLink>
                    </> : ''
                }
                <div className='space my-1' />
                <div
                    className={urlTkb.mainActive ? 'active-link' : ''}>
                    <NavLink to='/thoi-khoa-bieu' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='far fa-calendar-alt text-xl' />
                            </div>
                            <div className='label font-semibold'>Thời khóa biểu</div>
                        </div>
                    </NavLink>
                </div>
                {
                    role ? <div className={'ml-4'}>
                        <div className='space my-1' />
                        <div
                            className={history.location.pathname.search('/thoi-khoa-bieu-toan-khoa') >= 0 ? 'border-l-4 border-blue-900 bg-blue-50 rounded-md' : ''}>
                            <NavLink to='/thoi-khoa-bieu-toan-khoa'>
                                <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                    <div className='icon d-icon mr-8 opacity-100'>
                                        <i className='fas fa-calendar text-xl' />
                                    </div>
                                    <div className='label font-semibold'>TKB toàn khóa</div>
                                </div>
                            </NavLink>
                        </div>
                        <div className='space my-1' />
                        <div
                            className={history.location.pathname.search('/thoi-khoa-bieu-lop') >= 0 ? 'border-l-4 border-blue-900 bg-blue-50 rounded-md' : ''}>
                            <NavLink to='/thoi-khoa-bieu-lop'>
                                <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                    <div className='icon d-icon mr-8 opacity-100'>
                                        <i className='fas fa-calendar text-xl' />
                                    </div>
                                    <div className='label font-semibold'>TKB Lớp</div>
                                </div>
                            </NavLink>
                        </div>
                        <div className='space my-1' />
                        <div
                            className={history.location.pathname.search('/thoi-khoa-bieu-phong') >= 0 ? 'border-l-4 border-blue-900 bg-blue-50 rounded-md' : ''}>
                            <NavLink to='/thoi-khoa-bieu-phong'>
                                <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                                    <div className='icon d-icon mr-8 opacity-100'>
                                        <i className='fas fa-calendar text-xl' />
                                    </div>
                                    <div className='label font-semibold'>TKB Phòng</div>
                                </div>
                            </NavLink>
                        </div>
                    </div> : ''
                }
                {role ? <>
                <div className='space my-1' />
                    <NavLink to='/ke-hoach-toan-khoa' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-calendar text-xl' />
                            </div>
                            <div className='label font-semibold'>Kế hoạch toàn khóa</div>
                        </div>
                    </NavLink>
                    <div className='space my-1' />
                    <NavLink to='/bieu-mau-xac-nhan' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='far fa-file-alt text-xl' />
                            </div>
                            <div className='label font-semibold'>Biểu mẫu, xác nhận</div>
                        </div>
                    </NavLink>
                    <div className='space my-1' />
                    <NavLink to='/audit-log' activeClassName='active-link'>
                        <div className='active side-menu--item flex items-center text-gray-900 cursor-pointer'>
                            <div className='icon d-icon mr-8 opacity-100'>
                                <i className='fas fa-history text-xl' />
                            </div>
                            <div className='label font-semibold'>Audit Log</div>
                        </div>
                    </NavLink>
                </> : ''}
            </div>
        </div>
    );
}

export default SideMenu;
