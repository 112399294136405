import axiosClient from 'config/axiosClient';

const _url = '/Api';
const questionService = {
    getQuestionSuite: (id, freeTextSearch) => {
        return axiosClient.post(_url + '/Question/GetPagingQuestionSuite', {
            subjectId: id,
            freeTextSearch: freeTextSearch
        });
    },

    getPagingQuestion: (id) => {
        return axiosClient.post(_url + '/Question/GetPagingQuestion', { questionSuiteId: id });
    },
    getQuestionSuiteDetail: (id) => {
        return axiosClient.post(_url + '/Question/getQuestionSuiteDetail', { id: id });
    },
    saveQuestionSuite: (questionSuite) => {
        return axiosClient.post(_url + '/Question/SaveQuestionSuite', questionSuite);
    },
    saveQuestion: (question) => {
        return axiosClient.post(_url + '/Question/SaveQuestion', question);
    },
    deleteQuestionSuite: (id) => {
        return axiosClient.post(_url + '/Question/DeleteQuestionSuite', {id: id});
    },
    deleteQuestion: (id) => {
        return axiosClient.post(_url + '/Question/DeleteQuestion', {id: id});
    },
    downloadTemplate: () => {
        return axiosClient.get(_url + '/Question/Template', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    },
    importQuestion: (data, id) => {
        return axiosClient.post(_url + '/Question/Import?questionSuiteId=' + id, data);
    },
    exportQuestion: (id) => {
        return axiosClient.get(_url + '/Question/Export?questionSuiteId=' + id, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type':
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        });
    },
};
export default questionService;