import axiosClient from 'config/axiosClient';

const _url = '/Api/FormRequests';
const formRequestService = {
    getPaging: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    get: (id) => {
        return axiosClient.post(_url + '/Get', {id: id});
    },
    changeStatus: (option) => {
        return axiosClient.post(_url + '/ChangeStatus', option);
    },
};

export default formRequestService;
