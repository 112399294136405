import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useState } from 'react';
import { $api } from 'services/service';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

function UpdatePassword() {
	// @ts-ignore
	const user = useSelector((state) => state.auth.user);

	const dispatch = useDispatch();
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [data, setData] = useState({
		userName: user.UserName,
		oldPassword: '',
		newPassword: '',
		newPasswordConfirm: '',
		passwordResetId: '',
	});

	async function submit(e) {
		e.preventDefault();
		if (data.oldPassword === '') {
			show_ErrMsg('Vui lòng nhập mật khẩu cũ');
			return;
		}
		if (data.newPassword === '') {
			show_ErrMsg('Vui lòng nhập mật khẩu mới');
			return;
		}
		if (data.newPasswordConfirm === '') {
			show_ErrMsg('Vui lòng nhập xác nhận mật khẩu');
			return;
		}
		if (data.newPassword !== data.newPasswordConfirm) {
			show_ErrMsg('Xác nhận mật khẩu không khớp');
			return;
		}

		const d = {
			userName: data.userName,
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
			passwordResetId: null,
		};
		try {
			dispatch(setLoading(true));
			const res: any = await $api.auth.changePassword(d);
			dispatch(
				setAlert({
					title: res?.message,

				})
			);
			setTimeout(() => {
				window.location.href = '/';
			}, 500);
			dispatch(setLoading(false));
			return;
		} catch (e) {
			dispatch(setLoading(false));
			if (e?.response?.data?.errors?.exceptionMessage === 'Wrong password') {
				show_ErrMsg('Sai mật khẩu');
			}
			else {
				// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
			return;
		}
	}

	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}

	function cancel() { }
	return (
		<DefaultLayout>
			<div className='page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<div>Đổi mật khẩu</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div className='max-450 py-4'>
						<div className='label-form font-bold'>Đổi mật khẩu</div>
						<div>
							<form
								className='mt-4 space-y-4 flex item-center
						                    flex-col justify-center'
								onSubmit={(e) => submit(e)}
								onKeyDown={(e) =>
									e.key === 'Enter' && submit(e)
								}>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Mật khẩu cũ
									</div>
									<input
										name='password'
										type='password'
										autoComplete='password'
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập mật khẩu'
										value={data.oldPassword}
										onChange={(e) =>
											setData({
												...data,
												oldPassword: e.target.value,
											})
										}
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Mật khẩu mới
									</div>
									<input
										name='password'
										type='password'
										autoComplete='password'
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập mật khẩu'
										value={data.newPassword}
										onChange={(e) =>
											setData({
												...data,
												newPassword: e.target.value,
											})
										}
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Xác nhận mật khẩu mới
									</div>
									<input
										name='password'
										type='password'
										autoComplete='password'
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập xác nhận mật khẩu'
										value={data.newPasswordConfirm}
										onChange={(e) =>
											setData({
												...data,
												newPasswordConfirm:
													e.target.value,
											})
										}
									/>
								</div>
								<div className='flex justify-center'>
									<button
										type='button'
										className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-red-600
								hover:bg-red-700 focus:outline-none mt-3 mr-4'
										onClick={cancel}>
										Hủy Bỏ
									</button>
									<button
										type='submit'
										className='text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
										Đổi mật khẩu
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</DefaultLayout>
	);
}

export default UpdatePassword;
