function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function Button(props) {
	return (
		<button
			className={classNames(
				`text-center py-2.5 px-3.5 border
						border-transparent rounded-md shadow-sm
						text-sm font-medium text-white whitespace-nowrap
						focus:outline-none`,
				props.color ? props.color : 'bg-blue-800',
				props.hoverColor
					? `hover:${props.hoverColor}`
					: 'hover:bg-blue-900',
				props.width ? props.width : ''
			)} onClick={event =>props.click ?  props.click(event) : ''}>
			{props.icon ? <span className='mr-3'>{props.icon}</span> : ''}
			{props.label || ' Tiêu đề'}
		</button>
	);
}

export default Button;
