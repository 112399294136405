import axiosClient from 'config/axiosClient';

const _url = '/Api/Teachers';
const teacherService = {
    getAll: () => {
        return axiosClient.post(_url + '/GetAll');
    },
    getPaging: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    getDetail: (id) => {
        return axiosClient.post(_url + '/Get', { id: id });
    },
    createTeacher: (data) => {
        return axiosClient.post(_url + '/Create', data);
    },
    updateTeacher: (data) => {
        return axiosClient.post(_url + '/Update', data);
    },
    deleteTeacher: (id) => {
        return axiosClient.post(_url + '/Delete', { id: id });
    },
};

export default teacherService;
