import axiosClient from 'config/axiosClient';

const _url = '/Api/userannouncements';
const userAnnouncements = {
    getPaging: (data) => {
        return axiosClient.post(_url + '/getpaging', data);
    },
    createNotification: (data) => {
        return axiosClient.post(_url + '/CreateNotification', data);
    }
};
export default userAnnouncements;