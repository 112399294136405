import './SubjectPlanning.scss';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import { $api } from 'services/service';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { useParams } from 'react-router';
import { setAlert, setLoading } from '../../redux/authSlice';
import TableSubjectPlanning from '../../components/Elements/Table/Table-Subject-Planning';
import { Modal } from 'react-bootstrap';

const fileDownload = require('js-file-download');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SubjectPlanning() {
    let history = useHistory();
    const dispatch = useDispatch();
    // @ts-ignore
    const { cId, semesterPlanningId } = useParams();
    const [classes, setClasses] = useState([]);
    const [subjectPlannings, setSubjectPlannings] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subjectSelectList, setSubjectSelectList] = useState<any>([]);
    const [changes, setChanges] = useState(false);
    const [graduated, setGraduated] = useState('0');
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);
    const [updateSubject, setUpdateSubject] = useState(false);
    const [modalSubjectList, setModalSubjectList] = useState(false);
    const [subjectPlanning, setSubjectPlanning] = useState({
        id: '',
        subjectId: '',
        semesterPlanningId: semesterPlanningId
    });
    const [subjectRequest, setSubjectRequest] = useState({ id: cId, searchText: '' });
    useEffect(() => {
        async function getSubjectPlanning() {
            try {
                if (semesterPlanningId) {
                    dispatch(setLoading(true));
                    const res: any = await $api.subjectPlanning.getListSubject(subjectRequest);
                    if (res && res.result) {
                        setSubjectList(res.result);
                        setSubjectRequest({ ...subjectRequest, searchText: '' });
                    }
                    dispatch(setLoading(false));
                }
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getSubjectPlanning();
    }, [changes, semesterPlanningId, graduated]);
    useEffect(() => {
        async function getBySemester() {
            try {
                if (semesterPlanningId) {
                    dispatch(setLoading(true));
                    const res: any = await $api.subjectPlanning.getBySemester(semesterPlanningId);
                    if (res && res.result) {
                        setSubjectPlannings(res.result);
                    }
                    dispatch(setLoading(false));
                }
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getBySemester();
    }, [changes, semesterPlanningId, graduated]);

    function changePage(page) {
        setPage(page);
        setChanges(!changes);
    }

    async function deleteStudent(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subjectPlanning.delete(idDelete);
            if (res) {
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }


    function getSubjectPlanning(e, id) {
        e.stopPropagation();
        history.push(`/chi-tiet-hoc-ki/${id}`);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function deleteSubject(e, id) {
        e.stopPropagation();
        setIdDelete(id);
        setConfirmDialog({ isOpen: true, title: 'Xác nhận', subTitle: 'Bạn muốn xóa môn ra khỏi học kì?' });
    }

    async function onSaveSubjectPlanning() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subjectPlanning.save({
                subjectIds: subjectSelectList,
                semesterPlanningId: semesterPlanningId
            });
            if (res) {
                setModalSubjectList(false);
                setSubjectSelectList([]);
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }

    function subjectSelected(id) {
        setSubjectSelectList(prevIds => {
            const index = prevIds.indexOf(id);
            if (index === -1) {
                // If id not equal array add
                return [...prevIds, id];
            } else {
                // If id equal array delete
                return prevIds.filter(existingId => existingId !== id);
            }
        });
    }

    function findSubject(id: any) {
        console.log(subjectSelectList);
        return subjectSelectList.indexOf(id);
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <a href={`/ke-hoach-toan-khoa?f=${semesterPlanningId}`}>
                                Chi tiết học kì
                            </a>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='actions--item d-flex'>
                            <div onClick={() => setModalSubjectList(true)}>
                                <Button
                                    label='Thêm môn học'
                                    icon={<i className='fas fa-user-plus' />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='w-auto-scroll'>
                            {
                                subjectPlannings.length === 0 ?
                                    <div className='flex flex-wrap -ml-4 -mb-4'
                                         style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='/notFound_01.png'
                                             alt='Không tìm thấy dữ liệu'
                                             title='Không tìm thấy dữ liệu'
                                        />
                                    </div> : <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableSubjectPlanning
                                            data={subjectPlannings}
                                            deleteClicked={deleteSubject}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modalSubjectList}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => {
                    setModalSubjectList(false);
                    setSubjectSelectList([]);
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Danh sách môn học
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-column flex-nowrap'>
                        <div className='mt-2 w-100'>
                            <div className='relative mb-3'>
                                <div
                                    className='absolute inset-y-0 start-0 flex items-center px-2 pointer-events-none'>
                                    <svg className='w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden='true'
                                         xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'>
                                        <path stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'
                                              stroke-width='2' d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z' />
                                    </svg>
                                </div>
                                <input type='search' id='default-search'
                                       className='block w-full p-2.5 pl-4 pr-28 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                       placeholder='Tìm kiếm môn học' onChange={event => setSubjectRequest({
                                    ...subjectRequest,
                                    searchText: event.target.value
                                })} onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        setChanges(!changes);
                                    }
                                }} />
                                <button onClick={event => setChanges(!changes)}
                                        className='text-white absolute right-1 bottom-0.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Tìm
                                    kiếm
                                </button>
                            </div>
                            {
                                subjectList.length > 0 ? <table className='min-w-full divide-y divide-gray-200'>
                                        <thead className='bg-gray-50'>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='px-3 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider'>
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Khoa
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Tên môn
                                            </th>
                                            <th
                                                scope='col'
                                                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                                Số tín chỉ
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
                                        {subjectList.map((item) => (
                                            <tr
                                                className='hover:bg-gray-100'
                                                key={item.id}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    subjectSelected(item.id);
                                                }}>
                                                <td className='px-2 py-2 whitespace-nowrap'>
                                                    <input id='checkbox-item-1' type='checkbox'
                                                           checked={subjectSelectList.includes(item.id)}
                                                           className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500' />
                                                </td>
                                                <td className='px-6 py-4 whitespace-nowrap'>
                                                    <div className='flex text-left items-start text-sm text-gray-900'>
                                                        {item.facultyName || ''}
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 whitespace-nowrap'>
                                                    <div className='flex items-start text-sm text-gray-900'>
                                                        {item.name || ''}
                                                    </div>
                                                </td>
                                                <td className='px-6 py-4 whitespace-nowrap'>
                                                    <div className='flex text-center items-start text-sm text-gray-900'>
                                                        {item.creditPoint || ''}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    : 'Chưa có thông tin môn học'
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'flex flex-row justify-between w-full'}>
                        <div
                            onClick={() => {
                                setModalSubjectList(false);
                                setSubjectSelectList([]);
                            }}>
                            <Button color={'bg-red-600'} hoverColor={'bg-red-800'}
                                    icon={<i className='fas fa-times-circle'></i>} label='Hủy' />
                        </div>
                        <div
                            onClick={() => {
                                onSaveSubjectPlanning();
                            }}>
                            <Button icon={<i className='fas fa-save'></i>}
                                    label={'Lưu lại'} />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default SubjectPlanning;
