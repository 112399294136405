import React from 'react';
import './success.scss';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';

function Success() {
	function goLogin(e) {
		e.stopPropagation();
		window.location.href = '/auth';
	}

	return (
		<AuthLayout>
			<div className='auth'>
				<div className='login-dialog'>
					<div className='login bg-white rounded-xl h-full w-full flex justify-center items-center flex-col'>
						<div className='login__logo rounded-full border-8 border-white bg-white absolute'>
							<img
								className='img-logo rounded-full'
								src='/logo.png'
								alt='Cao Đẳng Tây Đô'
								title='Cao Đẳng Tây Đô'
							/>
						</div>
						<div className='login__content'>
							<div className='font-bold text-xl text-center mb-2.5 color-sapphire'>
								Đăng Nhập
							</div>
							<div>
								Chào mừng bạn đến với{' '}
								<span className='color-my-sin font-bold'>
									Cao Đẳng Tây Đô
								</span>
							</div>
							<div>
								<div className='text-center font-bold text-xl my-4'>
									Đổi mật khẩu thành công
								</div>
								<div>
									<button
										type='submit'
										className='w-full text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'
										onClick={(e) => goLogin(e)}>
										Đăng Nhập
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}

export default Success;
