import React, { useEffect, useState } from 'react';
import { setAlert, setLoading } from 'redux/authSlice';
import { useDispatch } from 'react-redux';
import { $api } from 'services/service';
import { useHistory, useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function ChangePasswordForm() {
	const dispatch = useDispatch();
	let history = useHistory();
	const query = useQuery();
	let id: any = query.get('Id');

	const [data, setData] = useState({
		password: '',
		passwordConfirm: '',
		userName: '',
		passwordResetId: id || null,
	});
	const [Response, setResponse] = useState(false);
	const [username, setUsername] = useState('');
	useEffect(() => {
		async function getResetId() {
			try {
				const res: any = await $api.auth.resetPassword(
					data.passwordResetId
				);
				if (res?.message.length <= 0) {
					history.push('/auth');
				}
				setUsername(res?.message);
			} catch (e) {
				dispatch(
					setAlert({
						type: 'danger',
						title: e?.response?.data?.errors?.exceptionMessage
							? e?.response?.data?.errors?.exceptionMessage
							: 'Đã có lỗi xảy ra, xin vui lòng thử lại',
					})
				);
			}
		}

		getResetId();
	}, []);

					const submit = async (e) => {
						e.preventDefault();
						// @ts-ignore
						try {
							if (data.password !== data.passwordConfirm) {
								dispatch(
									setAlert({
										type: 'danger',
										title: 'Xác nhận mật khẩu không khớp',
									})
								);
								return;
							}
							if (!username && username.length <= 0) {
								dispatch(
									setAlert({
										type: 'danger',
										title: 'Xác nhận mật khẩu không khớp',
									})
								);
								return;
							}
							dispatch(setLoading(true));
							const d = {
								newPassword: data.password || null,
								userName: username || null,
								passwordResetId: data.passwordResetId || null,
							};
							const res: any = await $api.auth.changePassword(d);
							dispatch(
								setAlert({
									title: res?.message,
				})
			);
			data.password = '';
			data.passwordConfirm = '';
			data.userName = '';
			setData({ ...data });
			setResponse(true);
			dispatch(setLoading(false));
			/*window.location.href = '/thanh-cong';*/
		} catch (e) {
			dispatch(
				setAlert({
					type: 'danger',
					title: e?.response?.data?.errors?.exceptionMessage
						? e?.response?.data?.errors?.exceptionMessage
						: 'Đã có lỗi xảy ra, xin vui lòng thử lại',
				})
			);
			dispatch(setLoading(false));
		}
	};

	return (
		<div className='login bg-white rounded-xl h-full w-full flex justify-center items-center flex-col'>
			<div className='login__logo rounded-full border-8 border-white bg-white absolute'>
				<img
					className='img-logo rounded-full'
					src='/logo.png'
					alt='Cao Đẳng Tây Đô'
					title='Cao Đẳng Tây Đô'
				/>
			</div>
			<div className='login__content'>
				<div className='font-bold text-xl text-center mb-2.5 color-sapphire'>
					Đặt lại mật khẩu
				</div>
				<div>
					Chào mừng bạn đến với{' '}
					<span className='color-my-sin font-bold'>
						Cao Đẳng Tây Đô
					</span>
				</div>
				<div>
					{
						Response ?
							<div className='font-bold text-xl text-center mb-2.5 color-sapphire'
								style={{display: 'flex',  justifyContent:'center', alignItems:'center', textAlign:'center'}}>
								<div>
									<img style={{display: '-webkit-inline-box'}}
										src='/checked.png'
										alt='Đổi mật khẩu thành công'
										title='Đổi mật khẩu thành công'
									/>
									<div>Đổi mật khẩu thành công !</div>
								</div>
							</div>
							:
							<form
								className='mt-4 space-y-4 flex item-center flex-col justify-center'
								onSubmit={(e) => submit(e)}
								onKeyDown={(e) => e.key === 'Enter' && submit(e)}>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Tên tài khoản
									</div>
									<input
										name='username'
										type='text'
										autoComplete='username'
										required
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Tên tài khoản'
										value={username}
										disabled
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Mật khẩu
									</div>
									<input
										name='password'
										type='password'
										autoComplete='password'
										required
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập mật khẩu'
										value={data.password}
										onChange={(e) =>
											setData({
												...data,
												password: e.target.value,
											})
										}
									/>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Xác nhận mật khẩu
									</div>
									<input
										name='password'
										type='password'
										autoComplete='password'
										required
										className='input-custom appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
										placeholder='Nhập xác nhận mật khẩu'
										value={data.passwordConfirm}
										onChange={(e) =>
											setData({
												...data,
												passwordConfirm: e.target.value,
											})
										}
									/>
								</div>
								<div>
									<button
										type='submit'
										className='w-full text-center px-10 py-2.5 border
								border-transparent rounded-md shadow-sm
								text-sm font-medium text-white bg-blue-800
								hover:bg-blue-900 focus:outline-none mt-3'>
										Đặt lại mật khẩu
									</button>
								</div>
							</form>
					}

				</div>
			</div>
		</div>
	);
}

export default ChangePasswordForm;
