import classService from './class.service';
import authService from './auth.service';
import facultiesService from './faculties.service';
import studentsService from './student.service';
import userService from './user.service';
import teacherService from './teacher.service';
import roomService from './room.service';
import scheduleService from './schedule.service';
import subjectService from './subjects.service';
import examService from './examService';
import settingService from './setting.service';
import subjectDocumentService from './subjectDocument';
import attendanceService from './attendance.service';
import formRequestService from './formRequest.service';
import formTemplateService from './formTemplate.service';
import questionService from './question.service';
import userAnnouncementsService from './userAnnouncements.service';
import semesterPlanningService from './semesterPlanning.service';
import subjectPlanningService from './subjectPlanning.service';

export const $api = {
    auth: authService,
    class: classService,
    faculties: facultiesService,
    student: studentsService,
    user: userService,
    teacher: teacherService,
    room: roomService,
    schedule: scheduleService,
    subject: subjectService,
    subjectDocument: subjectDocumentService,
    exam: examService,
    setting: settingService,
    attendance: attendanceService,
    formRequest: formRequestService,
    formTemplate: formTemplateService,
    question: questionService,
    userAnnouncement: userAnnouncementsService,
    semesterPlanning: semesterPlanningService,
    subjectPlanning: subjectPlanningService,
};
