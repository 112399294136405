import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import Pagination from 'components/Elements/Pagination/Pagination';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { $api } from 'services/service';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TableFormRequest from '../../../components/Elements/Table/Table-FormRequest';
import { Modal } from 'react-bootstrap';
import TableFormTemplate from '../../../components/Elements/Table/Table-FormTemplate';
import { useHistory } from 'react-router';

const fileDownload = require('js-file-download');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Form_Request__List() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [changes, setChanges] = useState(false);
    const [formRequests, setFormRequests] = useState([]);
    const [formTemplates, setFormTemplates] = useState([]);
    const [filterStatus, setFilterStatus] = useState([1, 2, 3]);
    const [page, setPage] = useState(1)
    const [totalRequest, setTotalRequest] = useState({total: 0, pages: 0})
    const [totalTemplate, setTotalTemplate] = useState({total: 0, pages: 0})
    const [tabIndex, setTabIndex] = useState(0);
    const [sortBy, setSortBy] = useState({ name: 'creationDate', order: 'desc' });
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', deleteId: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [formTemplate, setFormTemplate] = useState({
        id: '',
        name: '',
        // documentUrl: '',
        file: { name: '', fileUpload: null },
        isUpdate: false
        // isOnlineTemplate: false
    });
    const [formTemplateAction, setFormTemplateAction] = useState(false);

    useEffect(() => {
        resetFormTemplate();

        async function getFormRequest() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.formRequest.getPaging({
                    pageIndex: page,
                    pageSize: 10,
                    freeTextSearch: search || search.length > 0 ? search : '',
                    statuses: filterStatus,
                    sort: sortBy.name + ' ' + sortBy.order
                });
                if (res && res.result) {
                    setFormRequests(res.result.items);
                    setTotalRequest({total: res.result.totalRecord, pages: Math.ceil(res.result.totalRecord / 10)})
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getFormTemplate() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.formTemplate.getPaging({
                    pageIndex: page,
                    pageSize: 10,
                    freeTextSearch: search || search.length > 0 ? search : ''
                });
                if (res && res.result) {
                    setFormTemplates(res.result.items);
                    setTotalTemplate({pages: Math.ceil(res.result.totalRecord / 10), total: res.result.totalRecord})
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFormRequest();
        getFormTemplate();
    }, [changes]);

    function changePage(page) {
        setPage(page);
        setChanges(!changes);
    }

    async function searchAPI() {
        setChanges(!changes);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    function tabChange(index) {
        if (index == 0) {
            setFilterStatus([1, 2, 3]);
        } else {
            if (index == 1) {
                setFilterStatus([4]);
            } else {

            }
        }
        setTabIndex(() => index);
        setPage(() => 1);
        setSortBy({ name: 'creationDate', order: 'asc' });
        setChanges(!changes);
    }

    function sortChange(event) {
        if (event.sortBy == sortBy.name) {
            setSortBy({ ...sortBy, order: sortBy.order == 'asc' ? 'desc' : 'asc' });
            setChanges(!changes);
        } else {
            setSortBy({ name: event.sortBy, order: 'asc' });
            setChanges(!changes);
        }
    }

    function createFormRequest() {
        setFormTemplate({ file: { fileUpload: null, name: '' }, isUpdate: false, name: '', id: '' });
        setFormTemplateAction(true);
    }

    async function saveTemplate() {
        if (!formTemplate.name) {
            show_ErrMsg('Vui lòng nhập tên biểu mẫu!');
            return;
        }
        // if (!formTemplate.file.name) {
        //     show_ErrMsg('Vui lòng chọn file biểu mẫu!');
        //     return;
        // }
        try {
            dispatch(setLoading(true));
            const formData = new FormData();
            formData.append('id', formTemplate.id ? formTemplate.id : '');
            formData.append('name', formTemplate.name);
            if (formTemplate.file.name) {
                // @ts-ignore
                formData.append('file', formTemplate.file.fileUpload);
            }
            const res: any = await $api.formTemplate.save(formData);
            if (res && res.message) {
                resetFormTemplate();
                setChanges(!changes);
                setTabIndex(2);
                dispatch(setAlert({
                    title: res.message
                }));
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function documentAction(record, action) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.subjectDocument.downloadDocument({ path: record['url'] });
            if (res && res.result) {
                window.open(action == 'download' ? res.result : res.result.split('?dl=1')[0], '_blank');
                dispatch(setAlert({
                    title: res.message
                }));
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

// triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            Array.from(e.target.files).map((file, index) => {
                setFormTemplate({
                    ...formTemplate,
                    file: { name: e.target.files[index].name, fileUpload: e.target.files[index] }
                });
            });
        }
    };

    function resetFormTemplate() {
        setFormTemplate({ ...formTemplate, file: { name: '', fileUpload: null }, isUpdate: false });
        setConfirmDialog({ isOpen: false, title: '', subTitle: '', deleteId: '' });
        setFormTemplateAction(false);
    }

    function formTemplateUpdate(item) {
        setFormTemplate({
            id: item.id,
            name: item.name,
            file: { name: item.documentUrl, fileUpload: null },
            isUpdate: true
        });
        setFormTemplateAction(true);
    }

    async function formTemplateDownload(item) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.formTemplate.downloadDocument({ path: item['documentUrl'] });
            if (res && res.result) {
                window.open(res.result, '_blank');
                dispatch(setAlert({
                    title: res.message
                }));
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function formTemplateDelete(id) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.formTemplate.delete({ id: id });
            if (res) {
                dispatch(setAlert({
                    title: res.message
                }));
                setChanges(!changes);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

// @ts-ignore
    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <div>Quản lý biểu mẫu, xác nhận</div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white'>
                    <div className='mt-4'>
                        <div className='flex justify-between items-end flex-wrap'>
                            <div className='actions--item' onClick={() => createFormRequest()}>
                                <Button
                                    label={'Tạo biểu mẫu'}
                                    icon={<i className='fas fa-file-medical' />}
                                />
                            </div>
                            <div className='action--item search flex justify-end mt-4'>
                                <input
                                    type='text'
                                    required
                                    className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Tìm kiếm đề nghị, họ tên'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) =>
                                        e.key === 'Enter' && searchAPI()
                                    }/>
                                <div onClick={searchAPI}>
                                    <Button
                                        label='Tìm kiếm'
                                        color='bg-yellow-500'
                                        hoverColor='bg-yellow-400'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='tab-content mt-3'>
                            <Tabs
                                defaultIndex={tabIndex}
                                onSelect={(index) => tabChange(index)}>
                                <TabList>
                                    <Tab>Xác nhận - Đang thực hiện</Tab>
                                    <Tab>Xác nhận - Hoàn thành</Tab>
                                    <Tab>Danh sách biểu mẫu, xác nhận</Tab>
                                </TabList>
                                <TabPanel>
                                    <div>
                                        {
                                            formRequests.length === 0 ?
                                                <div className='flex flex-wrap -ml-4 -mb-4'
                                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src='/notFound_01.png'
                                                         alt='Không tìm thấy dữ liệu'
                                                         title='Không tìm thấy dữ liệu'
                                                    />
                                                </div>
                                                :
                                                <div className='w-auto-scroll'>
                                                    <div className='table w-auto-scroll mt-8 w-full'>
                                                        <TableFormRequest
                                                            data={formRequests}
                                                            sortBy={sortBy}
                                                            roleShow={true}
                                                            deleteClicked={() => {
                                                            }}
                                                            clicked={(e, id) => {
                                                                history.push('/bieu-mau-xac-nhan/' + id);
                                                            }}
                                                            updateClicked={() => {
                                                            }}
                                                            sortClicked={(event) => sortChange(event)}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className='pagination'>
                                            <Pagination
                                                page={page}
                                                total={totalRequest.total}
                                                pages={totalRequest.pages}
                                                clickedPage={changePage}
                                            />
                                        </div>
                                    </div>
                                    <ConfirmDialog
                                        confirmDialog={confirmDialog}
                                        setConfirmDialog={setConfirmDialog}
                                        deleteItem={() => {
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <div>
                                        {
                                            formRequests.length === 0 ?
                                                <div className='flex flex-wrap -ml-4 -mb-4'
                                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src='/notFound_01.png'
                                                         alt='Không tìm thấy dữ liệu'
                                                         title='Không tìm thấy dữ liệu'
                                                    />
                                                </div>
                                                :
                                                <div className='w-auto-scroll'>
                                                    <div className='table w-auto-scroll mt-8 w-full'>
                                                        <TableFormRequest
                                                            data={formRequests}
                                                            sortBy={sortBy}
                                                            roleShow={true}
                                                            deleteClicked={() => {
                                                            }}
                                                            clicked={() => {
                                                            }}
                                                            updateClicked={() => {
                                                            }}
                                                            sortClicked={(event) => sortChange(event)}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className='pagination'>
                                            <Pagination
                                                page={page}
                                                total={totalRequest.total}
                                                pages={totalRequest.pages}
                                                clickedPage={changePage}
                                            />
                                        </div>
                                    </div>
                                    <ConfirmDialog
                                        confirmDialog={confirmDialog}
                                        setConfirmDialog={setConfirmDialog}
                                        deleteItem={() => {
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <div>
                                        {
                                            formTemplates.length === 0 ?
                                                <div className='flex flex-wrap -ml-4 -mb-4'
                                                     style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src='/notFound_01.png'
                                                         alt='Không tìm thấy dữ liệu'
                                                         title='Không tìm thấy dữ liệu'
                                                    />
                                                </div>
                                                :
                                                <div className='w-auto-scroll'>
                                                    <div className='table w-auto-scroll mt-8 w-full'>
                                                        <TableFormTemplate
                                                            data={formTemplates}
                                                            sortBy={sortBy}
                                                            roleShow={true}
                                                            deleteClicked={(e, id) => setConfirmDialog({
                                                                deleteId: id,
                                                                isOpen: true
                                                                , title: 'Thông báo'
                                                                , subTitle: 'Bạn có chắc muốn xóa ?'
                                                            })}
                                                            downloadClick={(e, item) => formTemplateDownload(item)}
                                                            updateClicked={(e, item) => formTemplateUpdate(item)}
                                                            sortClicked={(event) => sortChange(event)}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className='pagination'>
                                            <Pagination
                                                page={page}
                                                total={totalTemplate.total}
                                                pages={totalTemplate.pages}
                                                clickedPage={changePage}
                                            />
                                        </div>
                                    </div>
                                    <ConfirmDialog
                                        confirmDialog={confirmDialog}
                                        setConfirmDialog={setConfirmDialog}
                                        deleteItem={() => formTemplateDelete(confirmDialog.deleteId)}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={formTemplateAction}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setFormTemplateAction(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {formTemplate.id ? 'Cập nhật biểu mẫu' : 'Tạo mới biểu mẫu'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-4'>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            Tên biễu mẫu
                        </div>
                        <input
                            name='text'
                            type='text'
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Nhập tên biểu mẫu'
                            value={formTemplate?.name}
                            onChange={(e) => {
                                setFormTemplate({ ...formTemplate, name: e.target.value });
                            }}
                        />
                    </div>
                    <div>
                        <div className={'pl-1 text-sm font-semibold mb-2 text-gray-600'}>
                            File biểu mẫu
                        </div>
                        {
                            formTemplate.file.name && formTemplate.id ? <div className={'row mx-1'}>
                                <div className='col-1' onClick={() => setFormTemplate({
                                    ...formTemplate,
                                    file: { fileUpload: null, name: '' }
                                })}>
                                    <i className={'fas fa-trash-alt text-1xl text-red-600'}></i>
                                </div>
                                <div className='col-11'>
                                    <span>{formTemplate.file.name}</span>
                                </div>
                            </div> : <input
                                name='text'
                                type='file'
                                required
                                className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder='Chọn file biểu mẫu'
                                accept=
                                    'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf'
                                onChange={(e) => handleChange(e)}
                            />
                        }
                    </div>
                    {/*{*/}
                    {/*    formTemplate.isOnlineTemplate ? <div>*/}
                    {/*        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>*/}
                    {/*            Đường dẫn tài liệu*/}
                    {/*        </div>*/}
                    {/*        <input*/}
                    {/*            name='text'*/}
                    {/*            type='text'*/}
                    {/*            required*/}
                    {/*            className='appearance-none block*/}
                    {/*				w-full px-3 py-2 border border-gray-300*/}
                    {/*				placeholder-gray-500 rounded-md*/}
                    {/*				focus:outline-none focus:ring-1*/}
                    {/*				focus:ring-blue-700'*/}
                    {/*            placeholder='Nhập đường dẫn tài liệu'*/}
                    {/*            value={formTemplate.documentUrl}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                setFormTemplate({ ...formTemplate, documentUrl: e.target.value });*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div> : <div>*/}
                    {/*        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>*/}
                    {/*            File tài liệu*/}
                    {/*        </div>*/}
                    {/*        {*/}
                    {/*            formTemplate.file.fileUpload ? <div className={'row mx-1'}>*/}
                    {/*                <div className='col-1' onClick={resetFormTemplate}>*/}
                    {/*                    <i className={'far fa-window-close text-2xl text-red-600'}></i>*/}
                    {/*                </div>*/}
                    {/*                <div className='col-11'>*/}
                    {/*                    <span>{formTemplate.file.name}</span>*/}
                    {/*                </div>*/}
                    {/*            </div> : <input*/}
                    {/*                name='text'*/}
                    {/*                type='file'*/}
                    {/*                required*/}
                    {/*                className='appearance-none block*/}
                    {/*				w-full px-3 py-2 border border-gray-300*/}
                    {/*				placeholder-gray-500 rounded-md*/}
                    {/*				focus:outline-none focus:ring-1*/}
                    {/*				focus:ring-blue-700'*/}
                    {/*                placeholder='Nhập số lượng ghế'*/}
                    {/*                accept=*/}
                    {/*                    'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf'*/}
                    {/*                // @ts-ignore*/}
                    {/*                value={formTemplate.file.name || ''}*/}
                    {/*                onChange={(e) => handleChange(e)}*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*}*/}
                </Modal.Body>
                <Modal.Footer>
                    <div className='flex justify-between w-100'>
                        <div onClick={() => setFormTemplateAction(false)}>
                            <Button
                                color={'bg-red-900'}
                                hoverColor={'bg-red-800'}
                                label={'Hủy'}
                            />
                        </div>
                        <div onClick={() => {
                            saveTemplate();
                        }}>
                            <Button
                                color={'bg-blue-900'}
                                hoverColor={'bg-blue-800'}
                                icon={<i className={'fas fa-save'}></i>}
                                label={formTemplate.id ? 'Lưu lại' : 'Tạo mới'}
                            />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Form_Request__List;
