import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import { $api } from 'services/service';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { setAlert, setLoading } from '../../redux/authSlice';
import moment from 'moment';
import TableExam from '../../components/Elements/Table/Table-Exam';
import ErrorDialogMultipleLine from '../../components/Elements/ErrorDialog/ErrorDialogMultipleLine';
import './Exam_List.scss';

const fileDownload = require('js-file-download');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Exam_List({ match }) {
    let history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();
    let examId: any = match.params.id;
    const [exams, setExams] = useState([]);
    const [exam, setExam] = useState({
        'id': '',
        'subjectTeachingId': '',
        'name': '',
        'portion': 0,
        'startDate': new Date(),
        'endDate': new Date(),
        'roomIdDefault': '',
        'notes': ''
    });
    const [changes, setChanges] = useState(false);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(20);
    const [page, setPage] = useState(1);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [confirmDialogSave, setConfirmDialogSave] = useState({ isOpen: false, title: '', subTitle: '', data: [] });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [errDialogMultipleLine, setErrDialogMultipleLine] = useState({ isOpen: false, message: [] });
    const [idDelete, setIdDelete] = useState(null);

    useEffect(() => {
        getExam(examId);
        getExams(examId);
        setChanges(false);
    }, [changes]);

    async function getExam(id) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_get(id);
            if (res && res.result) {
                setExam(res.result);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function getExams(id) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.exam.subjectTeachingExamGetBySubjectTeaching(id);
            if (res && res.result) {
                setExams(res.result);
                setTotal(res.result.totalRecord);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }


    function changePage(page) {
        setPage(page);
        setChanges(!changes);
    }

    async function uploadFile(event) {
        const formData = new FormData();
        formData.append('formFile', event.target.files[0]);
        try {
            dispatch(setLoading(true));
            const res: any = await $api.exam.exam_import(formData, examId);
            if (res && res.message) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                setChanges(!changes);
                return;
            }
            if (res && res.errors) {
                dispatch(setLoading(false));
                show_ErrMsg(res?.errors?.exceptionMessage ? res?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                return;
            }
        } catch (e) {
            let err = '';
            if (e.response && e.response.data && e.response.data.errors) {
                err += e.response.data.errors['success'].detail;
                if (e.response.data.errors.rowErrors) {
                    err += '\r\n';
                    err += '-------------------------------------------------------';
                    err += '\r\n';
                    for (var er of e.response.data.errors.rowErrors['details']) {
                        err += er + '\r\n';
                    }
                } else {
                    err = e.response.data.errors.exceptionMessage;
                }
            }
            if (e.response.data.errors['success'].count !== 0) {
                setChanges(!changes);
            }
            dispatch(setLoading(false));
            show_ErrMsg(err);
            return;
        } finally {
            event.target.value = '';
        }
    }

    async function searchAPI() {
        setChanges(!changes);
    }

    async function deleteStudent(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.student.deleteStudent(idDelete);
            if (res) {
                setChanges(!changes);
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                return;
            }
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
            dispatch(setLoading(false));
            return;
        }
    }

    async function onSaveResult(e, results: []) {
        e.stopPropagation();
        setConfirmDialogSave({
            isOpen: true,
            title: 'Xác nhận',
            subTitle: `Bạn muốn nhập kết quả cho lớp: ${exam.name} - Ngày bắt đầu: ${moment(exam.startDate).format('DD/MM/YYYY')} - Ngày kết thúc: ${moment(exam.endDate).format('DD/MM/YYYY')}`,
            data: results
        });
    }

    async function saveResultExam() {
        let errorList = [];
        if (errorList.length) {
            setErrDialogMultipleLine({ isOpen: true, message: errorList });
            setConfirmDialogSave({ isOpen: false, title: '', subTitle: '', data: [] });
        } else {
            try {
                setConfirmDialogSave({ isOpen: false, title: '', subTitle: '', data: [] });
                dispatch(setLoading(true));
                const res: any = await $api.exam.examSaveBySubjectTeaching(examId, exams);
                if (res) {
                    dispatch(
                        setAlert({
                            title: res.message
                        })
                    );
                    getExams(examId);
                }
                dispatch(setLoading(false));
            } catch (e) {
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
                dispatch(setLoading(false));
                return;
            }
        }

    }

    function openNewTabCreateExam() {
        window.open('', '_blank', 'noopener,noreferrer');
    }

    async function exportResultExam() {
        const res: any = await $api.exam.exam_export(examId);
        fileDownload(res, `Ket-qua-thi-${exam.name}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }


    return (
        <DefaultLayout>
            <input
                type='file'
                id='exam-import'
                className='hidden'
                accept='.xlsx, .xls, .csv'
                onChange={(e) => {
                    uploadFile(e);
                }}
            />
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <div>{`Kết quả thi lớp: ${exam?.name} - Ngày bắt đầu: ${moment(exam?.startDate).format('DD/MM/YYYY')} - Ngày kết thúc: ${moment(exam?.endDate).format('DD/MM/YYYY')}`}</div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='actions flex items-center  justify-between'>
                        <div className='actions--item d-flex'>
                            <div onClick={history.goBack}>
                                <Button
                                    label='Quay lại'
                                    icon={<i className='fas fa-arrow-left' />}
                                />
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div
                                className='actions--item ml-4'
                                onClick={exportResultExam}>
                                <Button
                                    label='Xuất kết quả thi'
                                    icon={<i className='fas fa-upload' />}
                                    color='bg-green-700'
                                    hoverColor='bg-green-800'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className='w-auto-scroll'>
                            {
                                exams.length === 0 ?
                                    <div className='flex flex-wrap -ml-4 -mb-4'
                                         style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='/notFound_01.png'
                                             alt='Không tìm thấy dữ liệu'
                                             title='Không tìm thấy dữ liệu'
                                        />
                                    </div>
                                    :
                                    <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableExam exams={exams}
                                                   onSaveResult={(e, data) => onSaveResult(e, data)}
                                                   changeData={(exams) => setExams(() => exams)} />
                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />

            <ConfirmDialog
                confirmDialog={confirmDialogSave}
                setConfirmDialog={setConfirmDialogSave}
                deleteItem={saveResultExam}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
            <ErrorDialogMultipleLine
                errorDialog={errDialogMultipleLine}
                setErrorDialog={setErrDialogMultipleLine}
            />
        </DefaultLayout>
    );
}

export default Exam_List;
