import './class__list.scss';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import Table from 'components/Elements/Table/Table';
import Pagination from 'components/Elements/Pagination/Pagination';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { $api } from 'services/service';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';

const fileDownload = require('js-file-download');

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Class__List() {
	let history = useHistory();
	const query = useQuery();
	const dispatch = useDispatch();
	let faId: any = query.get('f');
	const [classes, setClasses] = useState([]);
	const [changes, setChanges] = useState(false);
	const [faculties, setFaculties] = useState([]);
	const [faculty, setFaculty] = useState('');
	const [graduated, setGraduated] = useState('0');
	const [total, setTotal] = useState(0);
	const [pages, setPages] = useState(0);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
	const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
	const [idDelete, setIdDelete] = useState(null);

	useEffect(() => {
		async function getFaculties() {
			try {
				dispatch(setLoading(true));
				const res: any = await $api.faculties.getPaging({});
				if (res && res.result) {
					setFaculties(res.result.items);
				}
				dispatch(setLoading(false));
			} catch (e) {
				dispatch(setLoading(false));
				// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
				let errMsg;
				let type = typeof e?.response?.data?.errors?.exceptionMessage;
				if (type === 'string') {
					errMsg = e?.response?.data?.errors?.exceptionMessage;
				}
				else if (e?.response?.data?.errors?.exceptionMessage?.title) {
					errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
				}
				else {
					errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
				}
				show_ErrMsg(errMsg);
			}
		}
		if (faId && faId.length > 0) {
			setFaculty(faId);
		}
		getFaculties();
	}, []);

	useEffect(() => {
		getClasses({
			isGraduated: Number(graduated) === 1,
			facultyId: faculty || faculty.length > 0 ? faculty : null,
			pageIndex: page,
			pageSize: 9,
			freeTextSearch: search || search.length > 0 ? search : null,
		});
	}, [changes, faculty, graduated]);

	function changePage(page) {
		setPage(page);
		setChanges(!changes);
	}

	function findByID() {
		const objectFind = faculties.find((item: { id: string }) => {
			return item.id === faculty;
		});
		const object = { name: 'Quản lý khoa' };
		return objectFind ? objectFind : object;
	}

	async function getClasses(option = {}) {
		try {
			if (faculty) {
				dispatch(setLoading(true));
				const res: any = await $api.class.getPaging(option);
				if (res && res.result) {
					setTotal(res.result.totalRecord);
					setClasses(res.result.items);
					setPages(Math.ceil(res.result.totalRecord / 9));
					dispatch(setLoading(false));
					
				}
			}
		} catch (e) {
			dispatch(setLoading(false));
		}
	}

	async function uploadFile(event) {
		const formData = new FormData();
		formData.append('formFile', event.target.files[0]);
		try {
			dispatch(setLoading(true));
			const res: any = await $api.class.importClass(formData, faculty);
			if (res && res.message) {
				setChanges(!changes);
				dispatch(setLoading(false));
				return dispatch(
					setAlert({
						title: res.message,
					})
				);
			}
			if (res && res.errors) {
				dispatch(setLoading(false));
				show_ErrMsg(res?.errors?.exceptionMessage);
				return;
			}
		} catch (e) {
			let err = '';
			if (e.response && e.response.data && e.response.data.errors) {
				if (e.response.data.errors.rowErrors) {
					for (var er of e.response.data.errors.rowErrors['details']) {
						err += er + '\r\n';
					}
					err += e.response.data.errors.success.detail + '\r\n';

				}else{
					err = e.response.data.errors.exceptionMessage;
				}
			}
			dispatch(setLoading(false));
			show_ErrMsg(err);
			return;
		} finally {
			event.target.value = '';
		}
	}

	async function searchAPI() {
		setChanges(!changes);
	}

	async function deleteClass(e, id) {
		e.stopPropagation();
		setConfirmDialog({
			isOpen: true
			, title: 'Thông báo'
			, subTitle: 'Bạn có chắc muốn xóa ?'
		})
		setIdDelete(id);
	}

	async function deleteItem() {
		setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
		try {
			dispatch(setLoading(true));
			const res: any = await $api.class.deleteClass(idDelete);
			if (res) {
				dispatch(
					setAlert({
						title: res.message,
					})
				);
				setChanges(!changes);
				dispatch(setLoading(false));
			}
		}
		catch (e) {
			dispatch(setLoading(false));
			// show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
			let errMsg;
			let type = typeof e?.response?.data?.errors?.exceptionMessage;
			if (type === 'string') {
				errMsg = e?.response?.data?.errors?.exceptionMessage;
			}
			else if (e?.response?.data?.errors?.exceptionMessage?.title) {
				errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
			}
			else {
				errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
			}
			show_ErrMsg(errMsg);
		}
	}

	function getDetailClass(e, id) {
		e.stopPropagation();
		history.push(`/lop-hoc/${id}?f=${faculty}`);
	}

	function getStudents(e, id) {
		e.stopPropagation();
		history.push(`/sinh-vien?c=${id}&f=${faculty}`);
	}

	function addClass() {
		history.push('/lop-hoc/them-moi?f=' + faculty);
	}

	async function importClasses() {
		// @ts-ignore
		document.getElementById('class-import').click();
	}

	async function downloadTemplate() {
		const res: any = await $api.class.downloadTemplate();
		fileDownload(res, `ban-mau-danh-sach-lop-hoc.xlsx`);
	}

	async function exportClass() {
		// @ts-ignore
		const nameFaculty = faculties.find((item) => {
			// @ts-ignore
			return item.id === faculty;
		}).name;
		const res: any = await $api.class.exportClass(faculty);
		fileDownload(res, `danh-sach-lop-hoc-khoa-${nameFaculty}.xlsx`);
	}

	function show_ErrMsg(msg) {
		setErrDialog({
			isOpen: true
			, subTitle: msg
		});
	}

	return (
		<DefaultLayout>
			<input
				type='file'
				id='class-import'
				className='hidden'
				onChange={(e) => {
					uploadFile(e);
				}}
			/>
			<div className='page'>
				<div className='page__header'>
					<div className='___title flex items-center'>
						<div className='___title__decorate' />
						<div className='___title__box'>
							<div>{findByID().name}</div>
						</div>
					</div>
				</div>
				<div className='page__content bg-white mt-3'>
					<div className='actions flex items-center  justify-between'>
						<div className='actions--item' onClick={addClass}>
							<Button
								label='Thêm lớp học'
								icon={<i className='fas fa-user-plus' />}
							/>
						</div>
						<div className='flex items-center'>
							{faculty.length > 0 ? (
								<div
									className='actions--item ml-4'
									onClick={exportClass}>
									<Button
										label='Export'
										icon={<i className='fas fa-download' />}
										color='bg-green-700'
										hoverColor='bg-green-800'
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<div className='mt-4'>
						<div className='flex justify-between items-end flex-wrap'>
							<div className='action--item flex justify-start items-center'>
								<div className='mr-4'>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Khoa
									</div>
									<select
										className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										value={faculty}
										onChange={(e) => {
											setFaculty(e.target.value);
										}}>
										{faculties.map((item: any) => {
											return (
												<option
													value={item.id}
													key={item.id}>
													{item.name}
												</option>
											);
										})}
									</select>
								</div>
								<div>
									<div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
										Tình trạng tốt nghiệp
									</div>
									<select
										className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
										value={graduated}
										onChange={(e) => {
											setGraduated(e.target.value);
										}}>
										<option value='1' key='1'>
											Đã tốt nghiệp
										</option>
										<option value='0' key='0'>
											Chưa tốt nghiệp
										</option>
									</select>
								</div>
							</div>
							<div className='action--item search flex justify-start mt-4'>
								<input
									name='search'
									type='text'
									required
									className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
									placeholder='Tìm kiếm lớp học'
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									onKeyDown={(e) =>
										e.key === 'Enter' && searchAPI()
									}
								/>
								<div onClick={searchAPI}>
									<Button
										label='Tìm kiếm'
										color='bg-yellow-500'
										hoverColor='bg-yellow-400'
									/>
								</div>
							</div>
						</div>
						{
							classes.length === 0 ?
								<div className='faculties--list flex flex-wrap -ml-4 -mb-4'
									style={{ justifyContent: "center", alignItems: "center" }}>
									<img src='/notFound_01.png'
										alt='Không tìm thấy dữ liệu'
										title='Không tìm thấy dữ liệu'
									/>
								</div>
								:
								<div className='w-auto-scroll'>
									<div className='table w-auto-scroll mt-8 w-full'>
										<Table
											data={classes}
											deleteClicked={deleteClass}
											updateClicked={getDetailClass}
											clicked={getStudents}
										/>
									</div>
								</div>
						}
					</div>
					<div className='pagination'>
						<Pagination
							page={page}
							total={total}
							pages={pages}
							clickedPage={changePage}
						/>
					</div>
				</div>
			</div>
			<ConfirmDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
				deleteItem={deleteItem}
			/>
			<ErrorDialog
				errorDialog={errDialog}
				setErrorDialog={setErrDialog}
			/>
		</DefaultLayout>
	);
}

export default Class__List;
