import axiosClient from 'config/axiosClient';

const _url = '/Api/User';
const userService = {
    getPaging: (option) => {
        return axiosClient.post(_url + '/GetPaging', option);
    },
    getDetail: (id) => {
        return axiosClient.post(_url + '/Get', { id: id });
    },
    createCUser: (data) => {
        return axiosClient.post(_url + '/Create', data);
    },
    updateUser: (data) => {
        return axiosClient.post(_url + '/Update', data);
    },
    deleteUser: (id) => {
        return axiosClient.post(_url + '/Delete', { id: id });
    },
};

export default userService;
